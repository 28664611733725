import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {get, isEqual, last} from "lodash";
import {getEventDate, getExpectedDate} from "./utils";
import {getAnimalUnit} from "../../../utils/SettingsUtils";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {getExpectedDatesForAnimalCycle} from "../../../selectors/animalDocumentsSelectors";
import {compose} from "redux";
import {connect} from "react-redux";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {getWeightedAverage} from "../../../utils/MathUtils";

function mapStateToProps(state) {
    return {
        expectedDates: getExpectedDatesForAnimalCycle(state),
    };
}

class SeparationRenderer extends React.Component {
    state = {
        hoveredID: "",
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID,
            });
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: "",
        });
    };

    getPigletWeight(events = []) {
        if (events.length === 0) return "";
        let weights = [];
        let numbers = [];
        events.forEach((event) => {
            numbers.push(get(event, "event.EvData.PiWeight", 0));
            weights.push(get(event, "event.EvData.PiCnt", 0));
        });
        const avg = getWeightedAverage(numbers, weights);
        return convertWeightUnitTo(avg || 0, {
            unit: getAnimalUnit(),
            showUnit: true,
            fixed: 2,
        });
    }

    getTattooData(separation) {
        const tattooData = get(separation, "event.EvData.TattooData", {});
        if (Object.keys(tattooData).length === 0) return undefined;
        return tattooData;
    }

    getPigletsContent(separation, tattooData) {
        if (tattooData) {
            return tattooData.AnmNo1 || "";
        }
        return get(separation, "event.EvData.PiCnt", 0);
    }

    getTitle(tattooData) {
        const {t} = this.props;
        if (tattooData) return t("errors.noEditTattooPiglet");
        return "";
    }

    getClassName(evID, tattooData) {
        const {hoveredID} = this.state;
        let className = "cell-wrapper";
        if (tattooData) {
            className += " cursor-not-allowed";
        } else {
            if (hoveredID === evID) className += " error";
            else className += " success";
        }
        return className;
    }

    getPiglets(events = []) {
        return events.reduce((a, b) => a + get(b, "event.EvData.PiCnt", 0), 0);
    }

    getParturitionDate() {
        const {
            cycle: {parturitions, sowCycles},
        } = this.props;
        return get(
            parturitions,
            "[0].event.EvTime",
            get(sowCycles, "[0].event.EvTime", null)
        );
    }

    render() {
        const {
            t,
            cycle: {separations, sowCycles, key},
            expanded: {rows},
            isAdvanced,
            tooltipContent,
            cycleRenderClass,
            deletionMode,
            onDeleteClick,
            onDoubleEventClick,
            printing,
            rowIndex,
            expectedDates,
            isInterrupted,
        } = this.props;
        let rowValue = rows.find((o) => o === key);
        let lastSeparation = separations.length
            ? last(separations)
            : last(sowCycles);
        let type = cycleRenderClass && cycleRenderClass.split(" ")[1];
        if (!lastSeparation) {
            const expectedDate = getExpectedDate(
                isInterrupted,
                rowIndex,
                expectedDates,
                EventTypes.SEPARATION
            );
            const expectedInDays = getExpectedDate(
                isInterrupted,
                rowIndex,
                expectedDates,
                EventTypes.SEPARATION,
                true,
                this.getParturitionDate()
            );
            return (
                <>
                    {isAdvanced && (
                        <CycleRenderer
                            className={expectedDate ? "expected-date" : ""}
                            isInterrupted={isInterrupted}>
                            <div
                                className={`cell-wrapper`}
                                title={t("animalDocuments.plannedDate", {
                                    date: expectedDate,
                                })}>
                                {expectedDate &&
                                    t("Xdays", {count: expectedInDays})}
                            </div>
                        </CycleRenderer>
                    )}
                    <CycleRenderer
                        comment={cycleRenderClass && tooltipContent}
                        type={type}
                        deletionMode={deletionMode}
                        expected={
                            !isAdvanced &&
                            expectedDate &&
                            t("animalDocuments.plannedDate", {
                                date: expectedDate,
                            })
                        }
                        isInterrupted={isInterrupted}
                    />
                    <CycleRenderer isInterrupted={isInterrupted} />
                </>
            );
        }
        const separationEvents = separations.length ? separations : sowCycles;
        const operators = last(separationEvents)?.operators;
        const parturitionDate = this.getParturitionDate();
        const lastTattooData = this.getTattooData(lastSeparation);
        return (
            <>
                {isAdvanced && (
                    <CycleRenderer>
                        {isEqual(key, rowValue) ? (
                            separationEvents.map((separation) => {
                                const {
                                    event: {EvID, EvCode},
                                } = separation;
                                const tattooData =
                                    this.getTattooData(separation);
                                return (
                                    <div
                                        className={this.getClassName(
                                            EvID,
                                            tattooData
                                        )}
                                        id={`${EvID} EvTime`}
                                        onDoubleClick={
                                            !tattooData
                                                ? onDoubleEventClick
                                                : undefined
                                        }
                                        title={this.getTitle(tattooData)}
                                        onMouseEnter={
                                            !tattooData && deletionMode
                                                ? this.onMouseEnter
                                                : undefined
                                        }
                                        onMouseLeave={
                                            !tattooData && deletionMode
                                                ? this.onMouseLeave
                                                : undefined
                                        }
                                        onClick={
                                            !tattooData && deletionMode
                                                ? onDeleteClick
                                                : undefined
                                        }>
                                        {EvCode === EventTypes.SEPARATION
                                            ? parturitionDate !== null
                                                ? t("Xdays", {
                                                      count: getEventDate(
                                                          separation,
                                                          {
                                                              UTC: true,
                                                              inDays: true,
                                                              fromDay:
                                                                  parturitionDate,
                                                          }
                                                      ),
                                                  })
                                                : "-"
                                            : getEventDate(separation)}
                                    </div>
                                );
                            })
                        ) : (
                            <div
                                className={this.getClassName(
                                    lastSeparation.event.EvID,
                                    lastTattooData
                                )}
                                id={`${lastSeparation.event.EvID} EvTime`}
                                title={this.getTitle(lastTattooData)}
                                onDoubleClick={
                                    !lastTattooData
                                        ? onDoubleEventClick
                                        : undefined
                                }
                                onMouseEnter={
                                    !lastTattooData && deletionMode
                                        ? this.onMouseEnter
                                        : undefined
                                }
                                onMouseLeave={
                                    !lastTattooData && deletionMode
                                        ? this.onMouseLeave
                                        : undefined
                                }
                                onClick={
                                    !lastTattooData && deletionMode
                                        ? onDeleteClick
                                        : undefined
                                }>
                                {parturitionDate !== null
                                    ? t("Xdays", {
                                          count: getEventDate(lastSeparation, {
                                              UTC: true,
                                              inDays: true,
                                              fromDay: parturitionDate,
                                          }),
                                      })
                                    : "-"}
                            </div>
                        )}
                    </CycleRenderer>
                )}
                <CycleRenderer
                    comment={!sowCycles.length && tooltipContent}
                    isAdvanced={true}
                    operators={operators}
                    deletionMode={deletionMode}>
                    {isAdvanced && (isEqual(key, rowValue) || printing) ? (
                        separationEvents.map((separation) => {
                            const {
                                event: {EvID},
                            } = separation;
                            const tattooData = this.getTattooData(separation);
                            return (
                                <div
                                    className={this.getClassName(
                                        EvID,
                                        tattooData
                                    )}
                                    id={`${EvID} EvData.PiCnt`}
                                    onDoubleClick={
                                        !tattooData
                                            ? onDoubleEventClick
                                            : undefined
                                    }
                                    onMouseEnter={
                                        !tattooData && deletionMode
                                            ? this.onMouseEnter
                                            : undefined
                                    }
                                    onMouseLeave={
                                        !tattooData && deletionMode
                                            ? this.onMouseLeave
                                            : undefined
                                    }
                                    onClick={
                                        !tattooData && deletionMode
                                            ? onDeleteClick
                                            : undefined
                                    }
                                    title={this.getTitle(tattooData)}>
                                    {this.getPigletsContent(
                                        separation,
                                        tattooData
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div
                            className={this.getClassName(
                                lastSeparation.event.EvID,
                                lastTattooData
                            )}
                            id={`${lastSeparation.event.EvID} EvData.PiCnt`}
                            onDoubleClick={
                                !lastTattooData ? onDoubleEventClick : undefined
                            }
                            onMouseEnter={
                                !lastTattooData && deletionMode
                                    ? this.onMouseEnter
                                    : undefined
                            }
                            onMouseLeave={
                                !lastTattooData && deletionMode
                                    ? this.onMouseLeave
                                    : undefined
                            }
                            onClick={
                                !lastTattooData && deletionMode
                                    ? onDeleteClick
                                    : undefined
                            }
                            title={this.getTitle(lastTattooData)}>
                            {this.getPiglets(separationEvents)}
                        </div>
                    )}
                </CycleRenderer>
                <CycleRenderer>
                    {isAdvanced && (isEqual(key, rowValue) || printing) ? (
                        separationEvents.map((separation) => {
                            const {
                                event: {EvID},
                            } = separation;
                            const tattooData = this.getTattooData(separation);
                            return (
                                <div
                                    className={this.getClassName(
                                        EvID,
                                        tattooData
                                    )}
                                    id={`${EvID} EvData.PiWeight`}
                                    onDoubleClick={
                                        !tattooData
                                            ? onDoubleEventClick
                                            : undefined
                                    }
                                    onMouseEnter={
                                        !tattooData && deletionMode
                                            ? this.onMouseEnter
                                            : undefined
                                    }
                                    onMouseLeave={
                                        !tattooData && deletionMode
                                            ? this.onMouseLeave
                                            : undefined
                                    }
                                    onClick={
                                        !tattooData && deletionMode
                                            ? onDeleteClick
                                            : undefined
                                    }
                                    title={this.getTitle(tattooData)}>
                                    {this.getPigletWeight([separation])}
                                </div>
                            );
                        })
                    ) : (
                        <div
                            className={this.getClassName(
                                lastSeparation.event.EvID,
                                lastTattooData
                            )}
                            id={`${lastSeparation.event.EvID} EvData.PiWeight`}
                            onDoubleClick={
                                !lastTattooData ? onDoubleEventClick : undefined
                            }
                            onMouseEnter={
                                !lastTattooData && deletionMode
                                    ? this.onMouseEnter
                                    : undefined
                            }
                            onMouseLeave={
                                !lastTattooData && deletionMode
                                    ? this.onMouseLeave
                                    : undefined
                            }
                            onClick={
                                !lastTattooData && deletionMode
                                    ? onDeleteClick
                                    : undefined
                            }
                            title={this.getTitle(lastTattooData)}>
                            {this.getPigletWeight(separationEvents)}
                        </div>
                    )}
                </CycleRenderer>
            </>
        );
    }
}

SeparationRenderer.propTypes = {
    cycle: PropTypes.shape({
        separations: PropTypes.array.isRequired,
    }).isRequired,
};

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps)
)(SeparationRenderer);
