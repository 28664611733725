import {PreferencesTypes} from "@wesstron/utils/Api/constants/prefTypes";
import {prepareNotificationPreference} from "../selectors/preferencesSelectors";

export const initialValue = {
    preferences: [],
    loading: false,
    selectedNotification: null,
    tabsPreferences: new Map(),
    taskFilters: [],
    filterBookmarks: [],
};

export default function preferencesReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_PREFERENCES_PENDING": {
            return {...state, loading: action.meta.showLoading};
        }
        case "GET_PREFERENCES_REJECTED": {
            return {...state, loading: false, preferences: []};
        }
        case "GET_PREFERENCES_FULFILLED": {
            const {selectedNotification} = state;
            const stateValue = {
                ...state,
                loading: false,
                preferences: action.payload.filter(
                    (item) => item.SetType === PreferencesTypes.NOTIFICATION
                ),
                taskFilters: action.payload.filter(
                    (item) => item.SetType === "F"
                ), // TODO dodac do walidatora
                filterBookmarks: action.payload.filter(
                    (item) => item.SetType === PreferencesTypes.FILTER_BOOKMARKS
                ),
            };
            if (!!selectedNotification) {
                const newSelected = action.payload.find(
                    (n) =>
                        n.FarmID === selectedNotification.FarmID &&
                        n.SetType === PreferencesTypes.NOTIFICATION
                );
                if (newSelected) {
                    stateValue.selectedNotification = {
                        ...prepareNotificationPreference(
                            newSelected.FarmID,
                            newSelected.PrefData
                        ),
                        DtaCrtTime: newSelected.DtaCrtTime,
                        DtaModTime: newSelected.DtaModTime,
                        LocalUserID: newSelected.LocalUserID,
                        SetID: newSelected.SetID,
                        SetType: newSelected.SetType,
                    };
                }
            }
            return stateValue;
        }
        case "RESET_PREFERENCES": {
            return {...state, preferences: []};
        }
        case "SET_SELECTED_PREFERENCE_NOTIFICATION":
            return {...state, selectedNotification: action.payload};
        case "RESET_SELECTED_NOTIFICATION_PREFERENCE":
            return {...state, selectedNotification: null};
        case "CHANGE_TABS_PREFERENCES":
            return {...state, tabsPreferences: action.payload};
        default:
            return state;
    }
}
