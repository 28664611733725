import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {
    findIndex,
    get,
    isEmpty,
    isEqual,
    isNil,
    isUndefined,
    last,
} from "lodash";
import moment from "moment";
import {AnimalTypes} from "../constans/animalTypes";
import {USG_STATE} from "../constans/eventTypes";
import {medicineTypes} from "../constans/medicine";
import {reportStatus, reportType} from "../constans/reports";
import animalsDB from "../database/animalsDB";
import buildingsDB from "../database/buildingsDB";
import eventsDB from "../database/eventsDB";
import groupsDB from "../database/groupsDB";
import i18next from "../i18n";
import {
    convertRowsToCycles,
    getEventsFromRow,
    getParturitionIndexForASow,
    getSowResult,
    preEvents,
} from "./AnimalDocumentsUtils";
import {getAnimalKinds} from "./AnimalsUtils";
import {isDayInRange} from "./DateTimeUtils";
import {getPigBalance} from "./EventUtils";
import {checkIfUserIsService} from "./NewRolesUtils";
import {
    getTimeFromInseminationToPartuition,
    getTimeOnBirthRoom,
} from "./SettingsUtils";
import {formatLocationName} from "./global-formatters/formatLocationName";

export function sortStringArray(inseminators) {
    return inseminators.sort((a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    });
}

function getInseminatorsList(inseminations) {
    let ins = [];
    for (let insemination of inseminations) {
        let inseminatorID = get(insemination, "EvData.OperID", undefined);
        if (inseminatorID) ins.push(inseminatorID);
    }
    return [...new Set(ins)];
}

/**
 * Funkcja dziala na obiekcie data i zwieksza odpowiednie liczniki
 * @param allEvents - wszystkie eventy dla zwierzecia wczesniej przefiltorwane z interesującego nas okresu
 * @param data
 * @returns {*}
 */
export function passedUSG(allEvents, data) {
    let R = preEvents(allEvents);
    let resultTable = get(R, "resultTable", []);
    if (Array.isArray(resultTable) && resultTable.length > 0) {
        for (const [idx, row] of resultTable.entries()) {
            if (
                Array.isArray(row[EventTypes.INSEMINATION]) &&
                row[EventTypes.INSEMINATION].length > 0
            ) {
                const operators = getInseminatorsList(
                    row[EventTypes.INSEMINATION]
                );
                let operatorsIndex = findIndex(data, (el) =>
                    isEqual(el.inseminator, operators)
                );
                if (operatorsIndex === -1) {
                    operatorsIndex =
                        data.push({
                            inseminator: operators,
                            inseminationsCnt: 0,
                            pendingCnt: 0,
                            wellInseminatedCnt: 0,
                            wrongInseminatedCnt: 0,
                        }) - 1;
                }
                data[operatorsIndex].inseminationsCnt++;
                data[operatorsIndex].pendingCnt++;
                if (row[EventTypes.USG].length > 0) {
                    const usgResult = get(
                        last(row[EventTypes.USG]),
                        "EvData.Pregnant",
                        USG_STATE.REPEAT
                    );
                    data[operatorsIndex].pendingCnt -= data[operatorsIndex]
                        .pendingCnt
                        ? 1
                        : 0;
                    if (
                        usgResult === USG_STATE.POSITIVE ||
                        (usgResult && usgResult !== USG_STATE.REPEAT)
                    ) {
                        data[operatorsIndex].wellInseminatedCnt++;
                    } else if (usgResult === USG_STATE.NEGATIVE || !usgResult) {
                        data[operatorsIndex].wrongInseminatedCnt++;
                    }
                } else if (
                    [
                        row[EventTypes.PARTURITION],
                        row[EventTypes.FALL_PIGLETS],
                        row[EventTypes.MOMMY],
                        row[EventTypes.SEPARATION_TO_MOMMY],
                        row[EventTypes.SEPARATION],
                    ].flat().length > 0
                ) {
                    data[operatorsIndex].pendingCnt -= data[operatorsIndex]
                        .pendingCnt
                        ? 1
                        : 0;
                    data[operatorsIndex].wellInseminatedCnt++;
                } else if (row[EventTypes.NO_PREGNANCY].length > 0) {
                    data[operatorsIndex].pendingCnt -= data[operatorsIndex]
                        .pendingCnt
                        ? 1
                        : 0;
                    data[operatorsIndex].wrongInseminatedCnt++;
                } else if (!!resultTable[idx + 1]) {
                    if (resultTable[idx + 1].cycle === row.cycle) {
                        data[operatorsIndex].pendingCnt -= data[operatorsIndex]
                            .pendingCnt
                            ? 1
                            : 0;
                        data[operatorsIndex].wrongInseminatedCnt++;
                    }
                }
            }
        }
    }
    return data;
}

/**
 *
 * @param allEvents
 * @param startDate
 * @param endDate
 * @param data
 * @returns {*}
 */
export function calculateInseminatedAnimals(
    allEvents,
    startDate,
    endDate,
    data
) {
    let events = allEvents.filter((item) =>
        isDayInRange(startDate, endDate, item.EvTime)
    );
    return passedUSG(events, data);
}

/**
 * Dni jałowe - czas liczony od daty ostatniej separacji do inseminacji ktora wystapila po niej.
 * Przypadki:
 * 1. Jesli po separacji wystapi inseminacja wyliczamy ile minelo dni
 * 2. Jesli po separacji nie wystapila inseminacja liczymy do dnia dzisiejszego
 * 3. Jesli nie wystapila separacja (przypadek starych swin bo teraz obowiazkowo wpisujemy date ostatniej sepracji) to 0
 * @param events - array
 * @param date
 * @param preEventsTable
 * @param cycleSettings
 * @returns {{idleDaysAvg: number, cycle: number, idleDays: number}}
 */
export function countIdleDays(events, date, preEventsTable, cycleSettings) {
    const idleDays = {};
    const filteredEvents = events.filter((ev) => ev.EvTime < date);
    const FirstCycleIndex = get(cycleSettings, "FirstCycleIndex", 0);
    const cycleTable =
        preEventsTable ??
        (preEvents(filteredEvents, null, false, cycleSettings).cycleTable ||
            []);
    const converted = convertRowsToCycles(cycleTable);
    let sum = 0;
    let div = 0;
    const maxCycle = Math.max(...converted.map((a) => a.cycle));
    for (let cycle of converted) {
        const cycleNumber = cycle.cycle;
        const cycleIndex = cycle.cycle - FirstCycleIndex;
        const separationsCurrentCycle = [
            ...converted[cycleIndex][EventTypes.SEPARATION],
            ...converted[cycleIndex][EventTypes.SOW_CYCLES],
        ];
        const cycleEvents = getEventsFromRow(converted[cycleIndex]);
        const balance = getPigBalance(cycleEvents);
        // jesli liczy od 1 to chcemy sprawdzac dopiero od 2 cyklu stad cycleIndex a nie cycleNumber
        if (cycleIndex > 0) {
            //niektóre stare eventy USG mają wartość pregnant na "true/false" zamiast 0,1,2
            const sensitiveEvents = [
                ...converted[cycleIndex][EventTypes.NO_PREGNANCY],
                ...converted[cycleIndex][EventTypes.USG].filter(
                    (ev) =>
                        isEqual(ev.EvData.Pregnant, USG_STATE.REPEAT) ||
                        !ev.EvData.Pregnant
                ),
            ].sort((a, b) => a.EvTime - b.EvTime);
            const sensitiveEventTime = !isEmpty(sensitiveEvents)
                ? last(sensitiveEvents).EvTime
                : 0;
            const lastInseminationTime = get(
                last(converted[cycleIndex][EventTypes.INSEMINATION]),
                "EvTime",
                0
            );
            const separationsCycleBefore = [
                ...converted[cycleIndex - 1][EventTypes.SEPARATION],
                ...converted[cycleIndex - 1][EventTypes.SOW_CYCLES],
            ];
            const lastSeparationTime = get(
                separationsCycleBefore[0],
                "EvTime",
                0
            );
            const separationsCurrentCycle = [
                ...converted[cycleIndex][EventTypes.SEPARATION],
                ...converted[cycleIndex][EventTypes.SOW_CYCLES],
            ];
            const currentCycleSeparationTime = get(
                separationsCurrentCycle[0],
                "EvTime",
                0
            );
            // jesli ma jakies prosiaki to dalej jest produktywna
            if (cycleEvents.length > 0 && balance > 0) {
                idleDays[`idleDays${cycleNumber}`] = 0;
                idleDays["cycle"] = cycleNumber;
                div += 1;
            } else if (lastInseminationTime && lastSeparationTime) {
                if (sensitiveEventTime >= lastInseminationTime) {
                    //jezeli pojawil sie event powodujący przywrócenie dni jałowych - usg negatywne / brak ciazy PÓŹNIEJ niż inseminacja w danym
                    idleDays[`idleDays${cycleNumber}`] = moment
                        .utc(date)
                        .startOf("day")
                        .diff(
                            moment(lastSeparationTime).startOf("day"),
                            "days"
                        );
                    idleDays["cycle"] = cycleNumber;
                    sum += idleDays[`idleDays${cycleNumber}`];
                    div += 1;
                } else if (lastSeparationTime <= lastInseminationTime) {
                    //jesli wystapila inseminacja po odsadzie z poprzedniego cyklu
                    idleDays[`idleDays${cycleNumber}`] = moment
                        .utc(lastInseminationTime)
                        .startOf("day")
                        .diff(
                            moment(lastSeparationTime).startOf("day"),
                            "days"
                        );
                    idleDays["cycle"] = cycleNumber;
                    sum += idleDays[`idleDays${cycleNumber}`];
                    div += 1;
                }
            } else if (
                !lastInseminationTime &&
                lastSeparationTime &&
                currentCycleSeparationTime &&
                moment(currentCycleSeparationTime).diff(
                    moment.utc(lastSeparationTime),
                    "days"
                ) >=
                    getTimeFromInseminationToPartuition() + getTimeOnBirthRoom()
            ) {
                //jesli nie ma inseminacji ale jest odsad w poprzednim i obecnym cyklu
                //wyliczany jest teoretyczny czas inseminacji i obliczana jest liczba dni jałowych
                //w przypadku gdy roznica w dniach pomiedzy odsadami jest mniejsza niz 100 dni to pomijany jest ten przypadek(odsad czesciowy)
                idleDays[`idleDays${cycleNumber}`] = moment(
                    moment(currentCycleSeparationTime).subtract(
                        getTimeFromInseminationToPartuition() +
                            getTimeOnBirthRoom(),
                        "days"
                    )
                )
                    .startOf("day")
                    .diff(moment(lastSeparationTime).startOf("day"), "days");
                idleDays["cycle"] = cycleNumber;
                sum += idleDays[`idleDays${cycleNumber}`];
                div += 1;
            }
            if (cycleNumber === maxCycle && balance === 0) {
                //dla ostatniego cyklu
                //jezeli w obecnym cyklu znajduje sie odsad to licze do daty do ktorej raport mial byc wygenerowany i wynik wrzucam w kolejny cykl
                if (
                    currentCycleSeparationTime &&
                    converted[cycleIndex][EventTypes.SOW_CYCLES][0]?.EvTime !==
                        currentCycleSeparationTime
                ) {
                    idleDays[`idleDays${cycleNumber + 1}`] = moment
                        .utc(date)
                        .startOf("day")
                        .diff(
                            moment(currentCycleSeparationTime).startOf("day"),
                            "days"
                        );
                    idleDays["cycle"] = cycleNumber + 1;
                    sum += idleDays[`idleDays${cycleNumber + 1}`];
                    div += 1;
                }
            }
        } else if (
            cycleNumber === FirstCycleIndex &&
            cycleNumber === maxCycle
        ) {
            //gdy cykl 0 jest ostatnim cyklem i był zrobiony odsad to policz od odsadu do daty raportu
            const currentCycleSeparationTime = get(
                separationsCurrentCycle[0],
                "EvTime",
                0
            );
            if (currentCycleSeparationTime) {
                idleDays[`idleDays${cycleNumber + 1}`] = moment
                    .utc(date)
                    .startOf("day")
                    .diff(
                        moment(currentCycleSeparationTime).startOf("day"),
                        "days"
                    );
                idleDays["cycle"] = cycleNumber + 1;
                sum += idleDays[`idleDays${cycleNumber + 1}`];
                div += 1;
            }
        }
        if (separationsCurrentCycle.length > 1 && balance === 0) {
            const _lastCurrentSeparationTime = get(
                last(separationsCurrentCycle),
                "EvTime",
                0
            );
            const _penultimateCurrentSeparationTime = get(
                separationsCurrentCycle[separationsCurrentCycle.length - 2],
                "EvTime"
            );
            const diffBetweenSeparations = moment(_lastCurrentSeparationTime)
                .startOf("day")
                .diff(
                    moment(_penultimateCurrentSeparationTime).startOf("day"),
                    "days"
                );
            if (diffBetweenSeparations > 0) {
                const idleDaysValue = idleDays[`idleDays${cycleNumber + 1}`];
                idleDays[`idleDays${cycleNumber + 1}`] =
                    `${idleDaysValue}/${diffBetweenSeparations}`;
            }
        }
    }
    idleDays["idleDaysAvg"] = div > 0 ? sum / div : 0;
    return idleDays;
}

export function getHistoryOfEventsData(start, stop, FarmID, showRemoved) {
    const result = [];
    const animals = {};
    const getAnimal = (AnmID) => {
        if (animals[AnmID]) return animals[AnmID];
        const animal = animalsDB.getAnimalById(AnmID, {
            joinEvents: false,
            findDeleted: true,
        });
        animals[AnmID] = animal;
        return animal;
    };
    const events = eventsDB.getAllEventsWithRange(
        start,
        stop,
        FarmID,
        showRemoved
    );
    for (const ev of events) {
        if (
            [
                EventTypes.PIGLET_RECLASSIFY,
                EventTypes.PARTURITION_STATE,
                EventTypes.TECHNOLOGY_GROUP_TRANSFER,
            ].includes(ev.EvCode) ||
            isUndefined(ev?.AnmID)
        )
            continue;
        const animal = getAnimal(ev.AnmID);
        result.push({
            AnmCnt: ev.AnmCnt,
            EvTime: ev.EvTime,
            OperID: get(ev, "EvData.OperID") || get(ev, "OperID"),
            EvData: ev.EvData,
            EvCode: ev.EvCode,
            animal: {
                AnmID: animal?.AnmID,
                AnmNo1: animal.AnmNo1,
                isAnimalRemoved: !!animal.DtaDelTime,
            },
            eventCode: {
                EvCode: ev.EvCode,
                isEventRemoved: !!ev.DtaDelTime,
            },
        });
    }

    return result;
}

export function getCommentsData(start, stop, FarmID) {
    const result = [];
    const animals = {};
    const getAnimal = (AnmID) => {
        if (animals[AnmID]) return animals[AnmID];
        const animal = animalsDB.getAnimalById(AnmID, {
            joinEvents: false,
            findDeleted: true,
        });
        animals[AnmID] = animal;
        return animal;
    };
    const events = eventsDB.getAllEventsWithRange(start, stop, FarmID);
    for (const ev of events) {
        if (ev.EvCode !== EventTypes.COMMENTS || isUndefined(ev?.AnmID))
            continue;
        const animal = getAnimal(ev.AnmID);
        result.push({
            AnmID: animal?.AnmID,
            AnmNo1: animal.AnmNo1,
            EvTime: ev.EvTime,
            Comment: get(ev, "EvData.Comments"),
            EvData: ev.EvData,
            OperID: get(ev, "EvData.OperID") || get(ev, "OperID"),
        });
    }
    return result;
}

export function getRankingData(
    data,
    day,
    utilResults,
    herdSettings = {},
    {animals, events} = {},
    showDead = false
) {
    const finalDate = moment(day).endOf("day");
    const animalsMap = new Map();
    const animalsToDate = animals.filter(
        (animal) =>
            animal.AnmCnt > 0 &&
            animal.AnimalKind === AnimalTypes.SOW &&
            animal.DtaBrthTime <= +finalDate &&
            !animal.DtaDelTime &&
            (!animal.DtaDthTime ||
                (!showDead ? animal.DtaDthTime > +finalDate : true))
    );
    animalsToDate.forEach((animal) => {
        let animalEvents = events
            .filter((e) => e.AnmID === animal.AnmID)
            .sort((a, b) => a.EvTime - b.EvTime);
        if (day !== undefined)
            animalEvents = animalEvents.filter(
                (event) => event.EvTime <= +finalDate
            );
        const payload = {
            Animal: animal,
            CycleTable: get(
                preEvents(animalEvents, null, false, utilResults, true),
                "cycleTable",
                []
            ),
        };
        animalsMap.set(animal.AnmID, payload);
    });
    if (animalsMap.size) {
        for (const animalData of animalsMap.values()) {
            let obj = {
                AnmID: get(animalData, "Animal.AnmID", ""),
                AnmNo1: get(animalData, "Animal.AnmNo1", ""),
                Age: "",
                Cycle: get(
                    last(animalData.CycleTable),
                    "cycle",
                    utilResults.FirstCycleIndex
                ),
                PlcmntID: get(animalData, "Animal.PlcmntID"),
                Tsi: "",
                ProductionCode: "",
                IndexDate: "",
                ProductionPurpose: "",
                IsDead: !!get(animalData, "Animal.DtaDthTime"),
            };
            let birthTime = get(animalData, "Animal.DtaBrthTime", 0);
            if (birthTime) {
                obj.Age = finalDate
                    .clone()
                    .diff(moment(birthTime).endOf("day"), "days", 1);
            }
            if (animalData.CycleTable.length) {
                let {results, cycleResults} = getParturitionIndexForASow(
                    animalData.Animal,
                    animalData.CycleTable,
                    utilResults
                );
                obj.Animal = animalData.Animal;
                obj.CycleResults = cycleResults;
                obj.Repetitions = results.repetitions;
                obj.IdleDays = results.idleDays;
                obj.AverageIdleDays = results.avgIdleDays;
                obj.HealthyPiglets = results.healthyCnt;
                obj.AverageHealthyPiglets = results.avgHealthyCnt;
                obj.WeakPiglets = results.weakCnt;
                obj.AverageWeakPiglets = results.avgWeakCnt;
                obj.MummyPiglets = results.mummyCnt;
                obj.AverageMummyPiglets = results.avgMummyCnt;
                obj.DeadPiglets = results.deadCnt;
                obj.GiltsPiglets = results.giltsCnt;
                obj.AverageDeadPiglets = results.avgDeadCnt;
                obj.ParturitionIndex = results.parturitionIndex;
                obj.AverageParturitionedPigletWeight =
                    results.parturitionWeight;
                obj.AverageSeparatedPigletWeight = results.separationWeight;
                obj.SeparatedPiglets = results.separatedCnt;
                obj.AverageSeparatedPiglets = results.avgSeparatedCnt;
                obj.Nipples = results.activeNipples;
                obj.FallPiglets = results.fallPigletsCnt;
                obj.AverageFallPiglets = results.avgFallPigletsCnt;
                obj.AverageLactationDays = results.lactationDays;
                obj.AverageSeparationFromMommyCnt =
                    results.separationToMommyAmount;
                obj.AverageSeparationToMommyCnt = results.mommyAmount;
                obj.PigletsMortality = results.pigletsMortality;
                obj.ParturitionCnt = results.parturitionCnt;
                obj = {...obj, ...results};
            }
            data.push(obj);
        }
    }
    // generowanie procentow tak aby bylo tylko w jednej funkcji - majac juz wszystkie swinie mozemy znow przeleciec aby wyliczyc procenty
    let _data = [];
    for (let index = 0; index < data.length; index++) {
        const {AnmID} = data[index];
        let sowData = getSowResult(data, {AnmID}, utilResults, herdSettings);
        _data[index] = {
            ...data[index],
            ...(sowData.result || {}),
        };
    }
    return _data;
}

export function getAnimalNo1ByAnmID(anmID) {
    if (!anmID) return "";
    return get(animalsDB.getAnimalById(anmID), "AnmNo1", "?");
}
export function translateFormatter(key) {
    return i18next.t(key);
}
export function getAnimalGroupNo1ByID(grpID) {
    if (!grpID) return "";
    return get(groupsDB.findGroupByID(grpID), "GrNo1", "?");
}

export function getAnimalTypeTranslation(animalType) {
    const animalKind = getAnimalKinds(i18next.t);
    let kind = animalKind[animalType] || "?";
    return kind[0].toUpperCase() + kind.slice(1);
}
export function getGiltType(value) {
    return value === AnimalTypes.RENOVATION_SOW
        ? i18next.t("animalKind.5")
        : i18next.t("animalKind.6");
}
export function getDateString(timestamp, {format = "L", utc = false} = {}) {
    if (!timestamp) return "";
    if (utc) return moment(timestamp).utc().format(format);
    return moment(timestamp).format(format);
}

export function getTreatmentTypeByIndex(index) {
    const translate = (type) => i18next.t([`grid.treatmentTypes.${type}`, "?"]);
    let treatment;
    switch (+index) {
        case medicineTypes.VACCINE: {
            treatment = translate("grafting");
            break;
        }
        case medicineTypes.FORAGE: {
            treatment = translate("medicatedFeed");
            break;
        }
        case medicineTypes.DOSATRON: {
            treatment = translate("dosatron");
            break;
        }
        case medicineTypes.STIMULATOR: {
            treatment = translate("stimulator");
            break;
        }
        default: {
            treatment = "?";
        }
    }
    return treatment[0].toUpperCase() + treatment.slice(1);
}

export function getMedicineDoseByMedicineID({dose, unit}) {
    if (dose && unit) {
        return `${parseFloat(dose).toFixed(1)} ${unit}`.trim();
    }
    return "-";
}

export function getLocationPathByPlcmntID(plcmntID) {
    return formatLocationName(plcmntID, {notFoundText: "-"});
}

export function getPinnedRowSum(
    data = [],
    key = "",
    formatter = (value) => {
        return value;
    }
) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += formatter(+get(data[i], key, 0));
    }
    return sum;
}

export function getPinnedRowAvg(
    data = [],
    key = "",
    formatter = (value) => {
        return value;
    }
) {
    let sum = 0;
    let counter = 0;
    for (let i = 0; i < data.length; i++) {
        sum += formatter(+get(data[i], key, 0));
        counter += isNil(get(data[i], key)) ? 0 : 1;
    }
    return counter ? sum / counter : 0;
}

export function employeesValueFormatter(value, employees, serviceUsers) {
    if (isNil(value)) return "";
    if (typeof value === "object") return value.Name;
    if (value === "Fetura Cloud") return value;
    if (value.toLowerCase() === "system") return "Fetura Cloud";
    let serviceUser =
        serviceUsers.find((item) => item.LocalUserID === value) || null;
    if (serviceUser) {
        if (checkIfUserIsService()) return serviceUser.Name;
        return i18next.t("newSettings.users.changeRolesView.userTypes.V");
    }
    let employee = employees.find((item) => item.LocalUserID === value);
    if (employee)
        return `${employee.firstname || ""} ${employee.surname || ""}`;
}

export function locationPlcmntIDFormatter(PlcmntID) {
    let text = "";
    for (let plcmntID of PlcmntID || []) {
        let tree = buildingsDB.getTreeByLocationID(plcmntID.PlcmntID);
        if (tree) {
            if (tree.box)
                text += `${tree.chamber.CName} - ${tree.box.BoxesName} `;
            else if (tree.chamber) text += `${tree.chamber.CName} `;
            else if (tree.sector) text += `${tree.sector.SName} `;
            else if (tree.building) text += `${tree.building.BName} `;
        }
    }
    return text;
}

export const getTranslationEventKey = (EvCode) => {
    switch (EvCode) {
        case EventTypes.INSERTION:
            return "insertion";
        case EventTypes.TREATMENT:
            return "scheduleOfTreatment";
        case EventTypes.RECLASSIFY:
            return "events.reclassify";
        case EventTypes.SOW_CYCLES:
            return `eventTypes.${EventTypes.SEPARATION}`;
        case EventTypes.TO_INSEMINATION:
            return "toInsemination";
        case EventTypes.PARTURITION_START:
            return "logsView.shortNames.1005";
        case EventTypes.PARTURITION_END:
            return "logsView.shortNames.1006";
        case EventTypes.FIRST_MILK:
            return "shadowDescription.EVENT_P.EvData.FirstMilk._title";
        case EventTypes.TATTOO:
            return "tattooing";
        case EventTypes.COMMENTS:
            return "printSelectedAnimalsModal.comments";
        case EventTypes.HEAT:
            return "heat";
        default:
            return `eventTypes.${EvCode}`;
    }
};

export function createLocalReport(
    FarmID,
    type,
    {
        startDate = 0,
        endDate = moment().utc().endOf("day"),
        day = moment().utc().endOf("day"),
    } = {},
    status = reportStatus.LOADING
) {
    let queryParameters = {
        farmsArray: [FarmID],
        startDate,
        endDate,
    };
    if (
        [
            reportType.STRUCTURE,
            reportType.RANKING,
            reportType.IDLE,
            reportType.GILTS,
        ].includes(type)
    ) {
        queryParameters = {
            farmsArray: [FarmID],
            startDate: moment(day).utc().startOf("day"),
            endDate: moment(day).utc().startOf("day"),
        };
    }
    return {
        FeturaQuery: {
            QueryParams: queryParameters,
            FeturaQTime: new Date().getTime(),
            QueryCode: type,
        },
        QueryExecution: {
            Status: {
                State: status,
            },
        },
        isLocal: true,
    };
}

export function isOnFarm(report, FarmID) {
    return (
        report.FeturaQuery.QueryParams.farmsArray.length === 1 &&
        report.FeturaQuery.QueryParams.farmsArray.includes(FarmID)
    );
}
