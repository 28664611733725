import moment from "moment";
import {findIndex} from "lodash";

export const initialValue = {
    tasks: {Items: [], NextToken: null}, // lista zadań
    filters: {DtaPlanned: {value: "any"}, Status: "any"}, // zaznaczone tylko zadania do wykonania
    loading: false, // czy pobierane są taskidata
    month: moment.utc().month(), // wybrany miesiąc dla kalendarza
    year: moment.utc().year(), // wybrany rok dla kalendarza
    loadingNewerData: false,
    NextTokenTop: null,
    NextTokenBottom: null,
    files: {},
    grouped: [],
    color: "project",
    aggregation: "DtaPlanned",
    sort: "ASC",
    bookmarkSettingModal: false,
    selectedBookmark: null,
    createTaskModal: false,
};

export default function taskReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_AGGREGATION":
            return {...state, aggregation: action.payload};
        case "SET_SORT":
            return {...state, sort: action.payload};
        case "SET_FILTERS":
            return {...state, filters: action.payload};
        case "SET_BOOKMARK_SETTING_MODAL":
            return {...state, bookmarkSettingModal: action.payload};
        case "SELECT_BOOKMARK":
            return {...state, selectedBookmark: action.payload};
        case "GET_TASKS_PENDING":
            return {
                ...state,
                loading: action.meta.setLoading,
                loadingNewerData: action.meta.keepExisting,
            };
        case "GET_TASKS_FULFILLED": {
            let tasks = action.meta.keepExisting
                ? action.meta.scroll === "top"
                    ? [...action.payload.Items.reverse(), ...state.tasks.Items]
                    : [...state.tasks.Items, ...action.payload.Items]
                : action.payload.Items;
            return {
                ...state,
                tasks: {...action.payload, Items: tasks},
                loading: false,
                loadingNewerData: false,
                NextTokenTop:
                    action.meta.scroll === "top"
                        ? action.payload.NextToken
                        : state.NextTokenTop,
                NextTokenBottom:
                    action.meta.scroll === "bottom"
                        ? action.payload.NextToken
                        : state.NextTokenBottom,
            };
        }
        case "GET_TECHNOLOGYGROUP_TASKS_FULFILLED": {
            let tasks = action.payload.Items;
            return {
                ...state,
                technologyGroupTasks: {...action.payload, Items: tasks},
            };
        }
        case "GET_TASKS_REJECTED":
            return {
                ...state,
                tasks: {Items: [], NextToken: null},
                loading: action.payload.name === "AbortError",
                loadingNewerData: false,
            };
        case "CHANGE_TASK_MONTH_AND_YEAR":
            return {
                ...state,
                month: action.payload.month,
                year: action.payload.year,
            };
        case "SET_TASK_STATUS": {
            const index = findIndex(
                state.tasks.Items,
                (o) => o.IssueID === action.meta.IssueID
            );
            const copy = state.tasks.Items.slice();
            copy[index] = {...copy[index], Status: action.payload};
            const obj = {
                tasks: {...state.tasks, Items: copy},
            };
            if (state.grouped.length > 0) {
                const groups = state.grouped.slice();
                for (let i = 0; i < groups.length; i++) {
                    const group = groups[i];
                    const index = findIndex(
                        group.Items,
                        (o) => o.IssueID === action.meta.IssueID
                    );
                    if (index !== -1) {
                        const copy = group.Items.slice();
                        copy[index] = {...copy[index], Status: action.payload};
                        groups[i] = {...groups[i], Items: copy};
                        obj.grouped = groups;
                        break;
                    }
                }
            }
            return {
                ...state,
                ...obj,
            };
        }
        case "CLEAR_TASKS":
            return {
                ...state,
                tasks: initialValue.tasks,
                NextTokenTop: null,
                NextTokenBottom: null,
            };
        case "GET_TASKS_WITH_REPLACE_FULFILLED": {
            let tasks = state.tasks.Items.slice(0);
            for (let task of action.payload.Items) {
                let index = findIndex(tasks, (o) => o.IssueID === task.IssueID);
                if (index === -1) {
                    tasks.push(task);
                } else {
                    tasks[index] = task;
                }
            }
            tasks = tasks.filter(
                (item) =>
                    item.DtaPlanned !== action.meta.DtaPlanned ||
                    (action.meta.FarmID &&
                        item.FarmID !== action.meta.FarmID) ||
                    !!action.payload.Items.find((subItem) => {
                        console.log(subItem, item);
                        return subItem.IssueID === item.IssueID;
                    })
            );
            return {...state, tasks: {...state.tasks, Items: tasks}};
        }
        case "EDIT_TASK": {
            const tasks = state.tasks.Items.slice();
            let index = findIndex(
                tasks,
                (o) => o.IssueID === action.payload.IssueID
            );
            if (index === -1) {
                tasks.push(action.payload);
            } else {
                tasks[index] = action.payload;
            }
            return {...state, tasks: {...state.tasks, Items: tasks}};
        }
        case "ADD_TASK_COMMENT": {
            if (action.meta.issueID) {
                const clone = state.tasks.Items.slice();
                const index = findIndex(
                    clone,
                    (o) => o.IssueID === action.meta.issueID
                );
                const task = JSON.parse(JSON.stringify(clone[index]));
                if (!task.Comments) task.Comments = [];
                task.Comments.push(action.payload);
                clone[index] = task;
                return {...state, tasks: {...state.tasks, Items: clone}};
            }
            return state;
        }
        case "REMOVE_TASK_COMMENT": {
            // TODO przerobic na wpisywanie do listy zadan
            return state;
        }
        case "EDIT_TASK_COMMENT": {
            // TODO przerobic na wpisywanie do listy zadan
            return state;
        }
        case "ADD_TEMPORARY_TASK": {
            const items = state.tasks.Items.slice();
            items.push(action.payload);
            items.sort((a, b) => a.DtaPlanned - b.DtaPlanned);
            return {...state, tasks: {...state.tasks, Items: items}};
        }
        case "REMOVE_TASK_FROM_LIST": {
            const items = state.tasks.Items.slice();
            const index = findIndex(
                items,
                (o) => o.IssueID === action.payload.id
            );
            if (index !== -1) {
                items.splice(index, 1);
                return {...state, tasks: {...state.tasks, Items: items}};
            }
            return state;
        }
        case "START_TASK_FILE_UPLOAD": {
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.meta.IssueID]: {
                        ...(state.files[action.meta.IssueID] || {}),
                        [action.meta.FileID]: {
                            current: 0,
                            size: action.payload.size,
                            file: action.payload.file,
                        },
                    },
                },
            };
        }
        case "TASK_FILE_UPLOAD_PROGRESS": {
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.meta.IssueID]: {
                        ...state.files[action.meta.IssueID],
                        [action.meta.FileID]: {
                            ...state.files[action.meta.IssueID][
                                action.meta.FileID
                            ],
                            current: action.payload.loaded,
                        },
                    },
                },
            };
        }
        case "TASK_FILE_UPLOAD_REMOVE": {
            if (!state.files[action.meta.IssueID]) return state;
            const issueObject = {...(state.files[action.meta.IssueID] || {})};
            delete issueObject[action.meta.FileID];
            return {
                ...state,
                files: {...state.files, [action.meta.IssueID]: issueObject},
            };
        }
        case "GET_GROUPED_TASKS_PENDING": {
            return {
                ...state,
                loading: action.meta.isLoading ? action.meta.replace : false,
                loadingNewerData: !action.meta.replace,
            };
        }
        case "GET_GROUPED_TASKS_FULFILLED": {
            if (!action.meta.replace) {
                const groups = state.grouped.slice();
                const tasks = state.tasks.Items.slice();
                for (let i = 0; i < action.meta.groupNames.length; i++) {
                    const row = action.meta.groupNames[i];
                    const index = groups.findIndex(
                        (item) => item.name === row.name
                    );
                    groups[index] = {
                        ...groups[index],
                        ...row,
                        ...action.payload[i],
                        Items: [
                            ...groups[index].Items,
                            ...action.payload[i].Items,
                        ],
                    };
                    tasks.push(...action.payload[i].Items);
                }
                return {
                    ...state,
                    grouped: groups,
                    loadingNewerData: false,
                    tasks: {...state.tasks, Items: tasks},
                };
            } else {
                const groups = [];
                const tasks = [];
                for (let i = 0; i < action.meta.groupNames.length; i++) {
                    const row = action.meta.groupNames[i];
                    const oldGroup = state.grouped.find(
                        (item) => item.name === row.name
                    );
                    console.log(oldGroup);
                    const items = action.payload[i]
                        ? action.payload[i].Items
                        : [];
                    groups.push({
                        ...row,
                        show: oldGroup
                            ? oldGroup.show
                            : row.hasOwnProperty("show")
                              ? action.meta.clearCurrent
                                  ? row.show
                                  : state.grouped[i].show
                              : true,
                        ...action.payload[i],
                        Items: items.slice(0, action.meta.maxItems),
                    });
                    tasks.push(...items);
                }
                return {
                    ...state,
                    loading: false,
                    grouped: groups,
                    tasks: {...state.tasks, Items: tasks},
                };
            }
        }
        case "CHANGE_TASK_GROUP_SHOW_STATUS": {
            const groupIndex = state.grouped.findIndex(
                (item) => item.name === action.payload.name
            );
            if (groupIndex === -1) return state;
            const copy = state.grouped.slice();
            copy[groupIndex] = {
                ...copy[groupIndex],
                show: !copy[groupIndex].show,
            };
            return {...state, grouped: copy};
        }
        case "GET_TASKS_FOR_PROJECTS_FULFILLED": {
            const tasks = [];
            for (let call of action.payload) {
                if (call.status === "fulfilled") {
                    tasks.push(...call.value.Items);
                }
            }
            return {...state, tasks: {...state.tasks, Items: tasks}};
        }
        case "CHANGE_COLOR_METHOD": {
            return {...state, color: action.payload};
        }
        case "CLEAR_GROUPS": {
            return {...state, grouped: []};
        }
        case "ADD_TASK_TO_DO_ITEM": {
            if (action.meta.issueID) {
                const clone = state.tasks.Items.slice();
                const index = findIndex(
                    clone,
                    (o) => o.IssueID === action.meta.issueID
                );
                const task = JSON.parse(JSON.stringify(clone[index]));
                if (!task.ListTODO) task.ListTODO = [];
                task.ListTODO.push(action.payload);
                clone[index] = task;
                return {...state, tasks: {...state.tasks, Items: clone}};
            }
            return state;
        }
        case "REMOVE_TASK_TO_DO_ITEM": {
            if (action.meta.issueID) {
                const clone = state.tasks.Items.slice();
                const index = findIndex(
                    clone,
                    (o) => o.IssueID === action.meta.issueID
                );
                const task = JSON.parse(JSON.stringify(clone[index]));
                const itemIndex = findIndex(
                    task.ListTODO,
                    (o) => o.Text === action.meta.Text
                );
                task.ListTODO.splice(itemIndex, 1);
                clone[index] = task;
                return {...state, tasks: {...state.tasks, Items: clone}};
            }
            return state;
        }
        case "SET_TO_DO_ITEM_STATUS": {
            if (action.meta.issueID) {
                const clone = state.tasks.Items.slice();
                const index = findIndex(
                    clone,
                    (o) => o.IssueID === action.meta.issueID
                );
                const task = JSON.parse(JSON.stringify(clone[index]));
                const itemIndex = findIndex(
                    task.ListTODO,
                    (o) => o.ListID === action.meta.listID
                );
                task.ListTODO[itemIndex].Done = action.payload.Done;
                clone[index] = task;
                return {...state, tasks: {...state.tasks, Items: clone}};
            }
            return state;
        }
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
