import {createSelector} from "reselect";
import {
    get,
    groupBy,
    isArray,
    isEmpty,
    isNil,
    isString,
    memoize,
    uniq,
    uniqBy,
} from "lodash";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import {Interfaces} from "../constans/devices";
import {getShadowLoadings, getShadows} from "./shadowSelectors";
import moment from "moment";
import {getFarms, getTestSwitchStatus} from "./farmSelector";
import {checkIfUserIsService} from "../utils/NewRolesUtils";
import memoizeOne from "memoize-one";
import {getBuildingsMap, makeGetBuildingsMap} from "./buildingsSelector";
import {
    getDevicesMapType,
    getSupportedVersionRangesForDeviceType,
    hasPlcmntID,
} from "../utils/DevicesUtils";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import {isIPSUM} from "../utils/DispenserNRFUtils";
import {getStatus} from "./mqttSelecor";
import {isBetween} from "../utils/MathUtils";

const changePlacementToArray = memoize(
    (placement) =>
        isArray(placement)
            ? placement
            : isString(placement)
              ? [{PlcmntID: placement}]
              : [],
    (...args) => JSON.stringify(args)
);

const changeDevTypeToArray = memoize(
    (DevType) =>
        isArray(DevType) ? DevType : isString(DevType) ? [DevType] : [],
    (...args) => JSON.stringify(args)
);

export const getDevices = (state) => state.farmDevices.devices;

const getDeviceType = (state, {DevType}) => changeDevTypeToArray(DevType);

const getDeviceID = (state, {DevID}) => DevID;

const getPlacementIDs = (state, {PlcmntID}) => changePlacementToArray(PlcmntID);

export const makeGetDevicesByType = () => {
    return createSelector(
        [getDevices, getDeviceType],
        (_devices, _devTypes) => {
            return _devices.filter((d) => _devTypes.includes(d.DevType));
        }
    );
};

export const makeGetDeviceByID = () => {
    return createSelector([getDevices, getDeviceID], (_devices, _devId) => {
        return isNil(_devId)
            ? null
            : _devices.find((d) => d.DevID === _devId) || null;
    });
};

const hasLocation = (aPlcmnts = [], bPlcmnts = []) => {
    for (let a of aPlcmnts) {
        for (let b of bPlcmnts) {
            if (a.PlcmntID === b.PlcmntID) {
                return true;
            }
        }
    }
    return false;
};

const createDeviceDict = memoizeOne((devices) => {
    const map = {};
    for (let device of devices) {
        const devicePlacements = changePlacementToArray(device.PlcmntID);
        for (let {PlcmntID} of devicePlacements) {
            if (isString(PlcmntID)) {
                if (!map[device.DevType]) map[device.DevType] = {};
                if (!map[device.DevType][PlcmntID])
                    map[device.DevType][PlcmntID] = [];
                map[device.DevType][PlcmntID].push(device);
            }
        }
    }
    return map;
});

export const getDevicesInPlacement = createSelector(
    [getDevices, getPlacementIDs, getDeviceType],
    (_deviceList, _placementIdList, _devTypes) => {
        const dictionary = createDeviceDict(_deviceList);
        const omitDevType = isEmpty(_devTypes);
        const devices = [];
        for (let [DevType, placementDict] of Object.entries(dictionary)) {
            if (omitDevType || _devTypes.includes(DevType)) {
                _placementIdList.forEach(({PlcmntID}) => {
                    const tmp = placementDict[PlcmntID]
                        ? placementDict[PlcmntID]
                        : null;
                    if (tmp) devices.push(...tmp);
                });
            }
        }
        return devices;
    }
);

export const makeGetDevicesInPlacement = () => getDevicesInPlacement;

export const getSearchGateway = (state) => state.farmDevices.search.gateway;

const _getFoundDevices = (state) => state.farmDevices.search.foundDevices;

export const getFoundDevices = createSelector(
    _getFoundDevices,
    getDevices,
    (search, devices) => {
        console.log(search, devices);
        return search.map((row) => {
            let deviceByMAC = devices.find(
                (item) =>
                    (item.DevType === DevTypes.BRIDGE ||
                        item.DevType === DevTypes.RTE485) &&
                    item.Settings.ETH?.MACAddress === row.general.MAC
            );
            return {
                ...row,
                device: deviceByMAC,
            };
        });
    }
);

export const getSearchLoading = (state) => state.farmDevices.search.loading;

const getETHParamsLoading = (state, props) =>
    state.farmDevices.search.loadingParams[props.object.general.MAC];

export const makeGetETHParamsLoading = () =>
    createSelector(getETHParamsLoading, (loading) => loading);

export const getGateways = (state) => state.farmDevices.gateways;

export const getGatewaysLoading = (state) => state.farmDevices.loadingGateways;

export const getAlerts = (state) => state.farmDevices.alerts;

export const getAlertsSearch = (state) => state.farmDevices.alertsSearch;

export const getMultiByPlcmntID = createSelector(
    getDevices,
    getPlacementIDs,
    (devices, placementIdList) => {
        let nrfs = devices.filter((device) => {
            const devicePlacements = changePlacementToArray(device.PlcmntID);
            return (
                device.DevType === DevTypes.DISPENSER_NRF &&
                hasLocation(devicePlacements, placementIdList)
            );
        });
        let multis = new Map();
        for (let nrf of nrfs) {
            let multiAddress = nrf.Address & 0xff00;
            let multi = devices.find(
                (device) =>
                    device.Address === multiAddress &&
                    device.Interface === nrf.Interface &&
                    device.ParentID === nrf.ParentID
            );
            if (multi) {
                multis.set(multi.DevID, multi);
            }
        }
        if (multis.size === 1) {
            return multis.values().next().value;
        }
        return null;
    }
);

export const getSwitchDispenserData = createSelector(
    getMultiByPlcmntID,
    getPlacementIDs,
    getShadows,
    getDevices,
    getShadowLoadings,
    (multi, placementIdList, shadows, devices, shadowsLoading) => {
        if (multi) {
            let currentPlcmnt = placementIdList[0].PlcmntID;
            let subAddressesInPlcmnt = [];
            let subAddresses = [];
            let subAddressesNotAnswering = true;
            for (let device of devices) {
                if (device.DevType === DevTypes.DISPENSER_NRF) {
                    let multiAddress = device.Address & 0xff00;
                    if (
                        multi.Address === multiAddress &&
                        device.Interface === multi.Interface &&
                        device.ParentID === multi.ParentID
                    ) {
                        subAddresses.push(device.Address & 0x00ff);
                    }
                    if (
                        device.PlcmntID &&
                        device.PlcmntID.find(
                            (item) => item.PlcmntID === currentPlcmnt
                        )
                    ) {
                        subAddressesInPlcmnt.push(device);
                    }
                }
            }
            subAddresses.sort((a, b) => a - b);
            let shadow = shadows.get(multi.DevID);
            if (shadow) {
                let lastResponseTime = 0;
                for (let key in shadow.metadata) {
                    if (shadow.metadata[key] > lastResponseTime) {
                        lastResponseTime = shadow.metadata[key];
                    }
                }
                // urzadzenie nie odpowiedzialo w ciagu 15 minut
                if (moment().diff(lastResponseTime, "minute") > 15) {
                    shadow = null;
                }
            }
            let newAddresses = [];
            if (shadow) {
                for (let i = 0; i < shadow.statusCAN.status.length; i++) {
                    let status = shadow.statusCAN.status[i];
                    if (status && !subAddresses.includes(i + 1)) {
                        newAddresses.push(i + 1);
                    }
                }
                for (let device of subAddressesInPlcmnt) {
                    let subAddress = device.Address & 0x00ff;
                    if (
                        !shadow.statusCAN.status[subAddress - 1] ||
                        shadow.statusCAN.time[subAddress - 1] > 60000
                    ) {
                        subAddressesNotAnswering = false;
                    }
                }
            }
            return {
                device: multi,
                shadow: shadow || null,
                loadingShadow: shadowsLoading[multi.DevID],
                newAddresses,
                subAddresses,
                subAddressesInPlcmnt,
                subAddressesNotAnswering,
            };
        }
        return null;
    }
);

function countErrors(data) {
    let counter = {
        errors: 0,
        warnings: 0,
    };
    if (!data) return counter;
    for (let key in data) {
        if (typeof data[key] === "object") {
            counter.errors += data[key].activeAlertCounter;
            counter.warnings += data[key].activeWarnCounter;
        }
    }
    return counter;
}

function joinCategories(data, current) {
    if (!current) return data ? JSON.parse(JSON.stringify(data)) : null;
    if (!data) return current ? JSON.parse(JSON.stringify(current)) : null;
    let tmp = {...JSON.parse(JSON.stringify(current))};
    for (let key in data) {
        if (typeof data[key] === "object") {
            for (let subKey in data[key]) {
                tmp[key][subKey] = (tmp[key][subKey] || 0) + data[key][subKey];
            }
        }
    }
    return tmp;
}

/**
 * Selektor tworzący dane dla widoku FarmPreview
 * @type {OutputSelector<unknown, unknown[], (res1: *, res2: unknown, res3: ([]|*)) => unknown[]>}
 * @private
 */
const _getGatewaysAlerts = createSelector(
    getGateways,
    getFarms,
    getAlerts,
    getTestSwitchStatus,
    (gateways, farms, alerts, testSwitch) => {
        let data = {};
        let filteredFarms = checkIfUserIsService()
            ? farms.filter((item) =>
                  testSwitch
                      ? item.TestFarm === true
                      : testSwitch !== null
                        ? !item.TestFarm
                        : true
              )
            : farms;
        for (let gateway of gateways) {
            let farm = filteredFarms.find(
                (item) => item.FarmID === gateway.FarmID
            );
            if (!farm) continue;
            if (!data[farm.FarmID])
                data[farm.FarmID] = {
                    farm,
                    gateways: [],
                    errors: 0,
                    warnings: 0,
                    isNoneConnected: true,
                    loading: false,
                    categories: null,
                };
            let alertsData = alerts.data[gateway.DevID];
            let counter = countErrors(alertsData);
            data[farm.FarmID].errors += counter.errors;
            data[farm.FarmID].warnings += counter.warnings;
            data[farm.FarmID].categories = joinCategories(
                alertsData,
                data[farm.FarmID].categories
            );
            let noConnection = alerts.errors[gateway.DevID] || false;
            if (!noConnection) {
                data[farm.FarmID].isNoneConnected = false;
            }
            let loading = alerts.loading[gateway.DevID];
            if (loading) {
                data[farm.FarmID].loading = true;
            }
            data[farm.FarmID].gateways.push({
                gateway,
                loading,
                noConnection,
                data: counter || null,
                refreshDate: alerts.refreshTimes[gateway.DevID] || null,
                version: alertsData?.version,
                compilation: alertsData?.compilation,
            });
        }
        let values = Object.values(data);
        values.sort((a, b) => a.farm.FarmName.localeCompare(b.farm.FarmName));
        let groupedByClient = groupBy(
            values,
            (o) => o.farm.PreviewGroup || o.farm.ClientID
        );
        // sortowanie po nazwie fermy wewnatrz grupy
        let tmp = Object.keys(groupedByClient).map((key) => ({
            ClientID: groupedByClient[key][0].farm.ClientID,
            Alias:
                groupedByClient[key][0].farm.PreviewGroup ||
                groupedByClient[key][0].farm.Alias,
            farms: groupedByClient[key],
            group: !!groupedByClient[key][0].farm.PreviewGroup,
        }));
        // sortowanie po ilosci errorow, potem warningow dla calej grupy
        tmp.sort((a, b) => {
            let aData = {
                errors: a.farms.reduce(
                    (prev, current) => prev + current.errors,
                    0
                ),
                warnings: a.farms.reduce(
                    (prev, current) => prev + current.warnings,
                    0
                ),
            };
            let bData = {
                errors: b.farms.reduce(
                    (prev, current) => prev + current.errors,
                    0
                ),
                warnings: b.farms.reduce(
                    (prev, current) => prev + current.warnings,
                    0
                ),
            };
            return bData.errors - aData.errors !== 0
                ? bData.errors - aData.errors
                : bData.warnings - aData.warnings;
        });
        return tmp;
    }
);

/**
 * Selektor filtrujący dane dla widoku FarmPreview, są 2 selektory, żeby nie przeliczać danych na każdej zmianie filtrowania
 * @type {OutputSelector<unknown, unknown[], (res1: unknown[], res2: (string|*)) => unknown[]>}
 */
export const getGatewaysAlerts = createSelector(
    _getGatewaysAlerts,
    getAlertsSearch,
    (data, search) => {
        if (search.length > 0) {
            let filteredByClients = data.filter((item) => {
                let filterString =
                    `${item.ClientID}${item.Alias}`.toLowerCase();
                return filterString.includes(search.toLowerCase());
            });
            let filteredByFarms = [];
            for (let row of data) {
                let forceGroup = row.farms.length > 1;
                let farmsInRow = row.farms.filter((farm) => {
                    let filterString =
                        `${farm.farm.FarmID}${farm.farm.FarmName}`.toLowerCase();
                    return filterString.includes(search.toLowerCase());
                });
                if (farmsInRow.length > 0) {
                    filteredByFarms.push({
                        ...row,
                        farms: farmsInRow,
                        forceGroup,
                    });
                }
            }
            let merged = [...filteredByClients, ...filteredByFarms];
            return uniqBy(merged, (o) => o.ClientID);
        }
        return data;
    }
);

export const getGatewayAlertsTable = createSelector(
    getGatewaysAlerts,
    (alerts) => {
        let data = [];
        for (let group of alerts) {
            for (let row of group.farms) {
                for (let gateway of row.gateways) {
                    data.push({...gateway, farm: row.farm});
                }
            }
        }
        data.sort((a, b) => {
            if (a.data && b.data) {
                return b.data.activeAlertCounter - a.data.activeAlertCounter;
            }
            if (a.data) return -1;
            return 1;
        });
        return data;
    }
);

export const makeGetGatewayIDs = () =>
    createSelector(getDevices, (device) => {
        console.log("makeGetGatewayIDs", device);
        return device
            .filter((item) => item.DevType === DevTypes.GATEWAY)
            .map((item) => item.DevID);
    });

export const getDeviceProgramsLoading = (state) =>
    state.farmDevices.programs.loading;

const getDeviceProgramsData = (state) => state.farmDevices.programs.data;

const getDeviceProgramsDevType = (state, props) => props.DevType;

const getDeviceProgramsHardware = (state, props) => props.hardware;

const getSupportedMajorAndMinorRanges = createSelector(
    getDeviceProgramsDevType,
    getSupportedVersionRangesForDeviceType
);

const getDevicePrograms = createSelector(
    getDeviceProgramsData,
    getDeviceProgramsDevType,
    getDeviceProgramsHardware,
    (data, DevType, hardware) => {
        let defaultValue = {
            dev: [],
            staging: [],
            prod: [],
        };
        if (!data[DevType] || !data[DevType][hardware]) {
            return defaultValue;
        }
        return {
            ...defaultValue,
            ...data[DevType][hardware],
        };
    }
);

export const makeGetDevicePrograms = () => getDevicePrograms;

export const getAgentLoading = (state) => state.farmDevices.agent.loading;

const _getAgentData = (state) => state.farmDevices.agent.data;

const getAgentData = createSelector(
    _getAgentData,
    getTestSwitchStatus,
    (data, status) => {
        let things =
            status === null
                ? [...data.clients, ...data.test]
                : status
                  ? data.test
                  : data.clients;
        let group = groupBy(things, (o) => o.ClientID);
        let tmp = [];
        for (let ClientID in group) {
            let first = group[ClientID][0];
            tmp.push({
                ClientID,
                alias: first.alias,
                agents: group[ClientID].map((row) => ({
                    arn: row.arn,
                    thingName: row.thingName,
                })).sort((a, b) => a.thingName.localeCompare(b.thingName)),
            });
        }
        tmp.sort((a, b) => (a.alias || "").localeCompare(b.alias || ""));
        return {
            ...data,
            all: [...data.clients, ...data.test],
            data: tmp,
        };
    }
);

export const getAgentDataFiltered = createSelector(
    getAgentData,
    getAlertsSearch,
    (data, search) => {
        if (!search) return data;
        return {
            ...data,
            data: data.data.filter((item) =>
                `${item.ClientID}${item.alias}${item.agents.map((agent) => agent.thingName).join()}`
                    .toLowerCase()
                    .includes(search.toLowerCase())
            ),
        };
    }
);

const getJobId = (state, props) => props.job.jobId;

const getJobExecutionLoading = (state) =>
    state.farmDevices.jobs.jobsExecutionLoading;

const getJobExecutionData = (state) => state.farmDevices.jobs.jobsExecutionData;

const _getJobExecutionData = createSelector(
    getJobId,
    getJobExecutionLoading,
    getJobExecutionData,
    (jobId, loadingData, executions) => {
        return {
            loading: loadingData[jobId],
            data: executions[jobId] || [],
        };
    }
);

export const makeGetJobExecutionData = () => _getJobExecutionData;

export const getBackendPrograms = (state) => state.farmDevices.backend.programs;

export const getBackendProgramsLoading = (state) =>
    state.farmDevices.backend.loading;

export const getJobListLoading = (state) => state.farmDevices.jobs.loading;

export const getJobList = (state) => state.farmDevices.jobs.jobs;

const getStageFromProps = (state, props) => props.stage;

export const getNextDeviceVersion = createSelector(
    getDeviceProgramsData,
    getDeviceProgramsDevType,
    getSupportedMajorAndMinorRanges,
    getStageFromProps,
    (programs, DevType, supported, stage) => {
        let highestVersion = null;
        for (let hardware in programs[DevType] || {}) {
            for (let version of programs[DevType]?.[hardware]?.[stage] || []) {
                if (!highestVersion) {
                    highestVersion = version.version;
                } else {
                    let tmp = [highestVersion, version.version];
                    tmp.sort((a, b) => b.localeCompare(a, [], {numeric: true}));
                    highestVersion = tmp[0];
                }
            }
        }
        if (highestVersion) {
            let [major, minor] = highestVersion
                .split(".")
                .map((o) => Number(o));
            const nextMajorVersion = [major + 1, supported.minor.min];
            const nextMinorVersion = [major, minor + 1];
            const checkIfSupported = ([maj, min]) => {
                if (!isBetween(maj, supported.major.min, supported.major.max))
                    return false;
                return isBetween(min, supported.minor.min, supported.minor.max);
            };
            if (!checkIfSupported(nextMajorVersion)) {
                return null;
            }
            if (!checkIfSupported(nextMinorVersion)) {
                return null;
            }
            return {
                major: nextMajorVersion.join("."),
                minor: nextMinorVersion.join("."),
            };
        }
        return null;
    }
);

export const getTakenVersions = createSelector(
    getDeviceProgramsData,
    getDeviceProgramsDevType,
    getStageFromProps,
    (programs, DevType, stage) => {
        let versions = new Set();
        for (let hardware in programs[DevType] || {}) {
            for (let version of programs[DevType]?.[hardware]?.[stage] || []) {
                versions.add(version.version);
            }
        }
        return [...versions.values()];
    }
);

export const getVersionsForProgramming = createSelector(
    getDeviceProgramsData,
    getDeviceProgramsDevType,
    (programs, DevType) => {
        let data = {};
        for (let hardware in programs[DevType]) {
            for (let stage in programs[DevType][hardware]) {
                for (let version of programs[DevType][hardware][stage]) {
                    let key = version.version + stage;
                    if (!data[key])
                        data[key] = {
                            version: version.version,
                            stage,
                            availableHardwares: [],
                            size: [],
                        };
                    data[key].availableHardwares.push(hardware);
                    data[key].size.push(version.size);
                }
            }
        }
        return Object.values(data);
    }
);

function getPlcmntsWithChildren(PlcmntID, bMap) {
    let plcmnts = [PlcmntID];
    let children = bMap.filter((item) => item.parentId === PlcmntID);
    for (let child of children) {
        plcmnts.push(...getPlcmntsWithChildren(child.id, bMap));
    }
    return uniq(plcmnts);
}

export const getDevicesByPlcmntIDWithChildren = createSelector(
    getDevices,
    getPlacementIDs,
    getBuildingsMap,
    (devices, PlcmntIDs, buildingsMap) => {
        let devicesInPlcmnts = [];
        let bMap = [...buildingsMap.values()];
        for (let {PlcmntID} of PlcmntIDs) {
            let placements = getPlcmntsWithChildren(PlcmntID, bMap);
            for (let id of placements) {
                let devs = devices.filter(
                    (item) =>
                        typeof item.PlcmntID !== "string" &&
                        (item.PlcmntID || []).find(
                            (plcmntID) => plcmntID.PlcmntID === id
                        )
                );
                devicesInPlcmnts.push(...devs);
            }
        }
        return uniqBy(devicesInPlcmnts, (o) => o.DevID);
    }
);

export const getCurrentProgramsForDevType = createSelector(
    getDevices,
    getDeviceProgramsDevType,
    (devices, DevType) => {
        console.log(devices, DevType);
        let devs = devices.filter((item) => item.DevType === DevType);
        return [
            ...devs
                .reduce((prev, device) => {
                    prev.add(device.VerSoft);
                    return prev;
                }, new Set())
                .values(),
        ];
    }
);

export const getAgentProgramList = (state) => state.farmDevices.agent.programs;

export const getScripts = (state) => state.farmDevices.scripts.list;

export const getScriptsLoading = (state) => state.farmDevices.scripts.loading;

const getBridgeData = (state, props) => props.bridgeData;

export const getAvailableAddresses = createSelector(
    getDevices,
    getBridgeData,
    (devices, bridgeData) => {
        const channel = +bridgeData.channel;
        const multis = devices.filter(
            (item) =>
                item.ParentID === bridgeData.bridge.DevID &&
                item.DevType === DevTypes.DISPENSER_NRF_MULTI &&
                item.Interface ===
                    (channel === 0
                        ? Interfaces.NRF_0
                        : channel === 1
                          ? Interfaces.NRF_2
                          : Interfaces.NRF_1)
        );
        return uniq(multis.map((multi) => multi.Address >> 0x0008)).sort(
            (a, b) => a - b
        );
    }
);

export const getDevicesByMapType = createSelector(getDevices, (devices) =>
    getDevicesMapType(devices)
);

export const getRS485Devices = createSelector(getDevices, (devices) => {
    return devices.filter((device) =>
        [Interfaces.RS485_0, Interfaces.RS485_1].includes(device.Interface)
    );
});

export const getIPSUMS = createSelector(getDevices, (devices) =>
    devices.filter(
        (item) => item.DevType === DevTypes.DISPENSER_NRF && isIPSUM(item)
    )
);

const getDevIDsFromProps = (state, props) => props.DevIDs;

export const getReportComparatorDeviceList = createSelector(
    getDevices,
    getDevIDsFromProps,
    (devices, DevIDs) => devices.filter((item) => DevIDs.includes(item.DevID))
);

const getTakenMultiAddressBridge = (_, bridge) => bridge;

export const getTakenMultiAddresses = createSelector(
    getDevices,
    getTakenMultiAddressBridge,
    (devices, bridgeID) => {
        let devicesWithMultiLikeAddress = devices.filter(
            (item) =>
                item.ParentID === bridgeID &&
                item.Address >= 0x1300 &&
                (item.Address & 0x00ff) === 0
        );
        console.log(devicesWithMultiLikeAddress);
        return devicesWithMultiLikeAddress.map((device) => ({
            address: device.Address,
            interface: device.Interface,
        }));
    }
);

/**
 * creates selector to find gateways in selected location associated with selected dev types
 * @param options {object}
 * @param options.findForDevTypes {array} - find gateways for selected dev types, empty array equals to all device types (defaults to all dev types)
 * @param options.findInChildren {boolean} - use children devices to find gateways (defaults to false)
 * @return {OutputSelector<[(function(*, *): *), (function(): []), (function(*, *))], unknown, (...args: SelectorResultArray<[(function(*, *): *), (function(): []), (function(*, *))]>) => {clearCache: () => void}, GetParamsFromSelectors<[(function(*, *): *), (function(): []), (function(*, *))]>> & {clearCache: () => void}}
 */
export const makeGetGatewayForLocationId = (options = {}) => {
    // initialize options with default values
    options.findForDevTypes ??= [];
    options.findInChildren ??= false;
    // create helper selectors
    const getAllowedDevTypes = () => options.findForDevTypes;
    const getFindInChildren = () => !!options.findInChildren;
    const getLocationId = (state, locationId) => locationId || null;
    const getBuildingsSelector = makeGetBuildingsMap();
    // we must override 2nd argument of getBuildingsSelector
    const getBuildings = (state) => getBuildingsSelector(state);
    return createSelector(
        [
            getDevices,
            getAllowedDevTypes,
            getFindInChildren,
            getLocationId,
            getBuildings,
        ],
        (
            devices,
            allowedDevTypes,
            findInChildren,
            locationId,
            buildingsMap
        ) => {
            // get all gateways
            const allGateways = devices.filter(
                (d) => d.DevType === DevTypes.GATEWAY
            );
            // return all gateways if there is no location id
            if (!locationId) return allGateways;
            // gets children ids for given location if findInChildren is set to true
            const children = (() => {
                if (!findInChildren) return [];
                const childrenSet = new Set();
                const parentToChildren = {};
                [...buildingsMap.values()].forEach(({parentId, id}) => {
                    if (parentId) {
                        if (!parentToChildren[parentId])
                            parentToChildren[parentId] = new Set();
                        parentToChildren[parentId].add(id);
                    }
                });
                const getItems = (parentId) => {
                    if (parentToChildren[parentId]) {
                        parentToChildren[parentId].forEach((newParentId) => {
                            childrenSet.add(newParentId);
                            getItems(newParentId);
                        });
                    }
                };
                getItems(locationId);
                return [...childrenSet];
            })();
            const foundGateways = {};
            let counter = 0;
            for (let device of devices) {
                // break if we already have all the available gateways
                if (allGateways.length === counter) break;
                if (
                    allowedDevTypes.length === 0 ||
                    allowedDevTypes.includes(device.DevType)
                ) {
                    if (
                        device.GatewayID &&
                        !foundGateways[device.GatewayID] &&
                        (hasPlcmntID(device, locationId) ||
                            children.some((childId) =>
                                hasPlcmntID(device, childId)
                            ))
                    ) {
                        const gateway = allGateways.find(
                            (gw) => gw.DevID === device.GatewayID
                        );
                        if (gateway) {
                            foundGateways[gateway.DevID] = gateway;
                            counter++;
                        }
                    }
                }
            }
            return Object.values(foundGateways);
        }
    );
};
export const getETHBridges = createSelector(getDevices, (devices) =>
    devices.filter(
        (item) =>
            item.DevType === DevTypes.BRIDGE && item.Settings.Type === "ETH"
    )
);

export const getIpsums = createSelector(getDevices, (devices) =>
    devices.filter(
        (item) => item.DevType === DevTypes.DISPENSER_NRF && isIPSUM(item)
    )
);

export const getDevicesWithoutDosatron = createSelector(getDevices, (devices) =>
    devices.filter((item) => item.DevType !== DevType.DOSATRON)
);

export const getGatewayResponseTimes = createSelector(
    [getDevices, getStatus],
    (devices, status) => {
        return (devices || [])
            .filter((dev) => {
                const delay = get(status.get(dev.DevID), "delay");
                return dev.DevType === DevType.GATEWAY && delay !== undefined;
            })
            .map((dev) => ({
                Name: dev.Name,
                Delay: get(status.get(dev.DevID), "delay"),
            }));
    }
);

export const getThingsWithoutEstablishedConnection = createSelector(
    [getDevices, getStatus],
    (devices, status) => {
        return devices.filter((dev) => {
            const delay = get(status.get(dev.DevID), "delay");
            return (
                [DevType.GATEWAY, DevType.ALARM_CONTROL_PANEL].includes(
                    dev.DevType
                ) &&
                !delay &&
                delay !== null
            );
        });
    }
);

export const getGatewaysWithoutConnection = createSelector(
    [getDevices, getStatus],
    (devices, status) => {
        return devices.filter((dev) => {
            const delay = get(status.get(dev.DevID), "delay");
            return dev.DevType === DevType.GATEWAY && !delay && delay !== null;
        });
    }
);
