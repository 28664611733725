import React, {useCallback} from "react";
import MenuItem from "../../../basics/menu/MenuItem";
import Menu from "../../../basics/menu/Menu";
import {downloadFile} from "../../../../api/files/downloadFile";
import {useTranslation} from "react-i18next";

export default function DesktopCalendarTaskDrawerFileMenu({
    menu,
    fileObject,
    onRemoveClick,
}) {
    const {t} = useTranslation();

    const onDownloadClick = useCallback(() => {
        downloadFile(fileObject);
    }, [fileObject]);

    return (
        <Menu event={menu}>
            <MenuItem
                icon={<i className="fa-solid fa-download fa-fw" />}
                onClick={onDownloadClick}>
                {t("newReports.headers.download")}
            </MenuItem>
            <MenuItem
                icon={<i className="fa-solid fa-trash fa-fw" />}
                onClick={onRemoveClick}>
                {t("delete")}
            </MenuItem>
        </Menu>
    );
}
