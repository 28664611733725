import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getDetailedLocationDays} from "../../../../selectors/animalDocumentsSelectors";
import {useCallback, useMemo, useRef} from "react";
import FatteningHeader from "../components/FatteningHeader";
import {getEventsForAnimalsFattening} from "../../../../utils/EventUtils";
import {getTranslationEventKey} from "../../../../utils/RaportsUtils";
import {formatLocationName} from "../../../../utils/global-formatters/formatLocationName";
import moment from "moment";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {getAnimalUnit} from "../../../../utils/SettingsUtils";
import "../../css/_fattening-grid.scss";

export default function FatteningAnimalsAmountDetailedTable() {
    const {t} = useTranslation();

    const animalUnit = useRef(getAnimalUnit());

    const {locations, detailedData} = useSelector(getDetailedLocationDays);

    const getCellClassName = useCallback((type) => {
        let className = "p-2";
        if (type) className += ` color-${type}`;
        return className;
    }, []);

    const amountFormatter = useCallback((element, property) => {
        const showZero = !!element.summary.final && property !== "weight";
        const amount = element[property].amount;
        const value =
            property === "weight"
                ? convertWeightUnitTo(amount, {
                      unit: animalUnit.current,
                      showUnit: true,
                  })
                : amount;
        if (value || showZero)
            return <strong className="opacity-75">{value || 0}</strong>;
        return "-";
    }, []);

    const {detailedHeaders, detailedElements} = useMemo(() => {
        const detailedHeaders = [
            <FatteningHeader top>{t("date")}</FatteningHeader>,
            ...getEventsForAnimalsFattening().map((code) => (
                <FatteningHeader top>
                    {t(getTranslationEventKey(code))}
                </FatteningHeader>
            )),
            <FatteningHeader summary top>
                {t("results.sum")}
            </FatteningHeader>,
            <FatteningHeader top>{t("weight")}</FatteningHeader>,
            ...Object.keys(locations).map((locationKey) => (
                <FatteningHeader top>
                    {formatLocationName(locationKey.split("_")[1])}
                </FatteningHeader>
            )),
        ];
        const detailedElements = [];
        for (const element of detailedData) {
            const isFinal = !!element.summary.final;
            for (const property in element) {
                if (property === "date") {
                    detailedElements.push(
                        <FatteningHeader>
                            {isFinal
                                ? t("summary")
                                : moment(element[property]).format(
                                      moment.HTML5_FMT.DATE
                                  )}
                        </FatteningHeader>
                    );
                } else {
                    detailedElements.push(
                        <div
                            className={getCellClassName(element[property].type)}
                            id={`cy-${property}_${element["date"]}`}>
                            {amountFormatter(element, property)}
                        </div>
                    );
                }
            }
        }
        return {detailedHeaders, detailedElements};
    }, [amountFormatter, getCellClassName, detailedData, locations, t]);

    const detailedColumns = detailedHeaders.length;

    return (
        <div
            className="grid-wrapper"
            style={{
                "grid-template-columns": `repeat(${detailedColumns}, 1fr)`,
            }}>
            {detailedHeaders}
            {detailedElements}
        </div>
    );
}
