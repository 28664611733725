import {findIndex, get, isArray} from "lodash";
import * as UserTypes from "@wesstron/utils/Api/constants/userTypes";
import {UserSortType} from "../selectors/userSelector";

export const initialValue = {
    attributes: {},
    fetching: false,
    fetched: false,
    isAuthenticated: false,
    isLogIn: false,
    isAuthenticating: false,
    error: null,
    user: {},
    employees: [],
    deletedEmps: [],
    allUsers: [],
    fetchingAllUsers: false,
    fetchedUsers: false,
    fetchingEmployees: false,
    updatingRoles: false,
    loadedData: false,
    isChangingClientID: false,
    updatingUser: false,
    employeesStatusUpdating: false,
    userSort: UserSortType.BY_LOGIN_ASC,
    userFarmFilter: "",
    userTextFilter: "",
    userTypeFilter: "",
    userLanguagesFilter: [],
    selected: null,
    serviceAccounts: [],
    supportAccounts: [],
    fetchingServiceAccounts: false,
    fetchingSupportAccounts: false,
    selectedServiceAccount: null,
    selectedSupportAccount: null,
    supportUserToAssign: null,
    fetchingClientMap: false,
    serviceClientMap: {},
    fetchingTranslators: false,
    translators: [],
    selectedTranslator: null,
    changingLocalPassword: false,
};

export default function userReducer(state = initialValue, action) {
    switch (action.type) {
        case "USER_SORT": {
            const {userSort} = action.payload;
            return {...state, userSort};
        }
        case "USER_FARM_FILTER": {
            const {farmID} = action.payload;
            return {...state, userFarmFilter: farmID};
        }
        case "USER_TEXT_FILTER": {
            const {text} = action.payload;
            return {...state, userTextFilter: text};
        }
        case "USER_TYPE_FILTER": {
            const {userType} = action.payload;
            return {...state, userTypeFilter: userType};
        }
        case "USER_LANGUAGE_FILTER": {
            const {languages} = action.payload;
            return {...state, userLanguagesFilter: languages.slice(0)};
        }
        case "SET_SELECTED_USER_INFO": {
            return {...state, selected: action.payload};
        }
        case "SET_SELECTED_TRANSLATOR_ACCOUNT": {
            return {...state, selectedTranslator: action.payload};
        }
        case "SET_SELECTED_SERVICE_ACCOUNT": {
            return {...state, selectedServiceAccount: action.payload};
        }
        case "SET_SELECTED_SUPPORT_ACCOUNT": {
            return {...state, selectedSupportAccount: action.payload};
        }
        case "SET_SUPPORT_USER_TO_ASSIGN": {
            return {...state, supportUserToAssign: action.payload};
        }
        case "RESET_REGISTRATION_ACCOUNT": {
            return {...state, selectedRegistrationOwnerAccount: null};
        }
        case "FETCH_USER":
        case "USER_PASSWORD_CHANGE_PENDING":
        case "USER_LOGIN_PENDING": {
            return {...state, fetching: true, error: null};
        }
        case "USER_SET_ERROR":
        case "USER_TEMPORARY_PASSWORD_CHANGE_FAILED": {
            return {
                ...state,
                error: action.payload,
            };
        }
        case "ENABLE_DISABLE_USER_PENDING": {
            return {...state, employeesStatusUpdating: true};
        }
        case "ENABLE_DISABLE_USER_FULFILLED":
        case "ENABLE_DISABLE_USER_REJECTED": {
            return {...state, employeesStatusUpdating: false};
        }
        case "IS_AUTHENTICATING": {
            return {...state, isAuthenticating: true};
        }
        case "GET_USER_FULFILLED": {
            return {...state, user: action.payload};
        }
        case "USER_IS_AUTHENTICATED": {
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
            };
        }
        case "GET_USER_REJECTED": {
            return {
                ...state,
                fetching: false,
                isAuthenticated: false,
                isAuthenticating: false,
                error: action.payload,
            };
        }
        case "FETCH_USER_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }
        case "FETCH_USER_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                user: action.payload,
            };
        }
        case "FETCH_ALL_USERS_PENDING": {
            return {
                ...state,
                fetchingAllUsers: true,
                fetchedUsers: false,
            };
        }
        case "FETCH_ALL_USERS_REJECTED": {
            return {
                ...state,
                fetchingAllUsers: false,
                fetchedUsers: false,
            };
        }
        case "FETCH_ALL_USERS_FULFILLED": {
            return {
                ...state,
                allUsers: (action.payload || []).slice(0),
                fetchingAllUsers: false,
                fetchedUsers: false,
            };
        }
        case "FETCH_SERVICE_ACCOUNTS_PENDING": {
            return {
                ...state,
                fetchingServiceAccounts: true,
            };
        }
        case "FETCH_SERVICE_ACCOUNTS_REJECTED": {
            return {
                ...state,
                fetchingServiceAccounts: false,
            };
        }
        case "FETCH_SERVICE_ACCOUNTS_FULFILLED": {
            const stateObject = {
                ...state,
                serviceAccounts: action.payload,
                fetchingServiceAccounts: false,
            };
            if (!!state.selectedServiceAccount) {
                const newServiceAccount = action.payload.find(
                    (o) =>
                        o.LocalUserID ===
                        state.selectedServiceAccount.LocalUserID
                );
                if (newServiceAccount)
                    stateObject.selectedServiceAccount = newServiceAccount;
            }
            return stateObject;
        }
        case "FETCH_SUPPORT_ACCOUNTS_PENDING": {
            return {
                ...state,
                fetchingSupportAccounts: true,
            };
        }
        case "FETCH_SUPPORT_ACCOUNTS_REJECTED": {
            return {
                ...state,
                fetchingSupportAccounts: false,
            };
        }
        case "FETCH_SUPPORT_ACCOUNTS_FULFILLED": {
            const stateObject = {
                ...state,
                supportAccounts: action.payload,
                fetchingSupportAccounts: false,
            };
            if (!!state.selectedSupportAccount) {
                const newSupportAccount = action.payload.find(
                    (o) =>
                        o.LocalUserID ===
                        state.selectedSupportAccount.LocalUserID
                );
                if (newSupportAccount) {
                    stateObject.selectedSupportAccount = newSupportAccount;
                }
            }
            return stateObject;
        }
        case "FETCH_TRANSLATORS_PENDING": {
            return {
                ...state,
                fetchingTranslators: true,
            };
        }
        case "FETCH_TRANSLATORS_FULFILLED": {
            const stateObject = {
                ...state,
                translators: action.payload,
                fetchingTranslators: false,
            };
            if (!!state.selectedTranslator) {
                const newTranslator = action.payload.find(
                    (t) =>
                        t.LocalUserID === state.selectedTranslator.LocalUserID
                );
                if (newTranslator)
                    stateObject.selectedTranslator = newTranslator;
            }
            return stateObject;
        }
        case "FETCH_TRANSLATORS_REJECTED": {
            return {
                ...state,
                fetchingTranslators: false,
            };
        }
        case "FETCH_SERVICE_CLIENT_MAP_PENDING": {
            return {
                ...state,
                serviceClientMap: {},
                fetchingClientMap: true,
            };
        }
        case "FETCH_SERVICE_CLIENT_MAP_FULFILLED": {
            return {
                ...state,
                serviceClientMap: action.payload,
                fetchingClientMap: false,
            };
        }
        case "RESET_SERVICE_CLIENT_MAP":
        case "FETCH_SERVICE_CLIENT_MAP_REJECTED": {
            return {
                ...state,
                serviceClientMap: {},
                fetchingClientMap: false,
            };
        }
        case "USER_CONFIRMATION_CODE_REQUEST_PENDING":
        case "USER_CONFIRMATION_ACCOUNT_PENDING":
        case "USER_FORGOTTEN_PASSWORD_REQUEST_PENDING":
        case "USER_RESET_ERROR_MESSAGE": {
            return {...state, error: undefined};
        }
        case "USER_CONFIRMATION_ACCOUNT_REJECTED":
        case "USER_CONFIRMATION_CODE_REQUEST_REJECTED": {
            let code = 2;
            if (
                action.payload &&
                action.payload.code === "InvalidParameterException"
            )
                code = 4;
            if (
                action.payload &&
                action.payload.code === "LimitExceededException"
            )
                code = 6;
            if (
                action.payload &&
                action.payload.code === "CodeMismatchException"
            )
                code = 7;
            return {...state, error: code};
        }
        case "USER_AUTH_REJECTED": {
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
                fetching: false,
                error: 1,
            };
        }
        case "USER_LOGOUT_FULFILLED": {
            return {
                ...initialValue,
                isAuthenticating: false,
            };
        }
        case "USER_LOGIN_REJECTED": {
            let code = 1;
            if (action.payload && action.payload === 11) code = 11;
            return {
                ...state,
                fetching: false,
                isAuthenticated: false,
                error: code,
            };
        }
        case "USER_LOGIN_FULFILLED": {
            return {
                ...state,
                //fetching: false,
                fetched: !!action.payload,
                //isAuthenticated: true,
            };
        }
        case "USER_GET_ATTRIBUTES_REJECTED": {
            return {
                ...state,
                error: action.payload,
                isAuthenticating: false,
                fetching: false,
            };
        }
        case "USER_GET_ATTRIBUTES_FULFILLED": {
            return {
                ...state,
                attributes: action.payload,
            };
        }
        case "CHANGE_MQTT_PASSWORD_PENDING": {
            return {...state, changingLocalPassword: true};
        }
        case "CHANGE_MQTT_PASSWORD_FULFILLED": {
            return {...state, changingLocalPassword: false};
        }
        case "CHANGE_MQTT_PASSWORD_REJECTED": {
            return {...state, changingLocalPassword: false};
        }
        case "USER_TEMPORARY_PASSWORD_CHANGE_REQUEST": {
            return {
                ...state,
                userAttributes: action.payload.userAttributes,
                requiredAttributes: action.payload.requiredAttributes,
                loggedUser: action.payload.user,
                fetching: false,
            };
        }
        case "USER_TEMPORARY_PASSWORD_CHANGE_COMPLETE": {
            return {
                ...state,
                userAttributes: null,
                requiredAttributes: null,
                loggedUser: null,
                isAuthenticated: false,
                error: null,
            };
        }
        case "USER_PASSWORD_CHANGE_REJECTED": {
            return {
                ...state,
                error: action.payload,
                fetching: false,
            };
        }
        case "USER_PASSWORD_CHANGE_FULFILLED": {
            return {
                ...state,
                fetching: false,
            };
        }
        case "USER_FORGOTTEN_PASSWORD_REQUEST_FULFILLED": {
            return {
                ...state,
                email: action.payload,
            };
        }
        case "USER_FORGOTTEN_PASSWORD_REQUEST_REJECTED": {
            let code = 3;
            if (
                action.payload &&
                action.payload.code === "CodeMismatchException"
            ) {
                code = 10;
            }
            if (
                action.payload &&
                action.payload.code === "LimitExceededException"
            ) {
                code = 6;
            }

            return {
                ...state,
                error: code,
            };
        }
        case "VERIFICATION_EMAIL_SEND":
        case "VERIFICATION_PHONE_SEND": {
            return {
                ...state,
                user: action.payload.user,
                verificationType: action.payload.type,
                verificationEmail: action.payload.email,
            };
        }
        case "VERIFY_EMAIL_OR_PHONE_REJECTED": {
            return {
                ...state,
                error: action.payload.message,
            };
        }
        case "GET_USER_EMPLOYEES_PENDING": {
            return {
                ...state,
                fetchingEmployees: true,
            };
        }
        case "GET_USER_EMPLOYEES_REJECTED": {
            return {
                ...state,
                fetchingEmployees: false,
            };
        }
        case "GET_USER_EMPLOYEES_FULFILLED": {
            const employees = get(action, "payload.res", []).filter(
                (e) =>
                    !e.DtaDelTime &&
                    [
                        UserTypes.OWNER,
                        UserTypes.USER,
                        UserTypes.MANAGER,
                        UserTypes.TRANSLATOR,
                        UserTypes.SUPPORT_FAKE,
                        UserTypes.TV,
                        UserTypes.SERVICE,
                    ].includes(e.userType)
            );
            const deletedEmps = get(action, "payload.res", []).filter(
                (e) =>
                    e.DtaDelTime &&
                    [
                        UserTypes.OWNER,
                        UserTypes.USER,
                        UserTypes.MANAGER,
                        UserTypes.TRANSLATOR,
                        UserTypes.SUPPORT_FAKE,
                        UserTypes.TV,
                        UserTypes.SERVICE,
                    ].includes(e.userType)
            );
            const stateObject = {
                ...state,
                fetchingEmployees: false,
                employees,
                deletedEmps,
            };
            if (!!state.selected) {
                const selectedToChange = employees.find(
                    (e) => e.LocalUserID === state.selected.LocalUserID
                );
                if (selectedToChange) stateObject.selected = selectedToChange;
            }
            return stateObject;
        }
        case "USER_NEED_TO_ACCEPT_EULA": {
            return {
                ...state,
                fetching: false,
                isAuthenticating: false,
            };
        }
        case "UPDATE_ROLES_REJECTED":
        case "UPDATE_ROLES_FULFILLED": {
            return {
                ...state,
                updatingRoles: false,
            };
        }
        case "UPDATE_ROLES_PENDING": {
            return {
                ...state,
                updatingRoles: true,
            };
        }
        case "UPDATE_USER_REJECTED":
        case "UPDATE_USER_FULFILLED": {
            return {
                ...state,
                updatingUser: false,
            };
        }
        case "UPDATE_USER_PENDING": {
            return {
                ...state,
                updatingUser: true,
            };
        }
        case "LOADED_DATA": {
            return {
                ...state,
                loadedData: true,
            };
        }
        case "CHANGE_CLIENT": {
            return {
                ...state,
                user: {
                    ...state.user,
                    ClientID: action.payload,
                },
                employees: [],
                deletedEmps: [],
            };
        }
        case "CHANGE_CLIENT_PENDING": {
            return {
                ...state,
                isChangingClientID: true,
            };
        }
        case "CHANGE_CLIENT_REJECTED":
        case "REFRESH_TOKEN_REJECTED": {
            return {
                ...state,
                isChangingClientID: false,
            };
        }
        case "REFRESH_TOKEN_FULFILLED": {
            return {
                ...state,
                isChangingClientID: false,
                // user: {
                //     ...state.user,
                //     ClientID: action.meta.ClientID
                // }
            };
        }

        case "USER_MAIL_VERIFY": {
            return {
                ...state,
                user: {...state.user, ClientID: action.meta.ClientID},
            };
        }

        case "USER_CHANGE_MQTT_PASSWORD": {
            return {
                ...state,
                user: {
                    ...state.user,
                    LocalMqttSecret: action.payload,
                },
            };
        }

        case "UPDATE_DOC_READ": {
            let doc = state.user.DocRead || [];
            return {
                ...state,
                user: {
                    ...state.user,
                    DocRead: [
                        ...doc,
                        ...(isArray(action.payload)
                            ? action.payload
                            : [action.payload]),
                    ],
                },
            };
        }
        case "UPDATE_LIC_PACKAGE": {
            const {
                user: {LicPackages},
            } = state;
            const copy = LicPackages.slice(0);
            let index = findIndex(
                copy,
                (o) => o.SubID === action.payload.SubID
            );
            copy[index] = action.payload;
            console.log(copy);
            return {
                ...state,
                user: {
                    ...state.user,
                    LicPackages: copy,
                },
            };
        }
        case "CHANGE_USER_SECRET":
            return {
                ...state,
                user: {
                    ...state.user,
                    LocalStorageSecret: action.payload,
                },
            };
        case "REMOVE_USER_FROM_REDUCER":
            return {
                ...state,
                employees: get(state, "employees", []).filter(
                    (e) => e.LocalUserID !== action.payload
                ),
            };
        default:
            return state;
    }
}
