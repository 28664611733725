import React, {useCallback} from "react";
import useThumbnail from "../../../../hooks/useThumbnail";
import "./_input-actions-wrapper-file-item.scss";
import Tooltip from "../../tooltip/Tooltip";
import {getFontAwesomeIconForType} from "../../../../utils/FileUtils";
import {isMobile} from "../../../../utils/MobileUtils";

export function InputActionsWrapperFileItemRenderer({
    onClick,
    thumbnail,
    name,
    type,
    showRemove = true,
    showArrow,
    onContextMenu,
    onRemoveClick,
}) {
    let split = name.split(".");
    let extension = split[split.length - 1];

    const _onRemoveClick = useCallback(
        (e) => {
            e.stopPropagation();
            onRemoveClick();
        },
        [onRemoveClick]
    );
    return (
        <div
            className="input-actions-wrapper-file-item"
            onClick={onClick}
            onContextMenu={onContextMenu}>
            {thumbnail && (
                <div className="input-actions-wrapper-file-item-image">
                    <img src={thumbnail} alt={name} />
                </div>
            )}
            {!thumbnail && (
                <div className="input-actions-wrapper-file-item-name">
                    <div>
                        <i
                            className={`fa-solid ${getFontAwesomeIconForType(type)}`}
                        />
                    </div>
                    <div>
                        <div>{name}</div>
                        <small>{extension}</small>
                    </div>
                </div>
            )}
            {showRemove &&
                (isMobile() ? (
                    <div
                        className="input-actions-wrapper-file-item-remove-mobile"
                        data-testid={"remove"}
                        onClick={_onRemoveClick}>
                        <i className="fa-solid fa-xmark" />
                    </div>
                ) : (
                    <div
                        className="input-actions-wrapper-file-item-remove"
                        data-testid={"remove"}>
                        <i className="fa-solid fa-trash" />
                    </div>
                ))}
            {showArrow && (
                <div
                    className="input-actions-wrapper-file-item-arrow"
                    data-testid={"arrow"}>
                    <i className="fa-solid fa-chevron-down" />
                </div>
            )}
        </div>
    );
}

export default function InputActionsWrapperFileItem({
    file,
    onClick: onClickProps,
}) {
    const thumbnail = useThumbnail(file);

    const onClick = useCallback(() => {
        onClickProps(file);
    }, [onClickProps, file]);

    return (
        <Tooltip tooltipContent={file.name}>
            <InputActionsWrapperFileItemRenderer
                name={file.name}
                onClick={onClick}
                type={file.type}
                thumbnail={thumbnail}
            />
        </Tooltip>
    );
}
