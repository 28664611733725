import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {isFunction, isNil} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {Fade} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {myID} from "../../../libs/generateID";
import {getColorByName} from "../../../utils/ColorUtils";
import {getRemToPx} from "../../../utils/DOMUtils";
import {
    generateArc,
    getAngle,
    getColorByPercent,
    getColors,
    getCoordsOnArc,
} from "../../../utils/GaugeUtils";
import Checkbox from "../checkbox/Checkbox";
import "./_gauge.scss";
import {getNormalizedDistance, isFiniteNumber} from "../../../utils/MathUtils";

class Gauge extends React.Component {
    constructor(props) {
        super(props);
        this.fontSize = Math.round(getRemToPx(0.05) * 0.8);
    }

    onGaugeClick = () => {
        const {onClick} = this.props;
        if (isFunction(onClick)) onClick();
    };

    getClassName() {
        const {isSelected, onClick, editMode, show, value} = this.props;
        let className = "gauge-item mt-3";
        if (isSelected) className += " selected";
        if (isFunction(onClick)) className += " pointer";
        else className += " cursor-default";
        if (editMode) {
            className += " edit-mode";
            if (!show) className += " opacity-50";
        }
        if (isNil(value) && !className.includes("opacity"))
            className += " opacity-50";
        return className;
    }

    normalizePercent() {
        const {percentCalculation, percent} = this.props;
        if (percentCalculation === "internal") {
            return this.calculatePercent();
        }
        if (percent === null || percent === undefined || isNaN(percent))
            return 0;
        if (percent > 100) return 100;
        if (percent < 0) return 0;
        return percent;
    }

    /**
     * liczy procenty wewnątrz, bo te przekazane czasami są błędne?
     * #10546 - komentarz 1. obrazuje to ciekawe zjawisko
     * @return {number}
     */
    calculatePercent() {
        const {value, min, max} = this.props;
        if (
            !isFiniteNumber(value) ||
            !isFiniteNumber(min) ||
            !isFiniteNumber(max)
        )
            return 0;
        return getNormalizedDistance(value, min, max) * 100;
    }
    roundValue(val) {
        return typeof val === "number" ? Math.round(val * 100) / 100 : val;
    }
    getGauge = () => {
        const {
            value,
            label,
            showLine,
            valueFormatter,
            reverse,
            // percent: percentValue,
            showLegend,
            editMode,
            show,
            sideToSide,
        } = this.props;
        const id = myID();
        const colors = getColors(reverse, sideToSide, isNil(value));
        const percent = this.normalizePercent() / 100;
        // const angle = getAngle(percent);
        const angle = getAngle(percent);
        const filledArc = generateArc(-Math.PI / 2, angle);
        const color = getColorByPercent(
            colors,
            sideToSide ? this.props.percent : percent,
            sideToSide
        );
        const markerLocation = getCoordsOnArc(angle, 1 - (1 - 0.65) / 2);
        const legend = showLegend ? this.getLegendContent() : null;

        if (!editMode && !show) return null;
        // TODO - w wolnym czasie przerobic na canonical gradient bo linear gradient średnio pasuje
        return (
            <div className={this.getClassName()} onClick={this.onGaugeClick}>
                <Fade in={editMode} unmountOnExit>
                    <div className="gauge-item-icon-container-checkbox">
                        <Checkbox
                            label={""}
                            checked={show}
                            onChange={this.onGaugeClick}
                        />
                    </div>
                </Fade>
                {
                    <svg viewBox="-1 -1 2 1">
                        <defs>
                            {sideToSide && (
                                <radialGradient
                                    id={`Gauge__gradient__${id}`}
                                    cx="50%"
                                    cy="0%"
                                    r="100%">
                                    <stop
                                        offset="50%"
                                        style={{
                                            stopColor:
                                                getColorByName("success"),
                                            stopOpacity: 1,
                                        }}
                                    />
                                    <stop
                                        offset="90%"
                                        style={{
                                            stopColor:
                                                getColorByName("warning"),
                                            stopOpacity: 1,
                                        }}
                                    />
                                </radialGradient>
                            )}
                            {!sideToSide && (
                                <linearGradient
                                    key={myID()}
                                    id={`Gauge__gradient__${id}`}
                                    gradientUnits="userSpaceOnUse"
                                    x1="-1"
                                    x2="1"
                                    y2="0">
                                    {colors.map((color, index) => {
                                        const offset = `${index / (colors.length - 1)}`;
                                        return (
                                            <stop
                                                key={`${color}_${index}`}
                                                stopColor={color}
                                                offset={offset}
                                            />
                                        );
                                    })}
                                </linearGradient>
                            )}
                        </defs>
                        <path
                            className="gauge-background"
                            d="M-0.8062257748298549,-8.326672684688674e-17A0.175,0.175,0,0,1,-0.9772433634301272,-0.2121212121212122A1,1,0,0,1,0.9772433634301272,-0.21212121212121218A0.175,0.175,0,0,1,0.8062257748298549,-2.7755575615628914e-17L0.8062257748298549,-2.7755575615628914e-17A0.175,0.175,0,0,1,0.6352081862295826,-0.13787878787878788A0.65,0.65,0,0,0,-0.6352081862295826,-0.13787878787878793A0.175,0.175,0,0,1,-0.8062257748298549,-8.326672684688674e-17Z"
                        />
                        <path
                            d={filledArc}
                            fill={`url(#Gauge__gradient__${id})`}
                        />
                        {showLine && (
                            <line
                                y1="-1"
                                y2="-0.65"
                                stroke="white"
                                strokeWidth="0.027"
                            />
                        )}
                        <circle
                            cx={markerLocation[0]}
                            cy={markerLocation[1]}
                            r="0.2"
                            stroke="#ffffff"
                            strokeWidth="0.01"
                            fill={color}
                        />
                        <path
                            className="gauge-background"
                            transform={`rotate(${angle * (180 / Math.PI)}) translate(-0.2, -0.33)`}
                            d="M0.136364 0.0290102C0.158279 -0.0096701 0.219156 -0.00967009 0.241071 0.0290102C0.297078 0.120023 0.375 0.263367 0.375 0.324801C0.375 0.422639 0.292208 0.5 0.1875 0.5C0.0852272 0.5 -1.8346e-08 0.422639 -9.79274e-09 0.324801C0.00243506 0.263367 0.0803571 0.120023 0.136364 0.0290102ZM0.1875 0.381684C0.221591 0.381684 0.248377 0.356655 0.248377 0.324801C0.248377 0.292947 0.221591 0.267918 0.1875 0.267918C0.153409 0.267918 0.126623 0.292947 0.126623 0.324801C0.126623 0.356655 0.155844 0.381684 0.1875 0.381684Z"
                        />
                        {showLegend && (
                            <>
                                <text
                                    className={"visible"}
                                    dx="-1.1"
                                    dy="0"
                                    fontSize={0.27}
                                    textAnchor="end">
                                    {this.roundValue(legend[0]) ?? "-"}
                                </text>
                                <text
                                    className={"visible"}
                                    dx="1.1"
                                    dy="0"
                                    fontSize={0.27}
                                    textAnchor="start">
                                    {this.roundValue(legend[1]) ?? "-"}
                                </text>
                            </>
                        )}
                    </svg>
                }
                <div className="gauge-text">
                    {isNil(value) && "-"}
                    {!isNil(value) &&
                        isFunction(valueFormatter) &&
                        valueFormatter(value)}
                    {!isNil(value) &&
                        !isFunction(valueFormatter) &&
                        parseFloat(`${value}`).toFixed(2)}
                </div>
                {!!label && <div className="gauge-label">{label}</div>}
            </div>
        );
    };

    getLegendContent = () => {
        const {min, max, valueFormatter} = this.props;
        return [
            valueFormatter ? valueFormatter(min) : min,
            valueFormatter ? valueFormatter(max) : max,
        ];
    };

    render() {
        return this.getGauge();
    }
}

Gauge.defaultProps = {
    label: "",
    max: 3,
    showLine: false,
    reverse: false,
    editMode: false,
    show: true,
    sideToSide: false,
    percentCalculation: "external", // todo: zamienić kiedyś na "internal"
};

Gauge.propTypes = {
    label: PropTypes.string,
    reverse: PropTypes.bool,
    showLine: PropTypes.bool,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    showLegend: PropTypes.bool,
    editMode: PropTypes.bool,
    show: PropTypes.bool,
    sideToSide: PropTypes.bool,
    percentCalculation: PropTypes.oneOf(["external", "internal"]),
};

export default compose(withTranslation(NameSpaces.TRANSLATION))(Gauge);
