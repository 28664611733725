import {
    flatten,
    get,
    groupBy,
    isNil,
    isObject,
    isString,
    memoize,
} from "lodash";
import memoizeOne from "memoize-one";
import {ClimateWorkType} from "../../constans/devices";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import {Level} from "../../constans/levelTypes";
import i18n from "../../i18n";
import {getAlias} from "../../utils/DevicesUtils";
import {
    CustomEntityTypes,
    getAnimalSizeClassName,
    getAnimalSizeNameBySize,
    getDeviceEntityType,
    getEntityFillByType,
} from "../../utils/FarmMapUtils";
import {
    getClosestValueByMinMaxAndStep,
    isFiniteNumber,
} from "../../utils/MathUtils";
import {getFeedingLevels} from "../../utils/SettingsUtils";
import AnimationManager from "./animationManager";
import {Defaults} from "./components/drawer-content/utils";
import {
    parseCage,
    parseClimate,
    parseElectric,
    parseIpsum,
    parseNutriPro,
    parseNutriProV2,
    parseRadar,
    parseRelay,
    parseSilo,
    parseVehicleWeight,
    parseWater,
} from "./components/parsers";
import {isIPSUM, isNutriPro, isNutriProV2} from "../../utils/DispenserNRFUtils";
import {ReactLocalStorage} from "../../utils/ReactLocalStorage";
import GlobalConfig from "./components/drawer-content/GlobalConfig";

const {SHOW_OUTDATED_AS_WARNING} = GlobalConfig;

// true => show errors ONLY of SELECTED DEVICE
// false => show errors LINKED to SELECTED DEVICE LOCATION and its' CHILDREN LOCATION
// according to #10076 this should be set to true
// according to #7495 this should be set to false
const SUPPRESS_CHAMBER_ERRORS = ReactLocalStorage.get(
    "farmMapSuppressChamberErrors",
    true
);

const dispenserTypes = [DevTypes.DISPENSER_NRF, DevTypes.DISPENSER];

const fixClassName = memoize(
    (classNameArray) => {
        const filteredArray = flatten(classNameArray.filter((o) => !!o));
        // if we pass text with "map-" as second argument we override default one
        if (filteredArray[1]) {
            if (filteredArray[1].includes("map-")) {
                return filteredArray.slice(1).join(" ");
            }
        }
        return filteredArray.join(" ");
    },
    (...args) => JSON.stringify(args[0])
);

const appendPrefix = (value) =>
    isNil(value) || value === "" ? "" : `-${value}`;

export const DeviceTypesUsedInMap = [
    DevTypes.WIRELESS_WATER_FLOW_METER,
    DevTypes.TEMP_SENSOR,
    DevTypes.VEHICLE_WEIGHT_R320,
    DevTypes.DISPENSER,
    DevTypes.DISPENSER_NRF,
    DevTypes.CLIMATE_SK3,
    DevTypes.CLIMATE_SK4,
    DevTypes.CLIMATE,
    DevTypes.SCALE,
    DevTypes.ELECTRICITY_FLOW_METER,
    DevTypes.ELECTRICITY_FLOW_METER_MODBUS,
    DevTypes.WATER_FLOW_METER,
    DevTypes.CAGE,
    DevTypes.CAGE_2WAY,
    DevTypes.SILO_RADAR,
    DevTypes.MODBUS_RELAY,
    DevTypes.SILO_SENSOR,
];

const animationManager = new AnimationManager();

/**
 * function used to merge n objects which prefers not null values and not empty strings
 * @param objects
 * @return {{}}
 */
const mergeObjects = (...objects) => {
    const result = {};
    const isEmptyValue = (value) => isNil(value) || value === "";
    for (let obj of objects) {
        if (!obj) continue;
        for (let key in obj) {
            if (isEmptyValue(result[key]) || !isEmptyValue(obj[key])) {
                result[key] = obj[key];
            }
        }
    }
    return result;
};

const withDefaults = function (fn) {
    return function () {
        const result = fn.apply(this, arguments);
        const params = {};
        switch (arguments[0].object.type) {
            case "buildings": {
                params.className = ["map-building", result.className];
                break;
            }
            case "sectors": {
                params.className = ["map-sector", result.className];
                break;
            }
            case "chambers": {
                params.className = ["map-chamber", result.className];
                break;
            }
            case "devices": {
                params.className = ["map-device", result.className];
                break;
            }
            case "standings": {
                params.className = ["map-standing", result.className];
                break;
            }
            case "groups": {
                params.className = ["map-standing", result.className];
                break;
            }
            case "animals": {
                params.className = ["map-animal", result.className];
                break;
            }
            default: {
                params.className = [];
                break;
            }
        }
        if (result.disabled) {
            params.className.push("disabled");
        }
        if (result.noPointerEvents) {
            result.disabled = true;
            params.className.push("no-pointer-events");
        }
        if (result.text === undefined) {
            if (
                arguments[0].object.type !== "devices" &&
                arguments[0].object.location
            ) {
                result.text = arguments[0].object.location.name;
            }
        }
        result.className = fixClassName(params.className);
        return result;
    };
};

const _getDevicesData = memoizeOne((localChamberDevices) => {
    const devices = groupBy(
        localChamberDevices || [],
        ({device}) => device.DevType
    );
    return memoize(
        (...allowedDeviceTypes) => {
            const data = [];
            allowedDeviceTypes.forEach((deviceType) => {
                if (!devices[deviceType]) {
                    return;
                }
                devices[deviceType].forEach(({shadow}) => {
                    if (Array.isArray(shadow)) {
                        data.push(...shadow);
                    } else {
                        data.push(shadow);
                    }
                });
            });
            return data;
        },
        (...args) => args.join("_")
    );
});

const getClimateData = (devices) => {
    const data = _getDevicesData(devices)(
        DevTypes.CLIMATE,
        DevTypes.CLIMATE_SK3,
        DevTypes.CLIMATE_SK4,
        DevTypes.TEMP_SENSOR
    );
    const {text, isLive, isAlert, workType, isWarning} = parseClimate(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            className = "warning";
        } else if (isLive) {
            className = "success";
            if (workType === ClimateWorkType.INACTIVE) {
                className = "inactive";
            } else if (workType === ClimateWorkType.MANUAL) {
                className = "info";
            }
        }
    }
    return {
        text,
        className,
    };
};

const emptyObj = {};

const getDevicesData = (
    devices,
    {relay, climate, cage, ipsum, water, electricity, vehicleWeight} = {}
) => {
    const deviceType = get(devices, "[0].device.DevType");
    const entityType = getDeviceEntityType(devices?.[0]?.device);
    switch (entityType) {
        case CustomEntityTypes.CLIMATE: {
            const {show = false} = climate || emptyObj;
            if (!show) return {show: false};
            return getClimateData(devices);
        }
        case CustomEntityTypes.ELECTRICITY: {
            const {show = false} = electricity || emptyObj;
            if (!show) return {show: false};
            return getElectricData(devices);
        }
        case CustomEntityTypes.WATER: {
            const {show = false} = water || emptyObj;
            if (!show) return {show: false};
            return getWaterData(devices);
        }
        case CustomEntityTypes.SILO: {
            return deviceType === DevTypes.SILO_RADAR
                ? getSiloRadarData(devices)
                : getScaleData(devices);
        }
        case CustomEntityTypes.LIGHT: {
            const {
                show = false,
                overrideLabel,
                disable = true,
                small = false,
            } = relay || emptyObj;
            if (!show) return {show: false};
            return getRelayDataAsLightDevice(
                devices,
                disable,
                overrideLabel,
                small
            );
        }
        case CustomEntityTypes.CAGE_2WAY: {
            const {show = false} = cage || emptyObj;
            if (!show) return {show};
            return getCageData(devices);
        }
        case CustomEntityTypes.IPSUM: {
            const {show = false} = ipsum || emptyObj;
            if (!show) return {show};
            return getIpsumData(devices);
        }
        case CustomEntityTypes.NUTRI_PRO: {
            const {show = false} = ipsum || emptyObj;
            if (!show) return {show};
            return getNutriProData(devices);
        }
        case CustomEntityTypes.NUTRI_PRO_V2: {
            const {show = false} = ipsum || emptyObj;
            if (!show) return {show};
            return getNutriProV2Data(devices);
        }
        case CustomEntityTypes.VEHICLE_WEIGHT: {
            const {show = false} = vehicleWeight || emptyObj;
            if (!show) return {show};
            return getVehicleWeightData(devices);
        }
        default:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                show: false,
            };
    }
};

const getCageData = (devices) => {
    const data = _getDevicesData(devices)(DevTypes.CAGE_2WAY, DevTypes.CAGE);
    const type = getDeviceEntityType(devices[0]) ?? CustomEntityTypes.CAGE_2WAY;
    const {isLive, isAlert, isWarning, cageState} = parseCage(data);
    let fill = `url(#ue-${type}`;
    if (data.length) {
        if (isAlert) {
            fill += "-error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            fill += "-warning";
        }
    }
    if (
        cageState.length === 3 &&
        cageState.every((value, i) => {
            switch (i) {
                case 0:
                case 1:
                    return ["open", "closed"].includes(value);
                case 2:
                    return ["left", "middle", "right"].includes(value);
                default:
                    return false;
            }
        })
    ) {
        fill += `-enter-${{open: 1, closed: 0}[cageState[0]]}`;
        fill += `-mid-${{open: 1, closed: 0}[cageState[1]]}`;
        fill += `-exit-${cageState[2]}`;
    }

    fill += ")";
    return {
        text: "",
        fill,
        className: "map-device-detailed",
    };
};

const getIpsumData = (devices) => {
    return getIpsumLikeData(devices, isIPSUM, parseIpsum);
};

const getNutriProData = (devices) => {
    return getIpsumLikeData(devices, isNutriPro, parseNutriPro);
};

const getNutriProV2Data = (devices) => {
    return getIpsumLikeData(devices, isNutriProV2, parseNutriProV2);
};
const getIpsumLikeData = (devices, checkIfDesiredDevice, parser) => {
    const data = [];
    for (let {device, shadow} of devices) {
        if ([DevTypes.DISPENSER_NRF].includes(device.DevType)) {
            if (checkIfDesiredDevice(device)) {
                data.push(...(Array.isArray(shadow) ? shadow : [shadow]));
            }
        }
    }
    const {isLive, isAlert, isWarning} = parser(data);
    const device = devices[0]?.device;
    let fill = `url(#ue-${getEntityFillByType(getDeviceEntityType(device))}`;
    if (data.length) {
        if (isAlert) {
            fill += "-error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            fill += "-warning";
        }
    }
    fill += ")";
    return {
        text: "",
        fill,
        className: "map-device-detailed",
    };
};

const getScaleData = (devices) => {
    const data = _getDevicesData(devices)(DevTypes.SCALE, DevTypes.SILO_SENSOR);
    const {text, isLive, isAlert, isWarning} = parseSilo(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            className = "warning";
        } else if (isLive) {
            className = "success";
        }
    }
    return {
        text,
        className,
    };
};

const getVehicleWeightData = (devices) => {
    const data = _getDevicesData(devices)(DevTypes.VEHICLE_WEIGHT_R320);
    const {text, isLive, isAlert, isWarning} = parseVehicleWeight(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            className = "warning";
        } else if (isLive) {
            className = "success";
        }
    }
    return {
        text,
        className,
    };
};

const getRelayDataAsChamber = (devices) => {
    const data = _getDevicesData(devices)(DevTypes.MODBUS_RELAY);
    let {text, isLive, isAlert} = parseRelay(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if (SHOW_OUTDATED_AS_WARNING && !isLive) {
            className = "warning";
        } else if (isLive) {
            className = "success";
        }
    }
    return {
        text,
        className,
    };
};

const getRelayDataAsLightDevice = (
    devices,
    disablePointerRelay,
    overrideLabel,
    isSmallLight = false
) => {
    const data = _getDevicesData(devices)(DevTypes.MODBUS_RELAY);
    let {text, isLive, isAlert} = parseRelay(data);
    let className = "map-light";
    if (data.length) {
        if (isAlert) {
            className += " error";
        } else if (!isLive && SHOW_OUTDATED_AS_WARNING) {
            className += " warning";
        } else if (isLive) {
            className += " success";
        }
    }
    if (text.includes("/")) {
        const [count, maxCount] = text.split("/");
        if (count !== "0" && count === maxCount) {
            className += " map-light--on";
            text = [{icon: "fas fa-lightbulb-on", text: ""}];
        } else {
            className += " map-light--off";
            text = [{icon: "fas fa-lightbulb", text: ""}];
        }
        if (isSmallLight) {
            className += " map-light--small";
        }
    }
    if (devices.length === 1) {
        text = getAlias(devices[0].device, devices[0].Index[0]);
    }
    if (overrideLabel !== undefined) {
        text = overrideLabel;
    }
    return {
        text,
        className,
        noPointerEvents: !!disablePointerRelay,
    };
};

const getSiloRadarData = (devices) => {
    const data = _getDevicesData(devices)(DevTypes.SILO_RADAR);
    const {text, isLive, isAlert, isWarning} = parseRadar(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            className = "warning";
        } else if (isLive) {
            className = "success";
        }
    }
    return {
        text,
        className,
    };
};

const getElectricData = (devices) => {
    const data = _getDevicesData(devices)(
        DevTypes.ELECTRICITY_FLOW_METER,
        DevTypes.ELECTRICITY_FLOW_METER_MODBUS
    );
    const {text, isLive, isAlert, isWarning} = parseElectric(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            className = "warning";
        } else if (isLive) {
            className = "success";
        }
    }
    return {
        text,
        className,
    };
};

const getWaterData = (devices) => {
    const data = _getDevicesData(devices)(
        DevTypes.WATER_FLOW_METER,
        DevTypes.WIRELESS_WATER_FLOW_METER
    );
    const {text, isLive, isAlert, isWarning} = parseWater(data);
    let className = "";
    if (data.length) {
        if (isAlert) {
            className = "error";
        } else if ((SHOW_OUTDATED_AS_WARNING && !isLive) || isWarning) {
            className = "warning";
        } else if (isLive) {
            className = "success";
        }
    }
    return {
        text,
        className,
    };
};

const getErrorClass = (object = {}, alerts = {}, showSmalls) => {
    const showingChildren = showSmalls && object.hideOnSmallItems;
    const {errors, warnings, isWarningInChildren, isErrorInChildren} = alerts;
    const showError =
        !errors.isEmpty() || (showingChildren ? false : !!isErrorInChildren);
    const showWarn = showError
        ? false
        : !warnings.isEmpty() ||
          (showingChildren ? false : !!isWarningInChildren);
    let className = showError ? "error" : showWarn ? "warning" : "";
    if (className && showingChildren) {
        className += "-children";
    }
    return className;
};

export const onBeforeRenderElectrics = withDefaults(
    ({object, devices, alerts}) => {
        switch (object.type) {
            case "buildings": {
                return {
                    show: false,
                };
            }
            case "sectors": {
                return {
                    show: false,
                };
            }
            case "chambers": {
                return {
                    show: true,
                    ...mergeObjects(
                        getElectricData(devices),
                        !SUPPRESS_CHAMBER_ERRORS
                            ? {className: getErrorClass(object, alerts, false)}
                            : null
                    ),
                };
            }
            case "devices": {
                return {
                    show: true,
                    ...getDevicesData(devices, {electricity: {show: true}}),
                };
            }
            case "standings": {
                return {
                    show: false,
                };
            }
            default: {
                return {
                    show: false,
                };
            }
        }
    }
);

export const onBeforeRenderClimates = withDefaults(
    ({object, devices, alerts}) => {
        switch (object.type) {
            case "buildings": {
                return {
                    show: false,
                };
            }
            case "sectors": {
                return {
                    show: false,
                };
            }
            case "chambers": {
                return {
                    show: true,
                    ...mergeObjects(
                        getClimateData(devices),
                        !SUPPRESS_CHAMBER_ERRORS
                            ? {className: getErrorClass(object, alerts, false)}
                            : null
                    ),
                };
            }
            case "devices": {
                return {
                    show: true,
                    ...getDevicesData(devices, {climate: {show: true}}),
                };
            }
            case "standings": {
                return {
                    show: false,
                };
            }
            default: {
                return {
                    show: false,
                };
            }
        }
    }
);

export const onBeforeRenderLights = withDefaults(
    ({object, devices, alerts}) => {
        switch (object.type) {
            case "buildings": {
                return {
                    show: false,
                };
            }
            case "sectors": {
                return {
                    show: false,
                };
            }
            case "chambers": {
                return {
                    show: true,
                    noPointerEvents: true,
                    text: " ",
                };
            }
            case "devices": {
                return {
                    show: true,
                    ...getDevicesData(devices, {
                        relay: {show: true, disable: false},
                    }),
                };
            }
            case "standings": {
                return {
                    show: false,
                };
            }
            default: {
                return {
                    show: false,
                };
            }
        }
    }
);

export const onBeforeRenderWater = withDefaults(({object, devices, alerts}) => {
    switch (object.type) {
        case "buildings": {
            return {
                show: false,
            };
        }
        case "sectors": {
            return {
                show: false,
            };
        }
        case "chambers": {
            return {
                show: true,
                ...mergeObjects(
                    getWaterData(devices),
                    !SUPPRESS_CHAMBER_ERRORS
                        ? {className: getErrorClass(object, alerts, false)}
                        : null
                ),
            };
        }
        case "devices": {
            return {
                show: true,
                ...getDevicesData(devices, {water: {show: true}}),
            };
        }
        case "standings": {
            return {
                show: false,
            };
        }
        default: {
            return {
                show: false,
            };
        }
    }
});

const getT = memoizeOne((language = i18n.language) => {
    return (key, options) => {
        return i18n.t(key, options);
    };
});

const onBeforeRenderChambers = withDefaults(
    ({object, devices, animals, alerts, showSmalls}, useData = true) => {
        switch (object.type) {
            case "buildings": {
                return {
                    show: false,
                };
            }
            case "sectors": {
                return {
                    show: false,
                };
            }
            case "chambers": {
                const hide = showSmalls && object.hideOnSmallItems;
                const className = useData
                    ? getErrorClass(object, alerts, showSmalls)
                    : "";
                return {
                    show: true,
                    className: className,
                    text: hide ? "" : object.location.name,
                };
            }
            case "devices": {
                if (!useData)
                    return {
                        show: false,
                    };
                const optRelay = {};
                if (object.location?.level === Level.BOX) {
                    optRelay.small = true;
                }
                switch (object._displayMode) {
                    case "visible": {
                        optRelay.show = true;
                        optRelay.disable = false;
                        break;
                    }
                    case "visible_on_zoom": {
                        optRelay.show = showSmalls;
                        optRelay.disable = true;
                        break;
                    }
                    case "partially_visible_on_zoom": {
                        optRelay.show = showSmalls;
                        optRelay.disable = true;
                        optRelay.overrideLabel = "";
                        break;
                    }
                    default:
                        break;
                }
                return {
                    show: true,
                    ...getDevicesData(devices, {
                        vehicleWeight: {show: true},
                        relay: optRelay,
                        ipsum: {show: showSmalls},
                        cage: {show: showSmalls},
                    }),
                };
            }
            case "standings": {
                const index = devices.findIndex(({device}) =>
                    dispenserTypes.includes(device.DevType)
                );
                const dispenserData = index !== -1 ? devices[index] : null;
                const percentage = dispenserData?.shadow?.Percentage;
                const errorClass = useData
                    ? getErrorClass(object, alerts, false)
                    : "";
                let feedState = "";
                if (useData) {
                    if (!errorClass && !isNil(percentage)) {
                        const {FeedingMinimumLevel, FeedingMaximumLevel} =
                            getFeedingLevels();
                        const minimumLevel = 100 * FeedingMinimumLevel;
                        const maximumLevel = 100 * FeedingMaximumLevel;
                        if (percentage <= minimumLevel) {
                            feedState = "-feed-0";
                        } else if (percentage < maximumLevel) {
                            feedState = "-feed-1";
                        } else if (percentage < 100) {
                            feedState = "-feed-2";
                        } else {
                            feedState = "-feed-3";
                        }
                    }
                }

                // const animalSize = animals.length ? getAnimalSizeClassName(object.animals[0]) : "";
                return {
                    show: true,
                    led: useData ? dispenserData?.shadow?.LED || null : null,
                    className:
                        object.standingType === "standing" ? "with-boxes" : "",
                    text: `${animals[0]?.AnmNo1 || (isString(object.location.name) ? object.location.name.split(" ").pop() : object.location.name)}`.padStart(
                        5,
                        " "
                    ),
                    fill: `url(#ue-${object.standingType}-${object.facing}${animals.length ? `-animal` : ""}${dispenserTypes.includes(dispenserData?.device?.DevTypes) ? "-device" : ""}${errorClass ? `-${errorClass}` : feedState})`,
                };
            }
            case "groups": {
                const {AnimalID, _type} = devices[0]?.shadow || {};
                const errorClass = useData
                    ? getErrorClass(object, alerts, false)
                    : "";
                const animal =
                    _type === "GROUP" && AnimalID
                        ? animals.find(({AnmID}) => AnmID === AnimalID)
                        : null;
                return {
                    show: true,
                    text: animal ? animal.AnmNo1 : " ", //devices[0] ? devices[0].device.Address.toString(16).toUpperCase() : " ",
                    fill: `url(#ue-${object.standingType}-${object.facing}-device${animal ? "-animal" : ""}${errorClass ? `-${errorClass}` : ""})`,
                    led: useData ? devices[0]?.shadow?.LED || null : null,
                };
            }
            case "animals": {
                const {angle} = animationManager.Update(object.id);
                const t = getT();
                const pigsNumber = isNil(object.overrideAnimalCount)
                    ? getAnimalSizeNameBySize(object.animals[0].AnmCnt || 1)
                    : object.overrideAnimalCount;
                const errorClass = useData
                    ? getErrorClass(object, alerts, false)
                    : "";
                const percentage = animals[0]?.shadow?.Percentage;
                let feedState = "";
                if (useData) {
                    if (!isNil(percentage)) {
                        const {FeedingMinimumLevel, FeedingMaximumLevel} =
                            getFeedingLevels();
                        const minimumLevel = 100 * FeedingMinimumLevel;
                        const maximumLevel = 100 * FeedingMaximumLevel;
                        if (percentage <= minimumLevel) {
                            feedState = "feed-0";
                        } else if (percentage < maximumLevel) {
                            feedState = "feed-1";
                        } else if (percentage < 100) {
                            feedState = "feed-2";
                        } else {
                            feedState = "feed-3";
                        }
                    }
                }
                const animalSize = getAnimalSizeClassName(object.animals[0]);
                //todo: dorobic wyszukanie animalsa w device i wtedy show na false jesli jest device
                return {
                    show: true,
                    _progress: feedState || null,
                    fill:
                        pigsNumber === 0
                            ? "none"
                            : `url(#ue-pig${appendPrefix(pigsNumber)}${appendPrefix(animalSize)}${appendPrefix(errorClass)})`,
                    text: !isNil(object.overrideText)
                        ? object.overrideText
                        : pigsNumber > 1 || !isNil(object.overrideAnimalCount)
                          ? t("pcs", {count: object.animals[0].AnmCnt})
                          : object.animals[0].AnmNo1 || "-",
                    rotateObject:
                        pigsNumber === 0
                            ? 0
                            : pigsNumber <= 1
                              ? angle
                              : getClosestValueByMinMaxAndStep(
                                    angle,
                                    -360,
                                    360,
                                    90
                                ),
                };
            }
            default: {
                return {
                    show: false,
                };
            }
        }
    }
);

export const onBeforeRenderShowLocation = (...args) => {
    return onBeforeRenderChambers(args[0], false);
};

const _getTotalAnimals = memoize((id) => {
    return memoizeOne((animals, getColorByAnmID) => {
        return animals.reduce(
            (prev, curr) => {
                const color = getColorByAnmID(curr.AnmID) ?? null;
                if (color) prev.color.add(color);
                return {
                    total: prev.total + (curr.AnmCnt ?? 0),
                    selected:
                        prev.selected +
                        (getColorByAnmID(curr.AnmID) ? (curr.AnmCnt ?? 0) : 0),
                    color: prev.color,
                };
            },
            {total: 0, selected: 0, color: new Set()}
        );
    });
});

const getChamberClassName = (standingsExpanded, colorSet) => {
    if (colorSet.size === 0) return "";
    const colors = [...colorSet.keys()].map((o) =>
        o.startsWith("color-") ? o.replace("color-", "") : o
    );
    colors.sort();
    return `color-${colors.join("-")}`;
};

export const onBeforeRenderAnimalFilter = withDefaults(
    ({object, devices, animals, showSmalls, alerts}, getColorByAnmID) => {
        switch (object.type) {
            case "chambers": {
                const animalCount = _getTotalAnimals(object.id)(
                    animals,
                    getColorByAnmID
                );
                const hide = showSmalls && object.hideOnSmallItems;
                return {
                    show: true,
                    className: animalCount.selected
                        ? getChamberClassName(hide, animalCount.color)
                        : "",
                    text: hide
                        ? " "
                        : animalCount.total
                          ? `${animalCount.selected}/${animalCount.total}`
                          : "-",
                };
            }
            case "standings": {
                return {
                    show: true,
                    text: animals[0]?.AnmNo1 ?? " ",
                    fill: `url(#ue-${object.standingType}-${object.facing}${animals.length ? "-animal" : ""}${devices.find(({device}) => [DevTypes.DISPENSER_NRF, DevTypes.DISPENSER].includes(device.DevType)) ? "-device" : ""}${appendPrefix(getColorByAnmID(animals?.[0]?.AnmID) ?? null)})`,
                };
            }
            case "animals": {
                const {angle} = animationManager.Update(object.id);
                const t = getT();
                const pigsNumber = isNil(object.overrideAnimalCount)
                    ? getAnimalSizeNameBySize(object.animals[0].AnmCnt || 1)
                    : object.overrideAnimalCount;
                const color = object.animals[0]
                    ? (getColorByAnmID(object.animals[0].AnmID) ?? null)
                    : null;
                const animalSize = getAnimalSizeClassName(object.animals[0]);
                //todo: dorobic wyszukanie animalsa w device i wtedy show na false jesli jest device
                return {
                    show: true,
                    fill:
                        pigsNumber === 0
                            ? "none"
                            : `url(#ue-pig${appendPrefix(pigsNumber)}${appendPrefix(animalSize)}${appendPrefix(color)})`,
                    text: !isNil(object.overrideText)
                        ? object.overrideText
                        : pigsNumber > 1 || !isNil(object.overrideAnimalCount)
                          ? t("pcs", {count: object.animals[0].AnmCnt})
                          : object.animals[0].AnmNo1 || "-",
                    rotateObject:
                        pigsNumber === 0
                            ? 0
                            : pigsNumber <= 1
                              ? angle
                              : getClosestValueByMinMaxAndStep(
                                    angle,
                                    -360,
                                    360,
                                    90
                                ),
                };
            }
            case "devices": {
                return {
                    show: true,
                    ...getDevicesData(devices, {
                        ipsum: {show: showSmalls},
                        cage: {show: showSmalls},
                    }),
                };
            }
            case "groups": {
                const errorClass = getErrorClass(object, alerts, false);
                return {
                    show: true,
                    text: " ",
                    fill: `url(#ue-${object.standingType}-${object.facing}-device${errorClass ? `-${errorClass}` : ""})`,
                    led: devices[0]?.shadow?.LED || null,
                };
            }
            default: {
                return {
                    show: false,
                };
            }
        }
    }
);

export const onBeforeRenderLocationChooser = withDefaults(
    ({object, devices, animals}, whitelist, type) => {
        switch (object.type) {
            case "buildings": {
                return {
                    show: ["buildings"].includes(type),
                    disabled:
                        type !== object.type ||
                        (whitelist !== null && !whitelist.includes(object.id)),
                };
            }
            case "sectors": {
                return {
                    show: ["sectors"].includes(type),
                    disabled:
                        type !== object.type ||
                        (whitelist !== null && !whitelist.includes(object.id)),
                    className: `sector-type-${object?.location?.sectorType || 0}`,
                };
            }
            case "chambers": {
                return {
                    show: ["chambers"].includes(type),
                    disabled:
                        type !== object.type ||
                        (whitelist !== null && !whitelist.includes(object.id)),
                };
            }
            case "devices": {
                return {
                    show: false,
                };
            }
            case "standings": {
                return {
                    show: ["standings"].includes(type),
                    disabled:
                        type !== object.type ||
                        (whitelist !== null && !whitelist.includes(object.id)),
                    fill: `url(#ue-${object.standingType}-${object.facing}${animals.length ? "-animal" : ""}${devices.find(({device}) => [DevTypes.DISPENSER_NRF, DevTypes.DISPENSER].includes(device.DevType)) ? "-device" : ""})`,
                };
            }
            default: {
                return {
                    show: false,
                };
            }
        }
    }
);

export const onBeforeRenderLocationChooserSettings = withDefaults(
    (props, selectedId) => {
        const tmp = onBeforeRenderLocationChooser(props, null, "chambers");
        if (props.object.id === selectedId) {
            tmp.className = "map-selected-item success";
        }
        return tmp;
    }
);

const prepareText = ({
    show,
    name,
    climate,
    electric,
    animal,
    water,
    diode,
    light,
    group,
    piglets,
}) => {
    let text = [];
    const appendText = (t, i) => {
        let value;
        let status;
        if (isObject(t)) {
            value = t.text;
            status = t.className;
        } else {
            value = t;
        }
        if (value !== Defaults.NOT_FOUND_TEXT || status) {
            if (i) {
                text.push({text: `${value}`, icon: i});
            } else {
                text.push(`${value}`);
            }
        }
    };
    if (show) {
        appendText(name);
        if (animal) appendText(animal, "fas fa-pig");
        if (piglets) appendText(piglets, "fas fa-baby-carriage");
        if (group) appendText(group, "fas");
        if (climate) appendText(climate, "fas fa-thermometer-three-quarters");
        if (water) appendText(water, "fas fa-faucet");
        if (electric) appendText(electric, "fas fa-bolt");
        if (diode) appendText(diode, "fas fa-location-dot");
        if (light) appendText(light, "fas fa-lightbulb-on");

        return text;
    }
    return null;
};

const getGroupNameFromAnimals = (animals, groupMap) => {
    return animals.map((item) => groupMap.get(item.AnmID)).find((o) => o);
};

export const onBeforeRenderAll = withDefaults((args, opts) => {
    const {object, devices, animals, alerts, showSmalls, diodeCounter} = args;
    const {
        mapSettings: {
            numberOfAnimals,
            groups,
            climate,
            electric,
            water,
            diode,
            light,
            numberOfPiglets,
        } = {},
        groupMap,
        balanceMap,
    } = opts;
    switch (object.type) {
        case "chambers": {
            const hide = showSmalls && object.hideOnSmallItems;
            const className = getErrorClass(object, alerts, showSmalls);
            let name = object.location.name;
            const group = getGroupNameFromAnimals(animals, groupMap);
            const tmp = {
                show: !hide,
                name: name,
            };
            // todo: zoptymalizować ten bałagan, 2 reducy + filter
            const {animalsCount, pigletsCount} =
                numberOfPiglets || numberOfAnimals
                    ? animals.reduce(
                          (a, {AnmID, AnmCnt}) => {
                              if (isFiniteNumber(+AnmCnt))
                                  a.animalsCount += +AnmCnt;
                              if (isFiniteNumber(balanceMap?.[AnmID]))
                                  a.pigletsCount += balanceMap[AnmID];
                              return a;
                          },
                          {animalsCount: 0, pigletsCount: 0}
                      )
                    : emptyObj;
            if (numberOfAnimals) {
                tmp.animal = animalsCount;
            }
            if (numberOfPiglets) {
                tmp.piglets = pigletsCount;
            }
            if (groups && group) {
                tmp.group = group.GrNo1;
            }
            if (climate) {
                tmp.climate = getClimateData(devices);
            }
            if (electric) {
                tmp.electric = getElectricData(devices);
            }
            if (water) {
                tmp.water = getWaterData(devices);
            }
            if (diode) {
                tmp.diode = diodeCounter
                    ? diodeCounter
                    : Defaults.NOT_FOUND_TEXT;
            }
            if (light) {
                tmp.light = getRelayDataAsChamber(devices);
            }
            return {
                show: true,
                className: className,
                text: prepareText(tmp),
            };
        }
        default: {
            return onBeforeRenderChambers(args, true);
        }
    }
});
