import {getTasks} from "../api/task/getTasks";
import {getTechnologyGroupTasks} from "../api/task/getTechnologyGroupTasks";

export function setFilters(filters) {
    return function (dispatch) {
        dispatch({
            type: "SET_FILTERS",
            payload: filters,
        });
    };
}

export function setAggregation(aggregation) {
    return function (dispatch) {
        dispatch({
            type: "SET_AGGREGATION",
            payload: aggregation,
        });
    };
}

export function setBookmarkSettingModal(show) {
    return function (dispatch) {
        dispatch({
            type: "SET_BOOKMARK_SETTING_MODAL",
            payload: show,
        });
    };
}

export function selectBookmark(bookmark) {
    return function (dispatch) {
        dispatch({
            type: "SELECT_BOOKMARK",
            payload: bookmark,
        });
    };
}

export function setSort(sort) {
    return function (dispatch) {
        dispatch({
            type: "SET_SORT",
            payload: sort,
        });
    };
}

export function getTasksAPI(
    {
        DtaPlanned,
        DtaPlannedFrom,
        DtaPlannedTo,
        IsOpened,
        IsDone,
        Title,
        AssignTo,
        Priority,
        EstimatedTime,
        Limit,
        Reversed,
        Category,
        forceFetch = false,
        signal = null,
        NextToken,
        SortBy,
        AuthorID,
        DtaModTime,
        DtaModTimeFrom,
        DtaModTimeTo,
        ProjID,
        Observers,
    } = {},
    keepExisting = false,
    keepPage = false,
    setLoading = true,
    scroll = null
) {
    return function (dispatch) {
        dispatch({
            type: "GET_TASKS",
            payload: getTasks(
                {
                    DtaPlanned,
                    DtaPlannedFrom,
                    DtaPlannedTo,
                    IsOpened,
                    IsDone,
                    Title,
                    AssignTo,
                    Priority,
                    EstimatedTime,
                    Limit,
                    Reversed,
                    forceFetch,
                    signal,
                    NextToken,
                    Category,
                    SortBy,
                    AuthorID,
                    DtaModTime,
                    DtaModTimeFrom,
                    DtaModTimeTo,
                    Observers,
                },
                {FarmID: ProjID}
            ),
            meta: {
                keepExisting,
                keepPage,
                setLoading,
                scroll,
            },
        });
    };
}

export function getTasksWithReplace({
    DtaPlanned,
    DtaPlannedFrom,
    DtaPlannedTo,
    IsOpened,
    IsDone,
    Title,
    AssignTo,
    Priority,
    EstimatedTime,
    Limit,
    Reversed,
    Category,
    forceFetch = false,
    SortBy,
    AuthorID,
    FarmID,
} = {}) {
    return function (dispatch) {
        dispatch({
            type: "GET_TASKS_WITH_REPLACE",
            payload: getTasks(
                {
                    DtaPlanned,
                    DtaPlannedFrom,
                    DtaPlannedTo,
                    IsOpened,
                    IsDone,
                    Title,
                    AssignTo,
                    Priority,
                    EstimatedTime,
                    Limit,
                    Reversed,
                    forceFetch,
                    Category,
                    SortBy,
                    AuthorID,
                },
                {FarmID}
            ),
            meta: {DtaPlanned, FarmID},
        });
    };
}

export function getGroupedTasks(
    generalFilters,
    groupNames,
    maxItems = 10,
    replace = true,
    isLoading = true,
    clearCurrent = true
) {
    return function (dispatch) {
        const promises = groupNames.map((item) =>
            !item.selector
                ? getTasks(
                      {...generalFilters, ...item.filters},
                      {FarmID: item.FarmID}
                  )
                : null
        );
        dispatch({
            type: "GET_GROUPED_TASKS",
            payload: Promise.all(promises),
            meta: {groupNames, maxItems, replace, isLoading, clearCurrent},
        });
    };
}

export function changeTaskMonth(month, year) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_TASK_MONTH_AND_YEAR",
            payload: {month, year},
        });
    };
}

export function setSelectedTaskStatus(status) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_STATUS_SELECTED_TASK",
            payload: status,
        });
    };
}

export function setTaskStatus(IssueID, Status) {
    return function (dispatch) {
        dispatch({
            type: "SET_TASK_STATUS",
            payload: Status,
            meta: {IssueID},
        });
    };
}

export function clearTasks() {
    return function (dispatch) {
        dispatch({
            type: "CLEAR_TASKS",
        });
    };
}

export function editTask(task) {
    return function (dispatch) {
        dispatch({
            type: "EDIT_TASK",
            payload: task,
        });
    };
}

export function addTaskComment(comment, issueID) {
    return function (dispatch, getState) {
        let state = getState();
        const LocalUserID = state.user.user.LocalUserID;
        dispatch({
            type: "ADD_TASK_COMMENT",
            payload: {Text: comment, Date: +new Date(), LocalUserID},
            meta: {issueID},
        });
    };
}

export function removeTaskComment(index) {
    return function (dispatch) {
        dispatch({
            type: "REMOVE_TASK_COMMENT",
            payload: {index},
        });
    };
}

export function editTaskComment(index, comment) {
    return function (dispatch) {
        dispatch({
            type: "EDIT_TASK_COMMENT",
            payload: {index, comment},
        });
    };
}

export function addTemporaryTask(task) {
    return function (dispatch) {
        dispatch({
            type: "ADD_TEMPORARY_TASK",
            payload: task,
        });
    };
}

export function removeTaskFromList(id) {
    return function (dispatch) {
        dispatch({
            type: "REMOVE_TASK_FROM_LIST",
            payload: {id},
        });
    };
}

export function startTaskFileUpload(FileID, size, IssueID, file) {
    return function (dispatch) {
        dispatch({
            type: "START_TASK_FILE_UPLOAD",
            payload: {size, file},
            meta: {FileID, IssueID},
        });
    };
}

export function taskFileUploadProgress(FileID, loaded, IssueID) {
    return function (dispatch) {
        dispatch({
            type: "TASK_FILE_UPLOAD_PROGRESS",
            payload: {loaded},
            meta: {FileID, IssueID},
        });
    };
}

export function removeTaskUploadStatus(FileID, IssueID) {
    return function (dispatch) {
        dispatch({
            type: "TASK_FILE_UPLOAD_REMOVE",
            meta: {FileID, IssueID},
        });
    };
}

export function changeTaskGroupShowStatus(name) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_TASK_GROUP_SHOW_STATUS",
            payload: {name},
        });
    };
}

export function getDataAllProjects(projects, filters) {
    return function (dispatch) {
        const promises = projects.map((project) =>
            getTasks(filters, {FarmID: project.ProjID})
        );
        dispatch({
            type: "GET_TASKS_FOR_PROJECTS",
            payload: Promise.allSettled(promises),
        });
    };
}

export function getTasksForTechnologyGroup(start, FarmID) {
    return async function (dispatch) {
        const tasks = await getTechnologyGroupTasks(
            {GroupStartTime: start},
            {FarmID}
        );
        dispatch({
            type: "GET_TECHNOLOGYGROUP_TASKS_FULFILLED",
            payload: {Items: [...tasks]},
        });
    };
}

export function changeColorMethod(value) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_COLOR_METHOD",
            payload: value,
        });
    };
}

export function addTaskToDoItem(text, done, issueID) {
    return function (dispatch) {
        dispatch({
            type: "ADD_TASK_TO_DO_ITEM",
            payload: {Text: text, Done: done},
            meta: {issueID},
        });
    };
}

export function removeTaskToDoItem(text, issueID) {
    return function (dispatch) {
        dispatch({
            type: "REMOVE_TASK_TO_DO_ITEM",
            meta: {issueID, Text: text},
        });
    };
}

export function setToDoItemStatus(status, issueID, listID) {
    return function (dispatch) {
        dispatch({
            type: "SET_TO_DO_ITEM_STATUS",
            payload: {Done: status},
            meta: {issueID, listID},
        });
    };
}
