import {detect} from "detect-browser";

export function isMobile() {
    try {
        const browser = detect();
        if (browser.os.startsWith("Android")) return true;
        if (browser.os.startsWith("iPad")) return true;
        if (browser.os.startsWith("iPhone")) return true;
        if (browser.os.startsWith("iOS")) return true;
        if (/Macintosh/.test(navigator.userAgent) && "ontouchend" in document)
            return true;
    } catch (e) {
        console.error("isMobile error", e.name, e.message);
    }
    return false;
}

export function isIOS() {
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}
