import moment from "moment";
import React, {useCallback, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {utcDateFormatter} from "../../../utils/DateTimeUtils";
import {isBetween, isFiniteNumber} from "../../../utils/MathUtils";
import {getAnimalUnit} from "../../../utils/SettingsUtils";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import Chart from "../../basics/chart/Chart";
import CompareWeightsTooltip from "../../charts/CompareWeightsTooltip";
import CollapsableContainer from "../containers/CollapsableContainer";
import {calculateAutoDomainForFallChart} from "../ideal-charts/charts/utils";
import {getColorByHashedKey} from "../../../utils/ColorUtils";

const emptyArray = [];

function FallChart({
    fallDict,
    start,
    end,
    chartDomain = emptyArray,
    referenceLines,
}) {
    const forwardRef = useRef(null);

    const {t} = useTranslation();

    const animalUnit = useMemo(() => getAnimalUnit(), []);

    const weightUnit = useMemo(
        () => getUnit("weight", animalUnit),
        [animalUnit]
    );

    const weightFormatter = useCallback(
        (value) => {
            if (!isFiniteNumber(value)) return null;
            return convertWeightUnitTo(value, {
                showUnit: false,
                unit: animalUnit,
                rawValue: true,
                fixed: 2,
            });
        },
        [animalUnit]
    );

    const dataSet = useMemo(() => {
        const ds = [];
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(
            moment.utc(chartStart).diff(moment.utc(chartEnd), "day")
        );
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            const isGroupActive = isBetween(+dayUTC, start, end);
            if (!isGroupActive) {
                ds.push({
                    time: +dayUTC,
                });
                continue;
            }
            ds.push({
                time: +dayUTC,
            });
            // getting events by utc day from dict
            const eventsForCurrentDay = fallDict[+dayUTC] ?? null;
            if (Array.isArray(eventsForCurrentDay)) {
                for (const event of eventsForCurrentDay) {
                    ds.push({
                        ...event,
                        time: +dayUTC,
                    });
                }
            }
        }
        return ds;
    }, [chartDomain, start, end, fallDict]);

    const chartDomainY = useMemo(() => {
        return calculateAutoDomainForFallChart(dataSet);
    }, [dataSet]);

    const dataDef = useMemo(() => {
        const usedReasons = {};
        const tmp = [];
        for (let item of dataSet) {
            if (item.Reason) {
                const color = getColorByHashedKey(item.Reason);
                if (!usedReasons[item.Reason]) {
                    usedReasons[item.Reason] = item.Reason;
                    tmp.push({
                        dataKey: item.Reason,
                        color: color,
                        chartType: "Scatter",
                        opacity: 1,
                        unit: weightUnit,
                        name: item.Reason,
                        yAxisId: "scatterAxis",
                        formatter: weightFormatter,
                    });
                }
            }
        }
        return tmp;
    }, [weightFormatter, weightUnit, dataSet]);

    return (
        <CollapsableContainer.Card
            id="cy-falls-card"
            header={t("printSelectedAnimalsModal.falls")}
            defaultExpanded={dataDef.length}
            forwardRef={forwardRef}>
            <div className={"h-0 mh-15rem"}>
                <Chart
                    data={dataSet}
                    dataDef={dataDef}
                    syncId={"idealChart"}
                    isAnimationActive={false}
                    tooltipContent={<CompareWeightsTooltip />}
                    syncMethod={"value"}
                    saveAsExcell={t("printSelectedAnimalsModal.falls")}
                    Yaxis={{
                        name: t("weight"),
                        yAxisId: "lineAxis",
                        type: "number",
                        domain: chartDomainY,
                        formatter: weightFormatter,
                    }}
                    thirdYaxis={{
                        yAxisId: "scatterAxis",
                        type: "number",
                        domain: chartDomainY,
                    }}
                    Xaxis={{
                        name: "",
                        domain: chartDomain,
                        dataKey: "time",
                        formatter: utcDateFormatter,
                        type: "number",
                    }}
                    type={"Composed"}
                    referenceLines={referenceLines}
                />
                {referenceLines && referenceLines.length === 0 && (
                    <div className="chart-template-no-data">
                        <h5>{t("chartDisplayNoBirthTime")}</h5>
                        <div>{t("chartDisplayNoBirthTimeText")}</div>
                    </div>
                )}
            </div>
        </CollapsableContainer.Card>
    );
}

export default React.memo(FallChart);
