import React, {useCallback, useEffect, useRef, useState} from "react";
import {getFileObject as getFileObjectAPI} from "../../../../api/files/getFileObject";
import {InputActionsWrapperFileItemRenderer} from "../../../basics/input-actions-wrapper/file-list/InputActionsWrapperFileItem";
import {useOnScreen} from "../../../../hooks/useOnScreen";
import DesktopCalendarTaskDrawerFileMenu from "./DesktopCalendarTaskDrawerFileMenu";
import {showFile} from "../../../../actions/filesActions";
import {useDispatch} from "react-redux";
import {ModalName as ConfirmModalName} from "../../../modals-new/confirm-modal/ConfirmModal";
import {removeTaskUploadStatus} from "../../../../actions/taskActions";
import {
    removeFile,
    removeFileNotificationFailure,
    removeFileNotificationSuccess,
} from "../../../../api/files/removeFile";
import {useTranslation} from "react-i18next";
import {show} from "redux-modal";
import {isMobile} from "../../../../utils/MobileUtils";
function DesktopCalendarTaskDrawerFileItem({file, drawer, FarmID}) {
    const dispatch = useDispatch();
    const [fileObject, setFileObject] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [menu, setMenu] = useState(null);
    const {FileID} = file;
    const container = useRef();
    const {t} = useTranslation();
    const isOnScreen = useOnScreen(container, drawer?.current);

    useEffect(() => {
        if (!isOnScreen || fileObject) return;

        async function getFileObject() {
            let res = await getFileObjectAPI(FileID, {FarmID});
            setFileObject(res.file);
            setThumbnail(res.thumbnail);
        }

        getFileObject();
    }, [isOnScreen, FileID, fileObject, FarmID]);

    const onPreviewClick = useCallback(() => {
        dispatch(showFile(fileObject));
    }, [fileObject, dispatch]);

    const onContextMenu = useCallback((e) => {
        e.preventDefault();
        setMenu(e);
    }, []);

    let canUseThumbnail =
        fileObject &&
        fileObject.Type.startsWith("image/") &&
        !fileObject.Type.includes("gif");
    let canShowPreview =
        fileObject &&
        (fileObject.Type.includes("image") ||
            fileObject.Type.includes("video") ||
            fileObject.Type.includes("pdf"));

    const onRemoveClick = useCallback(() => {
        dispatch(
            show(ConfirmModalName, {
                title: t("removeFile", {name: fileObject.FileName}),
                text: t("removeFileText", {name: fileObject.FileName}),
                confirmText: t("yes"),
                onConfirmed: (props) => {
                    return removeFile(fileObject.FileID, {FarmID})
                        .then((res) => {
                            props.handleHide();
                            dispatch(
                                removeTaskUploadStatus(
                                    fileObject.FileID,
                                    fileObject.IssueID
                                )
                            );
                            removeFileNotificationSuccess(res);
                        })
                        .catch((e) => {
                            removeFileNotificationFailure(e);
                        });
                },
            })
        );
    }, [dispatch, fileObject, t, FarmID]);

    return (
        <div ref={container}>
            <InputActionsWrapperFileItemRenderer
                type={fileObject?.Type || ""}
                name={fileObject?.FileName || file.Name}
                onClick={canShowPreview ? onPreviewClick : null}
                onContextMenu={fileObject ? onContextMenu : null}
                thumbnail={canUseThumbnail && thumbnail}
                showRemove={isMobile()}
                onRemoveClick={onRemoveClick}
            />
            <DesktopCalendarTaskDrawerFileMenu
                menu={menu}
                fileObject={fileObject}
                onRemoveClick={onRemoveClick}
            />
        </div>
    );
}

export default React.memo(DesktopCalendarTaskDrawerFileItem);
