import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {sortDateStrings} from "../../../utils/SortUtils";
import moment from "moment";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {
    getAnimalEventsWithType,
    getCycles,
    getSelectedAnimalForDocuments,
} from "../../../selectors/animalDocumentsSelectors";
import {EventTypes, PARTURITION_DIFFICULTY} from "../../../constans/eventTypes";
import {connect} from "react-redux";
import {get, isEmpty, isEqual, last} from "lodash";
import {AnimalTypes} from "../../../constans/animalTypes";
import {convertTemperatureUnitTo} from "../../../utils/UnitUtils";
import PropTypes from "prop-types";
import Tooltip from "../../basics/tooltip/Tooltip";
import {isMobile} from "../../../utils/MobileUtils";
import CollapsableContainer from "../containers/CollapsableContainer";
import memoizeOne from "memoize-one";

function makeMapStateToProps(state) {
    const getSortedCycles = memoizeOne((cycles) =>
        cycles.slice().sort((a, b) => b.cycle - a.cycle)
    );
    return {
        events: [
            ...getAnimalEventsWithType(state, EventTypes.PARTURITION_START),
            ...getAnimalEventsWithType(state, EventTypes.PARTURITION_END),
            ...getAnimalEventsWithType(state, EventTypes.PARTURITION),
        ],
        cycles: getSortedCycles(getCycles(state)), // użycie memoizowanej funkcji
        animal: getSelectedAnimalForDocuments(state),
    };
}

class ParturitionHistoryGridCard extends React.Component {
    constructor(props) {
        super(props);
        this.mobile = isMobile();
        this.state = this.getData();
    }

    getData() {
        let data = [];
        const {events, animal, cycle: cycleNumber, cycles} = this.props;
        let date, maxDate;
        let cycle = cycles.find((item) => item.cycle === cycleNumber);
        if (
            [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW].includes(
                animal.AnimalKind
            )
        ) {
            date = cycle ? moment(cycle.StartTime) : moment(0);
            maxDate = cycle ? (cycleNumber === 0 ? null : cycle.EndTime) : null;
        } else {
            if (animal.DtaBrthTime || animal.DtaInTime) {
                date = moment(animal.DtaBrthTime || animal.DtaInTime).startOf(
                    "day"
                );
            } else {
                date = moment(0);
            }
        }
        const filterEventsBasedOnCode = (EvCode) => {
            return events.filter((item) => {
                if (item.EvCode !== EvCode) return false;
                if (
                    item.EvTime < date.clone().startOf("day").toDate().getTime()
                )
                    return false;
                return maxDate ? item.EvTime < maxDate : true;
            });
        };
        const parturitionStarts = filterEventsBasedOnCode(
            EventTypes.PARTURITION_START
        );
        const parturitionEnds = filterEventsBasedOnCode(
            EventTypes.PARTURITION_END
        );
        const parturitions = filterEventsBasedOnCode(EventTypes.PARTURITION);
        if (
            !isEmpty(parturitionStarts) ||
            !isEmpty(parturitions) ||
            !isEmpty(parturitionEnds)
        ) {
            const parturitionHistory = get(
                parturitions[0],
                "EvData.History",
                []
            );
            if (parturitionHistory.length > 0) {
                parturitionHistory.forEach((item, index) => {
                    data.push({
                        parturitionStart:
                            index === 0
                                ? get(last(parturitionStarts), "EvTime")
                                : null,
                        parturitionDate: get(item, "EvTime.Old"),
                        alive: get(item, "HealthyCnt.Old", 0),
                        weak: get(item, "WeakCnt.Old", 0),
                        mummies: get(item, "MummyCnt.Old", 0),
                        dead: get(item, "DeadCnt.Old", 0),
                        temperature: get(item, "Temperature.Old"),
                        difficulty: get(item, "Type.Old"),
                    });
                });
            }
            data.push({
                parturitionStart:
                    parturitionHistory.length === 0
                        ? get(last(parturitionStarts), "EvTime")
                        : null,
                parturitionDate: get(parturitions[0], "EvTime"),
                parturitionEnd: get(last(parturitionEnds), "EvTime"),
                alive: get(parturitions[0], "EvData.HealthyCnt", 0),
                weak: get(parturitions[0], "EvData.WeakCnt", 0),
                mummies: get(parturitions[0], "EvData.MummyCnt", 0),
                dead: get(parturitions[0], "EvData.DeadCnt", 0),
                temperature: get(parturitions[0], "EvData.Temperature"),
                difficulty: get(parturitions[0], "EvData.Type"),
                parturitionStarts,
                parturitionEnds,
            });
        }

        return {data, opened: data.length > 0};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cycle, events} = this.props;
        if (
            cycle !== prevProps.cycle ||
            !isEqual(events, prevProps.events) ||
            !isEqual(this.props.cycles, prevProps.cycles)
        ) {
            this.setState(this.getData());
        }
    }

    dateValueFormatter = (value) => {
        if (value) return moment(value).format("DD.MM.YY HH:mm");
        return "-";
    };

    parturitionDateValueFormatter = ({value, key}) => {
        const parturitionsDiscrepancy = this.isParturitionsDiscrepancy();
        const {t} = this.props;
        return (
            <div>
                {this.dateValueFormatter(value)}
                {parturitionsDiscrepancy.result &&
                    parturitionsDiscrepancy.guilty === key && (
                        <Tooltip
                            tooltipContent={
                                key === EventTypes.PARTURITION_START
                                    ? t(
                                          "parturitionHistoryGridCard.moreParturitionStartsError"
                                      )
                                    : t(
                                          "parturitionHistoryGridCard.moreParturitionEndsError"
                                      )
                            }
                            placement="auto"
                            type="error">
                            <i className="fas color-error fa-exclamation-circle ms-2" />
                        </Tooltip>
                    )}
            </div>
        );
    };

    isParturitionsDiscrepancy = () => {
        const {parturitionStarts = [], parturitionEnds = []} = this.state;
        return {
            result: parturitionStarts.length !== parturitionEnds.length,
            guilty:
                parturitionStarts.length > parturitionEnds.length &&
                parturitionEnds.length > 0
                    ? EventTypes.PARTURITION_START
                    : EventTypes.PARTURITION_END,
        };
    };

    difficultyValueFormatter = (value) => {
        const {t} = this.props;
        switch (value) {
            case PARTURITION_DIFFICULTY.LOW:
                return t("low");
            case PARTURITION_DIFFICULTY.MEDIUM:
                return t("medium");
            case PARTURITION_DIFFICULTY.HARD:
                return t("hard");
            default:
                return "-";
        }
    };

    temperatureValueFormatter = (value) => {
        if (value) return convertTemperatureUnitTo(value, {showUnit: true});
        return "-";
    };

    render() {
        const {data} = this.state;
        const {t} = this.props;
        const headers = [
            {
                name: t("rfidApp.start"),
                field: "parturitionStart",
                valueFormatter: (value) =>
                    this.parturitionDateValueFormatter({
                        value,
                        key: EventTypes.PARTURITION_START,
                    }),
                customSort: sortDateStrings,
            },
            {
                name: t("rfidApp.end"),
                field: "parturitionEnd",
                valueFormatter: (value) =>
                    this.parturitionDateValueFormatter({
                        value,
                        key: EventTypes.PARTURITION_END,
                    }),
                customSort: sortDateStrings,
            },
            {
                name: t("newReports.birth.parturitionDate"),
                field: "parturitionDate",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
            },
            {
                name: t("alive"),
                field: "alive",
            },
            {
                name: t("weakPiglets"),
                field: "weak",
            },
            {
                name: t("dead"),
                field: "dead",
            },
            {
                name: t("mummies"),
                field: "mummies",
            },
            {
                name: t("newSettings.dispenserNRF.temperature.title"),
                field: "temperature",
                valueFormatter: this.temperatureValueFormatter,
            },
            {
                name: t("difficulty"),
                field: "difficulty",
                valueFormatter: this.difficultyValueFormatter,
            },
        ];
        return (
            <>
                <CollapsableContainer.Fieldset
                    id="cy-parturition-history-card"
                    header={t("parturitionHistoryGridCard.parturitionDetails")}
                    defaultExpanded={data.length > 0}>
                    <TableGrid
                        data={data}
                        headers={headers}
                        mobileRow={<DefaultMobileRow />}
                        showPagination={!this.mobile}
                        paginationItems={10}
                        scrollOnPageChange={false}
                    />
                </CollapsableContainer.Fieldset>
            </>
        );
    }
}

ParturitionHistoryGridCard.propTypes = {
    cycle: PropTypes.number.isRequired,
};

export default compose(
    withTranslation(),
    connect(makeMapStateToProps)
)(ParturitionHistoryGridCard);
