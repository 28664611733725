import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {EventTypes} from "../../constans/eventTypes";
import {cloneDeep, get, isEmpty, last} from "lodash";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import {Col, Collapse, Row} from "react-bootstrap";
import "./css/_basic-info-box.scss";
import Card from "../basics/card/Card";
import {ModalName as EditAnimalBasicsModalName} from "../modals-new/edit-animal-basics/EditAnimalBasicsModal";

import Button from "../basics/button/Button";
import PropTypes from "prop-types";
import {
    getMaxDelayForBirth,
    getTimeFromInseminationToPartuition,
    getTimeOnBirthRoomMommy,
} from "../../utils/SettingsUtils";
import {
    getPigBalanceByCycle,
    getPlannedParturitionForASow,
} from "../../utils/EventUtils";
import {convertRowsToCycles} from "../../utils/AnimalDocumentsUtils";
import {withTranslation} from "react-i18next";
import Tooltip from "../basics/tooltip/Tooltip";
import ConfirmRemoveAnimal from "../modals-new/confirm-remove-animal/ConfirmRemoveAnimal";
import ConfirmResurrectAnimal from "../modals-new/confirm-resurect-animal/ConfirmResurrectAnimal";
import {
    getAnimalEvents,
    getGeneticsInformationForSelectedAnimal,
    getSelectedAnimalForDocuments,
    getTechnologyGroupForAnimal,
} from "../../selectors/animalDocumentsSelectors";
import {formatLocationName} from "../../utils/global-formatters/formatLocationName";
import BasicInfoBoxItem from "./BasicInfoBoxItem";
import ShowMoreLinkComponent from "../basics/show-more-link/ShowMoreLinkComponent";
import {getFeedingCurves} from "../../selectors/feedingSelector";
import {CurveTypesWithParturition} from "../../constans/feedingTypes";
import {withRouter} from "react-router-dom";
import {roleSelector} from "../../selectors/roleSelector";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import {getBoarOptions} from "../../utils/DataGridUtils";
import {getFarmID, isTOPIGSFarm} from "../../selectors/farmSelector";
import TOPIGSFieldSet from "../basics/topigs-fieldset/TOPIGSFieldSet";
import ColorItem from "../color-item/ColorItem";
import AnimalReferenceDocumentModalGrid from "../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import FindAnimal from "../find-animal/FindAnimal";
import {getFileObject as getFileObjectAPI} from "../../api/files/getFileObject";
import {showFile} from "../../actions/filesActions";
import {ModalName as PrintGeneratorAnimalModalName} from "../modals-new/print-generator-animal/PrintGeneratorAnimalModal";
import {getSowStatus} from "../../utils/AnimalsUtils";

const PigletsAmount = ({
    pigletsData: {
        piglets,
        tattooed,
        warning,
        parturitionStateTime,
        diff,
        greater,
    },
    t,
} = {}) => (
    <span>
        {`${piglets}${tattooed > 0 ? `/${tattooed}` : ""}`}
        {warning && (
            <Tooltip
                tooltipContent={t(
                    `animalDocuments.${greater ? "pigletsAmountDiscrepancyGreater" : "pigletsAmountDiscrepancy"}`,
                    {
                        date: moment(parturitionStateTime).format("L"),
                        diff,
                    }
                )}
                type={"warning"}>
                <i className="ms-1 fas fa-exclamation-triangle fa-fw" />
            </Tooltip>
        )}
    </span>
);

const RaceComponent = ({raceName, raceColor, raceCode}) => (
    <div className="d-flex justify-items-center align-items-center">
        <span>{`${raceName || "-"}${raceCode ? ` (${raceCode})` : ""}`}</span>
        {raceColor && <ColorItem className={"ms-2"} value={raceColor} />}
    </div>
);

function mapStateToProps(state, props) {
    return {
        farm: getFarmID(state),
        animal: getSelectedAnimalForDocuments(state),
        events: getAnimalEvents(state),
        races: state.dictionary.race.WData,
        fallReasons: state.dictionary.fallReasons.WData,
        clients: state.dictionary.clients.WData,
        cycleTable: state.animalDocuments.cycleTable,
        technologyGroup: getTechnologyGroupForAnimal(state),
        feedingCurves: getFeedingCurves(state),
        roles: roleSelector(state),
        isTOPIGSFarm: isTOPIGSFarm(state, props),
        geneticsInformation: getGeneticsInformationForSelectedAnimal(state),
        timeFromInseminationToParturition:
            getTimeFromInseminationToPartuition(state),
        maxDelayForBirth: getMaxDelayForBirth(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class BasicInfoBox extends Component {
    state = {
        showDetails: false,
        imageLoading: false,
    };

    getSupplier() {
        try {
            let supplier = this.props.clients.find(
                (item) => item.ID === this.props.animal.SupplierID
            );
            return supplier.Value;
        } catch (e) {
            return "-";
        }
    }

    getRace() {
        const {races = [], animal} = this.props;
        const race = races.find((item) => item?.ID === animal?.Race);
        return {
            RaceName: race?.Value,
            RaceCode: race?.Code,
            RaceColor: race?.Color,
        };
    }

    getBoarType() {
        const {animal, t} = this.props;
        const options = getBoarOptions();
        return (
            options.find((o) => o?.value === animal.BoarType)?.name ||
            t("boarTypes.stationary")
        );
    }

    getDeathReason() {
        let type = -1;
        if (this.props.animal && this.props.animal.DtaDthTime) {
            let filtered = cloneDeep(this.props.events);
            type = this.props.animal.DthRsn;
            filtered = filtered ? filtered : [];
            filtered.sort(
                (b, a) =>
                    (a.EvTime ? +a.EvTime : 0) - (b.EvTime ? +b.EvTime : 0)
            );
            filtered = filtered.filter(
                (event) =>
                    event.EvCode ===
                    (type === 1 ? EventTypes.SELL : EventTypes.FALL)
            )[0];
            if (filtered) {
                if (filtered.EvCode === EventTypes.SELL && filtered.EvData) {
                    let tmp = this.props.clients.filter(
                        (client) =>
                            `${client.ID}` === `${filtered.EvData.Buyer}`
                    )[0];
                    if (tmp) {
                        return {type: type, value: tmp.Value, image: null};
                    }
                }
                if (filtered.EvCode === EventTypes.FALL && filtered.EvData) {
                    let tmp = this.props.fallReasons.filter(
                        (reason) =>
                            `${reason.ID}` === `${filtered.EvData.Reasn}`
                    )[0];
                    if (tmp) {
                        return {
                            type: type,
                            value: tmp.Value,
                            image: filtered?.Files?.[0] || null,
                        };
                    }
                }
            }
        }
        return {type: type, value: "-", image: null};
    }

    getLocation() {
        const {animal} = this.props;
        return formatLocationName(animal.DelPlcmntID || animal.PlcmntID, {
            nameDeep: 3,
        });
    }

    getPigStatus() {
        const {
            animal,
            cycleTable,
            t,
            timeFromInseminationToParturition,
            maxDelayForBirth,
        } = this.props;
        const status = getSowStatus(animal, cycleTable, {
            timeFromInseminationToParturition,
            maxDelayForBirth,
        });
        return t(`sowStatus.${status}`);
    }

    getBirth(birthTime) {
        const {t} = this.props;
        let date = moment(birthTime);
        let age = moment().startOf("day").diff(birthTime, "days");
        if (age < 0) {
            // jesli swinia jeszcze sie nie urodzila (data ustawiona w przyszlosc)
            return (
                <span>
                    {date.format("L")}
                    <Tooltip
                        tooltipContent={t("animalDocuments.noBirthYet")}
                        type={"warning"}>
                        <i className="ms-1 fas fa-exclamation-triangle fa-fw" />
                    </Tooltip>
                </span>
            );
        } else {
            return `${date.format("L")} (${age} ${age === 1 ? this.props.t("day") : this.props.t("days")})`;
        }
    }

    getLastCycle = () => {
        const {cycleTable = []} = this.props;
        return last(cycleTable);
    };

    getLastActiveNipples = () => {
        const cycle = this.getLastCycle();
        return get(cycle, "AN[0].EvData.Nipples", "-");
    };

    getLastSeparationDate = () => {
        const {events} = this.props;
        const separations = events.filter(
            ({EvCode}) => EventTypes.SEPARATION === EvCode
        );
        if (separations.length) {
            separations.sort((o1, o2) => o2.EvTime - o1.EvTime);
            return moment(separations[0].EvTime).format("L");
        }
        return null;
    };

    getCurrentPigletsForASow() {
        const {cycleTable} = this.props;
        const cycles = convertRowsToCycles(cycleTable);
        if (!isEmpty(cycles)) {
            const currentCycle = cycles[cycles.length - 1];
            const {
                balance,
                withoutState,
                parturitionStateTime,
                tattooed,
                fromMommy,
            } = getPigBalanceByCycle(currentCycle);
            return {
                piglets: balance - tattooed - fromMommy,
                tattooed: tattooed + fromMommy,
                greater: withoutState < balance,
                diff: Math.abs(balance - withoutState),
                warning: withoutState !== balance,
                parturitionStateTime,
            };
        }
        return {
            piglets: 0,
            tattooed: 0,
            greater: false,
            diff: 0,
            warning: false,
            parturitionStateTime: 0,
        };
    }

    getParturitionCurve() {
        const {
            feedingCurves,
            animal: {feedParam},
        } = this.props;
        const curve = feedingCurves.find(
            (curve) => curve.SetData.Index === get(feedParam, "curveNr", 0) - 1
        );
        return {
            curve,
            isParturition: CurveTypesWithParturition.includes(
                curve?.SetData?.Type
            ),
        };
    }

    getFirstInseminationDate = () => {
        const {
            cycleTable = [],
            animal: {feedParam},
            timeFromInseminationToParturition,
        } = this.props;
        const _DateFormat = "L";
        let expected = false;
        let date = "-";
        if (cycleTable.length) {
            const firstInsemination = cycleTable[0][EventTypes.INSEMINATION][0];
            if (firstInsemination)
                date = moment(firstInsemination.EvTime)
                    .utc()
                    .format(_DateFormat);
            else if (this.getParturitionCurve().isParturition) {
                expected = true;
                date = moment(feedParam.startTime)
                    .subtract(timeFromInseminationToParturition, "days")
                    .format(_DateFormat);
            } else {
                const lastSeparation = cycleTable[0][EventTypes.SOW_CYCLES][0];
                if (lastSeparation) {
                    date = moment(lastSeparation.EvTime)
                        .subtract(
                            timeFromInseminationToParturition +
                                getTimeOnBirthRoomMommy(),
                            "days"
                        )
                        .utc()
                        .format(_DateFormat);
                    expected = true;
                }
            }
        }
        return {expected, date};
    };

    onEditAnimalBasicModalClick = () => {
        const {animal, show, events} = this.props;
        show(EditAnimalBasicsModalName, {animal, type: "GENERAL", events});
    };

    onEditAnimalGenetics = () => {
        const {animal, show, geneticsInformation, events} = this.props;
        show(EditAnimalBasicsModalName, {
            animal,
            type: "GENETICS",
            geneticsInformation,
            events,
        });
    };

    onShowDetailsClick = () => {
        this.setState((state) => ({showDetails: !state.showDetails}));
    };

    getPlannedParturition() {
        const {animal, events} = this.props;
        const plannedParturitionFromCycles = getPlannedParturitionForASow({
            ...animal,
            events,
        });
        if (plannedParturitionFromCycles) return plannedParturitionFromCycles;
        const curve = this.getParturitionCurve();
        if (curve.isParturition && animal?.feedParam?.startTime)
            return moment(animal.feedParam.startTime).format("L");
        return "-";
    }

    getPigletsHeaderName({tattooed = 0} = {}) {
        const {t} = this.props;
        let name = t("animalDocuments.sowPigletsCount");
        if (tattooed > 0)
            name += ` / ${t("planTypes.renovationSowsAmount").toLowerCase()}`;
        return name;
    }

    goToLogs = () => {
        const {
            history,
            farm,
            animal: {AnmID},
        } = this.props;
        const link = `/farm/${farm}/logs?object=${AnmID}`;
        history.push(link);
    };

    onPreviewImageClick = () => {
        const {image} = this.getDeathReason();
        if (!image) return;
        const {farm, dispatch} = this.props;
        this.setState(
            {
                imageLoading: true,
            },
            () => {
                getFileObjectAPI(image.FileID, {FarmID: farm})
                    .then((res) => {
                        dispatch(showFile(res.file));
                    })
                    .finally(() => {
                        this.setState({imageLoading: false});
                    });
            }
        );
    };
    openPrintModal = () => {
        const {show, animal} = this.props;
        const utils = {
            animalType: animal.AnimalKind,
            animal: animal,
            AnmNo1: animal.AnmNo1,
        };

        show(PrintGeneratorAnimalModalName, {utils});
    };
    render() {
        const {
            animal,
            showEdit,
            t,
            technologyGroup,
            roles,
            isTOPIGSFarm,
            geneticsInformation,
        } = this.props;
        const {showDetails, imageLoading} = this.state;
        const dthReason = this.getDeathReason();
        const inseminationDate = this.getFirstInseminationDate();
        const pigletsData =
            animal.AnimalKind === AnimalTypes.SOW
                ? this.getCurrentPigletsForASow()
                : {};
        const {RaceName, RaceCode, RaceColor} = this.getRace();
        const pigStatus = this.getPigStatus();
        return (
            <Card className="basic-info-box">
                <div className="d-flex justify-content-between">
                    <h5>{t("animalDocuments.basicInformation")}</h5>
                </div>
                <div>
                    <fieldset className="fieldset mb-2">
                        <BasicInfoBoxItem
                            name={
                                animal.PlcmntID
                                    ? t("placement")
                                    : t("lastPlacement")
                            }>
                            <FindAnimal animal={animal} />{" "}
                            {this.getLocation() || "-"}
                        </BasicInfoBoxItem>
                        {animal.AnimalKind === AnimalTypes.SOW && (
                            <BasicInfoBoxItem name={t("status")}>
                                {pigStatus}
                            </BasicInfoBoxItem>
                        )}
                    </fieldset>
                    <ShowMoreLinkComponent
                        onClick={this.onShowDetailsClick}
                        opened={showDetails}
                    />
                    <Collapse in={showDetails}>
                        <div id="info-box">
                            <fieldset className="fieldset mb-2">
                                <legend>{t("general")}</legend>
                                <Row>
                                    <Col md={6}>
                                        <BasicInfoBoxItem
                                            name={t("rfidNumber")}>
                                            {animal.RFID || "-"}
                                        </BasicInfoBoxItem>
                                        <BasicInfoBoxItem name={t("birthDate")}>
                                            {animal.DtaBrthTime
                                                ? this.getBirth(
                                                      animal.DtaBrthTime
                                                  )
                                                : "-"}
                                        </BasicInfoBoxItem>
                                        <BasicInfoBoxItem
                                            name={t("insertionDate")}>
                                            {animal.DtaInTime
                                                ? moment(
                                                      animal.DtaInTime
                                                  ).format("L")
                                                : "-"}
                                        </BasicInfoBoxItem>
                                    </Col>
                                    <Col md={6}>
                                        <BasicInfoBoxItem
                                            name={t("herdNumber")}>
                                            {animal.HerdNumber || "-"}
                                        </BasicInfoBoxItem>
                                        <BasicInfoBoxItem name={t("supplier")}>
                                            {this.getSupplier()}
                                        </BasicInfoBoxItem>
                                        {animal.AnimalKind ===
                                            AnimalTypes.BOAR && (
                                            <BasicInfoBoxItem name={t("type")}>
                                                {this.getBoarType()}
                                            </BasicInfoBoxItem>
                                        )}
                                    </Col>
                                </Row>
                                {showEdit && (
                                    <Button
                                        buttonStyle="round"
                                        icon={
                                            <i className="fas fa-pencil-alt" />
                                        }
                                        onClick={
                                            this.onEditAnimalBasicModalClick
                                        }
                                        className="basic-info-box-edit"
                                    />
                                )}
                            </fieldset>
                            {animal.DtaDthTime && (
                                <fieldset className="fieldset mb-2">
                                    <legend>
                                        {dthReason.type === 1
                                            ? t("sale")
                                            : t("fall")}
                                    </legend>
                                    <Row>
                                        <Col md={dthReason.type === 0 ? 6 : 12}>
                                            <BasicInfoBoxItem
                                                name={
                                                    dthReason.type === 1
                                                        ? t("saleDate")
                                                        : t(
                                                              "animalDocuments.deathDate"
                                                          )
                                                }>
                                                {animal.DtaDthTime
                                                    ? moment(
                                                          animal.DtaDthTime
                                                      ).format("L")
                                                    : "-"}
                                            </BasicInfoBoxItem>
                                        </Col>
                                        {dthReason.type === 0 && (
                                            <Col md={6}>
                                                <BasicInfoBoxItem
                                                    name={t(
                                                        "animalDocuments.deathReason"
                                                    )}>
                                                    {dthReason.value}
                                                </BasicInfoBoxItem>
                                            </Col>
                                        )}
                                    </Row>
                                    {dthReason.image && (
                                        <Col md={6}>
                                            <BasicInfoBoxItem
                                                name={t("fallPicture")}>
                                                <Button
                                                    className="mb-1"
                                                    buttonColor="success"
                                                    type="button"
                                                    icon={
                                                        <i className="fas fa-camera" />
                                                    }
                                                    text={t("preview")}
                                                    onClick={
                                                        this.onPreviewImageClick
                                                    }
                                                    isLoading={imageLoading}
                                                />
                                            </BasicInfoBoxItem>
                                        </Col>
                                    )}
                                </fieldset>
                            )}
                            {animal.AnimalKind === AnimalTypes.SOW && (
                                <fieldset className="fieldset mb-2">
                                    <legend>{t("menu.procreation")}</legend>
                                    <Row>
                                        <Col md={6}>
                                            <BasicInfoBoxItem
                                                name={
                                                    inseminationDate.expected
                                                        ? t(
                                                              "animalDocuments.expectedFirstInseminationDate"
                                                          )
                                                        : t(
                                                              "animalDocuments.firstInseminationDate"
                                                          )
                                                }>
                                                {inseminationDate.date}
                                            </BasicInfoBoxItem>
                                            <BasicInfoBoxItem
                                                name={t(
                                                    "animalDocuments.plannedBirth"
                                                )}
                                                valueID={"parturition-date"}>
                                                {this.getPlannedParturition()}
                                            </BasicInfoBoxItem>
                                            <BasicInfoBoxItem
                                                name={t(
                                                    "animalDocuments.activeNipples"
                                                )}>
                                                {this.getLastActiveNipples()}
                                            </BasicInfoBoxItem>
                                        </Col>
                                        <Col md={6}>
                                            <BasicInfoBoxItem
                                                name={t("technologyGroup")}>
                                                {technologyGroup !== null
                                                    ? technologyGroup
                                                    : "-"}
                                            </BasicInfoBoxItem>
                                            <BasicInfoBoxItem
                                                name={this.getPigletsHeaderName(
                                                    pigletsData
                                                )}>
                                                <PigletsAmount
                                                    pigletsData={pigletsData}
                                                    t={t}
                                                />
                                            </BasicInfoBoxItem>
                                            <BasicInfoBoxItem
                                                name={t(
                                                    "animalDocuments.lastSeparationDate"
                                                )}>
                                                {this.getLastSeparationDate()}
                                            </BasicInfoBoxItem>
                                        </Col>
                                    </Row>
                                </fieldset>
                            )}

                            <fieldset className="fieldset">
                                <legend>{t("pedigree")}</legend>
                                <Row>
                                    <Col md={6}>
                                        <BasicInfoBoxItem
                                            name={t("tattooNumber")}>
                                            {geneticsInformation.TattooNumber}
                                        </BasicInfoBoxItem>
                                        {[
                                            AnimalTypes.SOW,
                                            AnimalTypes.RENOVATION_SOW,
                                        ].includes(animal.AnimalKind) && (
                                            <>
                                                <BasicInfoBoxItem
                                                    name={t("fatherNumber")}>
                                                    {geneticsInformation?.FatherAnmID ? (
                                                        <AnimalReferenceDocumentModalGrid
                                                            additionalClass={
                                                                "mb-1"
                                                            }
                                                            goBack
                                                            object={{
                                                                AnmID: geneticsInformation?.FatherAnmID,
                                                            }}
                                                        />
                                                    ) : (
                                                        geneticsInformation.FatherAnmNo1
                                                    )}
                                                </BasicInfoBoxItem>
                                                <BasicInfoBoxItem
                                                    name={t("fatherTattoo")}>
                                                    {
                                                        geneticsInformation.FatherTattoo
                                                    }
                                                </BasicInfoBoxItem>
                                                <BasicInfoBoxItem
                                                    name={t("fatherRace")}>
                                                    <RaceComponent
                                                        raceName={
                                                            geneticsInformation?.FatherRaceName
                                                        }
                                                        raceColor={
                                                            geneticsInformation?.FatherRaceColor
                                                        }
                                                        raceCode={
                                                            geneticsInformation?.FatherRaceCode
                                                        }
                                                    />
                                                </BasicInfoBoxItem>
                                            </>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        <BasicInfoBoxItem name={t("race")}>
                                            <RaceComponent
                                                raceName={RaceName}
                                                raceCode={RaceCode}
                                                raceColor={RaceColor}
                                            />
                                        </BasicInfoBoxItem>
                                        {[
                                            AnimalTypes.SOW,
                                            AnimalTypes.RENOVATION_SOW,
                                        ].includes(animal.AnimalKind) && (
                                            <>
                                                <BasicInfoBoxItem
                                                    name={t("motherNumber")}>
                                                    {geneticsInformation?.MotherAnmID ? (
                                                        <AnimalReferenceDocumentModalGrid
                                                            additionalClass={
                                                                "mb-1"
                                                            }
                                                            goBack
                                                            object={{
                                                                AnmID: geneticsInformation?.MotherAnmID,
                                                            }}
                                                        />
                                                    ) : (
                                                        geneticsInformation.MotherAnmNo1
                                                    )}
                                                </BasicInfoBoxItem>
                                                <BasicInfoBoxItem
                                                    name={t("motherTattoo")}>
                                                    {
                                                        geneticsInformation.MotherTattoo
                                                    }
                                                </BasicInfoBoxItem>
                                                <BasicInfoBoxItem
                                                    name={t("motherRace")}>
                                                    <RaceComponent
                                                        raceName={
                                                            geneticsInformation?.MotherRaceName
                                                        }
                                                        raceColor={
                                                            geneticsInformation?.MotherRaceColor
                                                        }
                                                        raceCode={
                                                            geneticsInformation?.MotherRaceCode
                                                        }
                                                    />
                                                </BasicInfoBoxItem>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                {showEdit && (
                                    <Button
                                        buttonStyle="round"
                                        icon={
                                            <i className="fas fa-pencil-alt" />
                                        }
                                        onClick={this.onEditAnimalGenetics}
                                        className="basic-info-box-edit"
                                    />
                                )}
                            </fieldset>
                            {isTOPIGSFarm &&
                                [
                                    AnimalTypes.SOW,
                                    AnimalTypes.RENOVATION_SOW,
                                ].includes(animal.AnimalKind) && (
                                    <TOPIGSFieldSet>
                                        {/*TODO - Wstępna integracja z TOPIGS. Na ten moment nie mamy skąd tych parametrów pobrać.*/}
                                        <Row>
                                            <Col md={6}>
                                                <BasicInfoBoxItem
                                                    className={"topigs"}
                                                    name={t("tsi")}>
                                                    {"-"}
                                                </BasicInfoBoxItem>
                                                <BasicInfoBoxItem
                                                    className={"topigs"}
                                                    name={t("productionCode")}>
                                                    {"-"}
                                                </BasicInfoBoxItem>
                                            </Col>
                                            <Col md={6}>
                                                <BasicInfoBoxItem
                                                    className={"topigs"}
                                                    name={t("indexDate")}>
                                                    {"-"}
                                                </BasicInfoBoxItem>
                                                <BasicInfoBoxItem
                                                    className={"topigs"}
                                                    name={t(
                                                        "productionPurpose"
                                                    )}>
                                                    {"-"}
                                                </BasicInfoBoxItem>
                                            </Col>
                                        </Row>
                                    </TOPIGSFieldSet>
                                )}
                            <Button
                                type="button"
                                onClick={this.openPrintModal}
                                buttonColor="info"
                                className={"print-display-none mt-2 float-end"}>
                                {t("grid.print1")}
                            </Button>
                            {roles.hasRole({
                                roles: [RoleTypes.USER_MANAGE],
                            }) && (
                                <Button
                                    className={
                                        "print-display-none mt-2 float-end"
                                    }
                                    onClick={this.goToLogs}
                                    buttonStyle={"bordered"}
                                    text={t("logsView.goToLogs")}
                                />
                            )}
                        </div>
                    </Collapse>
                </div>
                <ConfirmRemoveAnimal />
                <ConfirmResurrectAnimal />
            </Card>
        );
    }
}

BasicInfoBox.propTypes = {
    showEdit: PropTypes.bool,
};

BasicInfoBox.defaultProps = {
    showEdit: true,
};

export default compose(
    withTranslation(),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BasicInfoBox);
