export const MessageTypes = {
    REQUEST: "R",
    SMALL_RESPONSE: "a",
    BIG_RESPONSE: "A",
    LAMBDA: "L",
};

export const MessageCommands = {
    GET_FULL_DEV_STATE: "GET_FULL_DEV_STATE",
    GET_FULL_DEV_STATE_DELTA: "GET_FULL_DEV_STATE_DELTA",
    STOP_SENDING_DEV_STATE: "STOP_SENDING_DEV_STATE",
    GET_AGGREGATED_DATA: "GET_AGGREGATED_DATA",
    UPDATE_DEVICES: "TREE_UPDATE_DEVICES",
    UPDATE_SINGLE_DEVICE: "TREE_UPDATE_SINGLE_DEVICE",
    REMOVE_DEVICES: "TREE_DELETE_SINGLE_DEVICE",
    GET_DEVICES: "TREE_GET_DEVICES",
    GET_NRF_FEEDERS: "GET_NRF_FEEDERS",
    PING: "PING",
    AUTH_USER: "AUTH_USER",
    GET_FEED_STATE: "GET_FEED_STATE",
    STOP_FEED_STATE: "STOP_FEED_STATE",
    GET_FEED_STATE_DELTA: "GET_FEED_STATE_DELTA",
    GET_FEED_RFID_STATE: "GET_FEED_RFID_STATE",
    GET_FEED_RFID_STATE_DELTA: "GET_FEED_RFID_STATE_DELTA",
    STOP_FEED_RFID_STATE: "STOP_FEED_RFID_STATE",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    RUN_SCRIPT: "RUN_SCRIPT",
    REBOOT: "REBOOT",
    BACK_TO_AP: "BACK_TO_AP",
    SET_TIMEZONE: "SET_TIMEZONE",
    SET_STA: "SET_STA",
    RESET_FETURA: "RESET_FETURA",
};

export const TopicTypes = {
    ALARMS: "alarms",
    COMMANDS: "commands",
    RESPONSES: "responses",
    STATE: "state",
    AGGREGATED_DATA: "aggregatedData",
    AUTH: "auth",
    ALARM_STATE: "alarmState",
    RPI_RESPONSES: "rpiResponses",
};

export const DispenserDriverCommandTypes = {
    GET_CONFIGURATION: "GET_CONFIGURATION",
    SET_CONFIGURATION: "SET_CONFIGURATION",
    GET_PARAM_OUTS: "GET_PARAM_OUTS",
    SET_PARAM_OUTS: "SET_PARAM_OUTS",
    GET_BLOCK_OUTS: "GET_BLOCK_OUTS",
    SET_BLOCK_OUTS: "SET_BLOCK_OUTS",
    GET_RTC: "GET_RTC",
    SET_RTC: "SET_RTC",
    GET_MOTOR_OVERCURRENTS: "GET_MOTOR_OVERCURRENTS",
    SET_MOTOR_OVERCURRENTS: "SET_MOTOR_OVERCURRENTS",
    SET_FORCE_LATE_FEEDING: "SET_FORCE_LATE_FEEDING",
    GET_SKIP_DOSES: "GET_SKIP_DOSES",
    SET_SKIP_DOSES: "SET_SKIP_DOSES",
    GET_SCHEDULE: "GET_SCHEDULE",
    SET_SCHEDULE: "SET_SCHEDULE",
    GET_NUTRITION_CURVE: "GET_NUTRITION_CURVE",
    SET_NUTRITION_CURVE: "SET_NUTRITION_CURVE",
    START_MOTORS: "SET_START_MOTORS",
    STOP_MOTORS: "SET_STOP_MOTORS",
    GET_CONTROLLER_STATUS: "GET_CONTROLLER_STATUS",
    GET_NON_FEED_DETAILS: "GET_NON_FEED_DETAILS",
    GET_POWER_ON_EVENTS: "GET_POWER_ON_EVENTS",
    SET_CLEAR_POWER_ON_EVENTS: "SET_CLEAR_POWER_ON_EVENTS",
    GET_CLEAR_POE_ORDERS: "GET_CLEAR_POE_ORDERS",
    GET_SET_RTC_ORDERS: "GET_SET_RTC_ORDERS",
    SET_CLEAR_FEED_PROBLEM: "SET_CLEAR_FEED_PROBLEM",
    SET_CLEAR_MOTOR_PROBLEM: "SET_CLEAR_MOTOR_PROBLEM",
    SET_HOLD_OUT: "SET_HOLD_OUT",
    GET_HOLD_OUT: "GET_HOLD_OUT",
    SET_FORCE_FEEDING: "SET_FORCE_FEEDING",
    FORMAT_FACTORY_CONFIGURATION: "SET_FORMAT_FACTORY_CONFIGURATION",
    GET_CONSUMPTION: "GET_CONSUMPTION",
    RESET_CONSUMPTION: "SET_RESET_CONSUMPTION",
    GET_DEBUG: "GET_DEBUG",
    GET_DEBUG_INDEX: "GET_DEBUG_INDEX",
    SET_SINGLE_SCHEDULE: "SET_SINGLE_SCHEDULE",
    ALERT: "Alert",
};

export const AlarmControlPanelCommandTypes = {
    GET_HMIS_CONFIGURATION: "GET_HMIS_CONFIGURATION",
    SET_HMIS_CONFIGURATION: "SET_HMIS_CONFIGURATION",
    SET_HMIS_SENSOR_CONF: "SET_HMIS_SENSOR_CONF",
    SET_HMIS_SILO_SCALE: "SET_HMIS_SILO_SCALE",
    SET_HMIS_SILO_TARE: "SET_HMIS_SILO_TARE",
    SET_HMIS_SILO_CONF: "SET_HMIS_SILO_CONF",
    SET_HMIS_CHAN_CONF: "SET_HMIS_CHAN_CONF",
    SET_HMIS_CLEAR_ALERT: "SET_HMIS_CLEAR_ALERT",
    SET_HMIS_CHAN_REALTIME_DATA: "SET_HMIS_CHAN_REALTIME_DATA",
    GET_HMIS_CHAN_REALTIME_DATA: "GET_HMIS_CHAN_REALTIME_DATA",
    SET_HMIS_CALL_MODE: "SET_HMIS_CALL_MODE",
    SET_HMIS_DEVICE_NUMBER: "SET_HMIS_DEVICE_NUMBER",
    SET_HMIS_RUN_UPDATE: "SET_HMIS_RUN_UPDATE",
};

export const SeparationCageCommandTypes = {
    SET_RTC: "SET_RTC",
    GET_RTC: "GET_RTC",
    SIMULATE_WEIGHTS: "SET_SIMULATE_WEIGHTS",
    GET_CURRENT_DATA: "GET_CURRENT_DATA",
    GET_EXPEDITION_ANIMAL_COUNT: "GET_EXPEDITION_ANIMAL_COUNT",
    GET_SEPARATION_ANIMALS_NUMBER: "GET_SEPARATION_ANIMALS_NUMBER",
    GET_WEIGHT: "GET_WEIGHT",
    GET_SENSORS_AND_VALVES: "GET_SENSORS_AND_VALVES",
    GET_DATE: "GET_DATE",
    GET_WORK_TYPE: "GET_WORK_TYPE",
    GET_DEFAULT_EXIT: "GET_DEFAULT_EXIT",
    GET_DEFAULT_EXIT_EXPEDITION: "GET_DEFAULT_EXIT_EXPEDITION",
    GET_WORK_TYPE_DURATION: "GET_WORK_TYPE_DURATION",
    GET_EXPEDITION_WORK_TYPE: "GET_EXPEDITION_WORK_TYPE",
    GET_EXPEDITION_WEIGHT_RANGE: "GET_EXPEDITION_WEIGHT_RANGE",
    GET_EXPEDITION_COUNTER: "GET_EXPEDITION_COUNTER",
    GET_SEPARATION_COUNTER: "GET_SEPARATION_COUNTER",
    GET_SEPARATION_WEIGHT_RANGE: "GET_SEPARATION_WEIGHT_RANGE",
    GET_SCALE_FACTORS: "GET_SCALE_FACTORS",
    GET_AVERAGE_WEIGHT_CLOSED: "GET_AVERAGE_WEIGHT_CLOSED",
    SET_WORK_TYPE: "SET_WORK_TYPE",
    SET_CA_WORK_TYPE: "SET_CA_WORK_TYPE",
    SET_WORK_TYPE_DURATION: "SET_WORK_TYPE_DURATIONS",
    SET_VALVE: "SET_VALVE",
    RESET: "SET_RESET",
    SET_DEFAULT_EXIT: "SET_DEFAULT_EXIT",
    SET_DEFAULT_EXIT_EXPEDITION: "SET_DEFAULT_EXIT_EXPEDITION",
    SET_EXPEDITION_ANIMALS_NUMBER: "SET_EXPEDITION_ANIMALS_NUMBER",
    SET_EXPEDITION_WEIGHT_RANGE: "SET_EXPEDITION_WEIGHT_RANGE",
    SET_EXPEDITION_WORK_TYPE: "SET_EXPEDITION_WORK_TYPE",
    SET_SEPARATION_WEIGHT_RANGE: "SET_SEPARATION_WEIGHT_RANGE",
    SET_SEPARATION_ANIMAL_COUNT: "SET_SEPARATION_ANIMALS_NUMBER",
    SET_SCALE: "SET_SCALE",
    SET_SCALE_FACTOR: "SET_SCALE_FACTORS",
    CLEAR_INSERTION_DATA: "SET_CLEAR_INSERTION_DATA",
    GET_ERRORS: "GET_ERRORS",
    GET_VISITS_IN_WEIGHTS_YESTERDAY: "GET_VISITS_IN_WEIGHTS_YESTERDAY",
    GET_AVERAGE_WEIGHT_ALL_EXITS_YESTERDAY:
        "GET_AVERAGE_WEIGHT_ALL_EXITS_YESTERDAY",
    GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT1:
        "GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT1",
    GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT3:
        "GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT3",
    GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT2:
        "GET_DAILY_VISITS_NUMBER_YESTERDAY_EXIT2",
    GET_VISITS_NUMBER_YESTERDAY: "GET_VISITS_NUMBER_YESTERDAY",
    SET_INSERTED: "SET_INSERTED",
    GET_ISMAD: "GET_ISMAD",
    GET_DAILY_VISITS_NUMBER_NOW: "GET_DAILY_VISITS_NUMBER_NOW",
    GET_DAILY_VISITS_NUMBER_NOW_EXIT1: "GET_DAILY_VISITS_NUMBER_NOW_EXIT1",
    GET_DAILY_VISITS_NUMBER_NOW_EXIT3: "GET_DAILY_VISITS_NUMBER_NOW_EXIT3",
    GET_DAILY_VISITS_NUMBER_NOW_EXIT2: "GET_DAILY_VISITS_NUMBER_NOW_EXIT2",
    GET_RETURN_TO_DEFAULT_EXIT: "GET_RETURN_TO_DEFAULT_EXIT",
    SET_RETURN_TO_DEFAULT_EXITL: "SET_RETURN_TO_DEFAULT_EXIT",
    GET_ATTEMPTS_NUMBER: "GET_ATTEMPTS_NUMBER",
    SET_ATTEMPTS_NUMBER: "SET_ATTEMPTS_NUMBER",
    GET_EXIT_TIME: "GET_EXIT_TIME",
    SET_EXIT_TIME: "SET_EXIT_TIME",
    GET_TARE_STATUS: "GET_TARE_STATUS",
    SET_TARE_STATUS: "SET_TARE_STATUS",
    DO_TARE: "SET_DO_TARE",
    RESET_DAILY_VISITS: "SET_RESET_DAILY_VISITS",
    SET_AVERAGE_HERD_WEIGHT: "SET_AVERAGE_HERD_WEIGHT",
    SET_NUMBERS_SEND_TO_EXITS: "SET_NUMBERS_SEND_TO_SIDE_EXITS",
    SET_AVERAGE_WEIGHT_YESTERDAY: "SET_AVERAGE_WEIGHT_YESTERDAY",
    SET_MARKING_CONFIG: "SET_MARKING_CONFIG",
    SET_RESET_DYE_USAGE: "SET_RESET_DYE_USAGE",
    SET_EXPEDITION_DATA: "SET_EXPEDITION_DATA",
    SET_CLEAR_ERRORS: "SET_CLEAR_ERRORS",
    SET_WORK_TYPE_SEPARATION_WITH_PARAMS:
        "SET_WORK_TYPE_SEPARATION_WITH_PARAMS",
    SET_WORK_TYPE_EXPEDITION_WITH_PARAMS:
        "SET_WORK_TYPE_EXPEDITION_WITH_PARAMS",
    SET_WEIGHT_RANGE_ENTRANCE: "SET_WEIGHT_RANGE_ENTRANCE",
    SET_WEIGHT_RANGE_EXIT: "SET_WEIGHT_RANGE_EXIT",
    SET_PASS_BOUNDS_MODIFIER: "SET_PASS_BOUNDS_MODIFIER",
    SET_EXPEDITION: "SET_EXPEDITION",
    SET_STOP_EXPEDITION: "SET_STOP_EXPEDITION",
    SET_CA_AUTO_RESOLVE_ATTEMPTS_NUMBER: "SET_CA_AUTO_RESOLVE_ATTEMPTS_NUMBER",
    SET_MARKER_WORK_TYPE: "SET_MARKER_WORK_TYPE",
    SET_MARKER_SETTINGS: "SET_MARKER_SETTINGS",
    SET_CA_TARE_LIMIT: "SET_CA_TARE_LIMIT",
    SET_CA_UPS_STATUS: "SET_CA_UPS_STATUS",
    SET_CA_BUFFER_SIZE: "SET_CA_BUFFER_SIZE",
    SET_SCALE_CALCULATED: "SET_SCALE_CALCULATED",
    SET_RESET_CA_FACTORY_SETTINGS: "SET_RESET_CA_FACTORY_SETTINGS",
    SET_DATA_SAVE_INTERVAL: "SET_DATA_SAVE_INTERVAL",
    SET_TARE_VALUE: "SET_TARE_VALUE",
    SET_CA_TIMEOUT_TIME: "SET_CA_TIMEOUT_TIME",
    SET_CA_NUMBER_OF_MEASUREMENTS: "SET_CA_NUMBER_OF_MEASUREMENTS",
    SET_CA_MEASURING_LIMIT_MIN: "SET_CA_MEASURING_LIMIT_MIN",
    SET_CA_MEASURING_LIMIT_MAX: "SET_CA_MEASURING_LIMIT_MAX",
    SET_CA_SORTING_VALUE: "SET_CA_SORTING_VALUE",
};

export const ClimateDriverCommandTypes = {
    SET_CONFIGURATION: "SET_CONFIGURATION",
    PING: "SET_PING",
    GET_CONFIGURATION: "GET_CONFIGURATION",
    GET_AMBIENT_CONDITIONS: "GET_AMBIENT_CONDITIONS",
    GET_CLOCK_MENU: "GET_CLOCK_MENU",
    SET_CLOCK_MENU: "SET_CLOCK_MENU",
    GET_SERVICE_MENU: "GET_SERVICE_MENU",
    SET_SERVICE_MENU: "SET_SERVICE_MENU",
};

export const ScaleHubCommandTypes = {
    GET_WEIGHT_CALCULATED: "GET_WEIGHT_CALCULATED",
    GET_CONFIGURATION: "GET_CONFIGURATION",
    SET_CONFIGURATION: "SET_CONFIGURATION",
    GET_WEIGHT_RAW: "GET_WEIGHT_RAW",
    GET_TARES_SCALES: "GET_TARES_SCALES",
    SET_TARE: "SET_TARE",
    SET_SCALE: "SET_SCALE",
    PING: "SET_PING",
};

export const TerminalCommandTypes = {
    TEST_RAW_DATA: "TEST_RAW_DATA",
    TEST_RAW_DATA_CRC: "TEST_RAW_DATA_CRC",
    TEST_RAW_DATA_ASCII: "TEST_RAW_DATA_ASCII",
};

export const DispenserNRFCommandTypes = {
    GET_CONFIGURATION: "GET_CONFIGURATION",
    SET_WORK_TYPE: "SET_WORK_TYPE",
    SET_MOTOR_SETUP: "SET_MOTOR_SETUP",
    SET_SCHEDULE: "SET_SCHEDULE",
    SET_NUTRITION_CURVE: "SET_NUTRITION_CURVE",
    GET_LOGS: "GET_LOGS",
    GET_MOTOR_SETUP: "GET_MOTOR_SETUP",
    GET_NUTRITION_CURVE: "GET_NUTRITION_CURVE",
    GET_SCHEDULE: "GET_SCHEDULE",
    GET_FEED_EFC_TIME: "GET_FEED_EFC_TIME",
    GET_FEED_EFC_IMPULSE: "GET_FEED_EFC_IMPULSE",
    GET_HISTORY_STANDARD: "GET_HISTORY_STANDARD",
    GET_WORK_TYPE: "GET_WORK_TYPE",
    DELETE_LOGS: "SET_DELETE_LOGS",
    START_MOTORS: "SET_START_MOTORS",
    STOP_MOTORS: "SET_STOP_MOTORS",
    SET_FEED_EFC_TIME: "SET_FEED_EFC_TIME",
    SET_FEED_EFC_IMPULSE: "SET_FEED_EFC_IMPULSE",
    SET_CONFIG_STANDARD: "SET_CONFIG_STANDARD",
    ADD_PIG: "SET_ADD_PIG",
    GET_PIG: "GET_PIG",
    DELETE_PIG: "SET_DELETE_PIG",
    SET_LOCK: "SET_LOCK",
    SET_ALERT: "SET_ALERT",
    GET_DAILY_USAGE: "GET_DAILY_USAGE",
    GET_DAILY_USAGE_DELTA: "GET_DAILY_USAGE_DELTA",
    SET_CHAMBER_DEVICES: "SET_CHAMBER_DEVICES",
    SET_TYPE_EVENT: "SET_TYPE_EVENT",
    SET_SYNC_DEVICES: "SET_SYNC_DEVICES",
    GET_UPLOAD_SOFTWARE_STATUS: "GET_UPLOAD_SOFTWARE_STATUS",
    GET_CURVE_CRC: "GET_CURVE_CRC",
    GET_SCHEDULE_CRC: "GET_SCHEDULE_CRC",
    GET_CAN_STATUS: "GET_CAN_STATUS",
    SET_SKIP_DOSES: "SET_SKIP_DOSES",
    GET_SKIP_DOSES: "GET_SKIP_DOSES",
    SET_MINUS_CONFIG: "SET_MINUS_CONFIG",
    SET_FORCE_FEEDING: "SET_FORCE_FEEDING",
    SET_SWITCH_MOTOR_ON_INFO: "SET_SWITCH_MOTOR_ON_INFO",
    SET_CLEAR_FRAM: "SET_CLEAR_FRAM",
    SET_NRF_POWER: "SET_NRF_POWER",
    SET_CLEAR_HISTORY: "SET_CLEAR_HISTORY",
    SET_CONTROL_BOOTLOADER: "SET_CONTROL_BOOTLOADER",
    GET_FRAM_RAM_STATUS_CRC: "GET_FRAM_RAM_STATUS_CRC",
    SET_INSEMINATION_PARTURITION: "SET_INSEMINATION_PARTURITION",
    SET_WATER_EFFICIENCY: "SET_WATER_EFFICIENCY",
    SET_ADDITIONAL_WATER: "SET_ADDITIONAL_WATER",
    SET_FEEDING_EFFICIENCY: "SET_FEEDING_EFFICIENCY",
    SET_ANIMAL_MODIFICATION: "SET_ANIMAL_MODIFICATION",
    SET_SENSOR_SCHEDULE: "SET_SENSOR_SCHEDULE",
    SET_SENSOR_CONFIGURATION: "SET_SENSOR_CONFIGURATION",
    GET_SERIAL_NUMBER: "GET_SERIAL_NUMBER",
    SET_SWAP_SUBADDRESSES: "SET_SWAP_SUBADDRESSES",
    SET_RFID_SENSOR_CONFIGURATION: "SET_RFID_SENSOR_CONFIGURATION",
    SET_SERIAL_NUMBER: "SET_SERIAL_NUMBER",
    SET_LED_COLOUR: "SET_LED_COLOUR",
    SET_CONFIG_SCALES_V2: "SET_CONFIG_SCALES_V2",
    SET_SCALE_MANUAL_SCALES_V2: "SET_SCALE_MANUAL_SCALES_V2",
    SET_AUTO_TARE_PARAM_SCALES_V2: "SET_AUTO_TARE_PARAM_SCALES_V2",
    SET_TARE_MANUAL_SCALES_V2: "SET_TARE_MANUAL_SCALES_V2",
    SET_MSE_AVG_WIDTH_SCALES_V2: "SET_MSE_AVG_WIDTH_SCALES_V2",
    SET_PIG_EXIT_UPINFO_SCALES_V2: "SET_PIG_EXIT_UPINFO_SCALES_V2",
    SET_CLEAR_BUFFER_WITH_MEASURMENTS_SCALES_V2:
        "SET_CLEAR_BUFFER_WITH_MEASURMENTS_SCALES_V2",
    SET_SCALE_WEIGHT_SCALES_V2: "SET_SCALE_WEIGHT_SCALES_V2",
    GET_IPSUM_PASSES: "GET_IPSUM_PASSES_V2",
    GET_IPSUM_DETAILS_PASSES: "GET_IPSUM_DETAILS_PASSES",
    SET_WEIGHT_IPSUM_PASSES: "SET_WEIGHT_IPSUM_PASSES",
    GET_IPSUM_RFIDS: "GET_IPSUM_RFIDS",
    GET_IPSUM_RFID_INFO: "GET_IPSUM_RFID_INFO",
    SET_STOP_IPSUM_MOTOR_2_V2: "SET_STOP_IPSUM_MOTOR_2_V2",
    SET_START_IPSUM_MOTOR_2_V2: "SET_START_IPSUM_MOTOR_2_V2",
    SET_MINIMAL_WORK_TIME_IPSUM_V2: "SET_MINIMAL_WORK_TIME_IPSUM_V2",
    SET_IPSUM_EFFICIENCY_V2: "SET_IPSUM_EFFICIENCY_V2",
    SET_IPSUM_CONFIGURATION_V2: "SET_IPSUM_CONFIGURATION_V2",
    SET_IPSUM_CURVE_START_V2: "SET_IPSUM_CURVE_START_V2",
    SET_IPSUM_SCHEDULE_V2: "SET_IPSUM_SCHEDULE_V2",
    SET_IPSUM_CURVE_V2: "SET_IPSUM_CURVE_V2",
    SET_IPSUM_MAXIMUM_CONTINUOUS_DOSE_ALARM_V2:
        "SET_IPSUM_MAXIMUM_CONTINUOUS_DOSE_ALARM_V2",
};

export const GatawayCommandTypes = {
    START_ADDRESSING: "ADDR_START_ADDRESSING",
    START_ADR_WST: "START_ADR_WST",
    STOP_ADR_WST: "STOP_ADR_WST",
    START_ADDRESSING_NRF: "START_ADDRESSING_NRF",
    STOP_ADDRESSING_NRF: "STOP_ADDRESSING_NRF",
    GET_GATEWAY_TIME: "GET_GATEWAY_TIME",
    OPEN_SSH: "OPEN_SSH",
    PING: "GET_PING",
    GET_NRF_STATS: "GET_NRF_STATS",
    SET_PLCMNTS: "SET_PLCMNTS",
    ADDRESS_DELTA: "ADDRESS_DELTA",
    GET_SCHEMA: "GET_SCHEMA",
    ADDR_START_SUBADDRESSING_NRF: "ADDR_START_SUBADDRESSING_NRF",
    GET_QUEUES_LAST_SUCCESS: "GET_QUEUES_LAST_SUCCESS",
    GET_PIGS_DATA: "GET_PIGS_DATA",
    GET_ASCII_CLIMATE_TESTING_DATA: "GET_ASCII_CLIMATE_TESTING_DATA",
    SET_SCHEDULE_OUTPUT: "SET_SCHEDULE_OUTPUT",
    SET_CLEAR_PROBLEM: "SET_CLEAR_PROBLEM",
    SET_CHANGE_DISPENSERS_DOSE_CORRECTION:
        "SET_CHANGE_DISPENSERS_DOSE_CORRECTION",
    SET_DISPENSERS_DOSE_CORRECTION: "SET_DISPENSERS_DOSE_CORRECTION",
    SET_SKIP_DOSES_IN_TIME: "SET_SKIP_DOSES_IN_TIME",
    GET_ALL_ONGOING_ALARMS: "GET_ALL_ONGOING_ALARMS",
    GET_ALL_ALARMS: "GET_ALL_ALARMS",
    GET_USAGE_FOR_PIG: "GET_USAGE_FOR_PIG",
    SET_ANIMAL_CHANGES: "SET_ANIMAL_CHANGES",
    SET_NEW_INSERTION: "SET_NEW_INSERTION",
    GET_FEEDING_DATA_FOR_PIG: "GET_FEEDING_DATA_FOR_PIG",
    GET_FEEDING_DATA_FOR_PIG_SYNC: "GET_FEEDING_DATA_FOR_PIG_SYNC",
    SET_CONFIRM_ALARM: "SET_CONFIRM_ALARM",
    SET_CLIMATE_CURVE: "SET_CLIMATE_CURVE",
    GET_ALL_ONGOING_ALARMS_BY_CODE: "GET_ALL_ONGOING_ALARMS_BY_CODE",
    GET_ALL_ALARMS_BY_CODE: "GET_ALL_ALARMS_BY_CODE",
    GET_DEVICE_LOGS: "GET_DEVICE_LOGS",
    SET_SCHEDULE_OUTPUT_MODE: "SET_SCHEDULE_OUTPUT_MODE",
    SET_KEEP_NRF_IN_BOOT: "SET_KEEP_NRF_IN_BOOT",
    SEARCH_ETH_DEVICES: "SEARCH_ETH_DEVICES",
    FOUND_ETH_DEVICE_DELTA: "FOUNDED_ETH_DEVICE_DELTA",
    GET_PARAM_ETH_DEVICE: "GET_PARAM_ETH_DEVICE",
    SET_BASIC_PARAM_ON_ETH_DEVICE: "SET_BASIC_PARAM_ON_ETH_DEVICE",
    VERIFY_NRF_SERIAL_NUMBERS: "VERIFY_NRF_SERIAL_NUMBERS",
    SAVE_NRF_SERIAL_NUMBERS: "SAVE_NRF_SERIAL_NUMBERS",
    RESTORE_NRF_SERIAL_NUMBERS: "RESTORE_NRF_SERIAL_NUMBERS",
    GET_GW_ALERT_COUNTER: "GET_GW_ALERT_COUNTERS",
    GET_GW_ALERTS_UNGROUPED: "GET_GW_ALERTS_UNGROUPED",
    SET_PORT_PARAM_ON_ETH_DEVICE: "SET_PORT_PARAM_ON_ETH_DEVICE",
    SET_GW_SEND_BINARY_PROGRAM: "SET_GW_SEND_BINARY_PROGRAM",
    GET_UPLOAD_STATUS: "GET_UPLOAD_STATUS",
    SET_START_UPLOAD_BINARY: "SET_START_UPLOAD_BINARY",
    GET_PROGRAM_DETAILS: "GET_PROGRAM_DETAILS",
    GET_UPLOAD_STATUS_DELTA: "GET_UPLOAD_STATUS_DELTA",
    GET_GW_ALERT_COUNTERS_CATEGORIES: "GET_GW_ALERT_COUNTERS_CATEGORIES",
    GET_DATA_BY_LOCATION: "GET_DATA_BY_LOCATION",
    GET_DATA_BY_LOCATION_DELTA: "GET_DATA_BY_LOCATION_DELTA",
    SET_PARAM_ON_ETH_DEVICE: "SET_PARAM_ON_ETH_DEVICE",
    GET_RFID_PIG_HISTORY: "GET_RFID_PIG_HISTORY",
    GET_GATEWAY: "GET_GATEWAY",
    SET_FEEDERS_CALIBRATION: "SET_FEEDERS_CALIBRATION",
    GET_RPI_IP_ADDRESSES: "GET_RPI_IP_ADDRESSES",
    SET_CONFIRM_SUBADDRESS_PROCESS: "SET_CONFIRM_SUBADDRESS_PROCESS",
    GET_IPSUM_DATA: "GET_IPSUM_DATA",
    GET_IPSUM_DETAILS: "GET_IPSUM_DETAILS",
    GET_SETTLEMENTS: "GET_SETTLEMENTS",
    SET_CONFIRM_MBUS_WATER_SWAP: "SET_CONFIRM_MBUS_WATER_SWAP",
    SET_SAVE_OFFLINE_SETTINGS: "SET_SAVE_OFFLINE_SETTINGS",
    GET_OFFLINE_AGGREGATED_DATA: "GET_OFFLINE_AGGREGATED_DATA",
    OFFLINE_SYNC_DEVICE: "OFFLINE_SYNC_DEVICE",
    SET_OFFLINE_FORCE_GLOBAL_SYNC: "SET_OFFLINE_FORCE_GLOBAL_SYNC",
    SET_AUTO_TARE_SILO: "SET_AUTO_TARE_SILO",
    GET_NON_EATING_PIGS: "GET_NON_EATING_PIGS",
    GET_DEVICES_COMMUNICATION_STATUS: "GET_DEVICES_COMMUNICATION_STATUS",
};

export const VehicleWeightCommandTypes = {
    SET_R320_TARE: "SET_R320_TARE",
    SET_RESET_WEIGHTING: "SET_RESET_WEIGHTING",
};

export const ClimateCommandTypes = {
    SET_CLIMATE_WATER_MATS: "SET_CLIMATE_WATER_MATS",
    SET_CLIMATE_MAT_POWER: "SET_CLIMATE_MAT_POWER",
    SET_CLIMATE_MAT_CALIBRATION: "SET_CLIMATE_MAT_CALIBRATION",
    SET_CLIMATE_WORK_TYPE: "SET_CLIMATE_WORK_TYPE",
    SET_CLIMATE_ALARMS: "SET_CLIMATE_ALARMS",
    SET_CLIMATE_VENTILATION_SETTINGS: "SET_CLIMATE_VENTILATION_SETTINGS",
    SET_CLIMATE_CO2_SETTINGS: "SET_CLIMATE_CO2_SETTINGS",
    SET_MAT_MENU: "SET_MAT_MENU",
    SET_CLIMATE_HEATING: "SET_CLIMATE_HEATING",
    SET_CLIMATE_COOLING: "SET_CLIMATE_COOLING",
    SET_CLIMATE_VENTILATION: "SET_CLIMATE_VENTILATION",
    SET_CLIMATE_FLAPS: "SET_CLIMATE_FLAPS",
    SET_CLIMATE_THROTTLE: "SET_CLIMATE_THROTTLE",
    SET_CLIMATE_SENSORS_CALIBRATION: "SET_CLIMATE_SENSOR_CALIBRATION",
    SET_CLIMATE_SINGLE_MAT: "SET_CLIMATE_SINGLE_MAT",
    SET_BANDWIDTH: "SET_BANDWIDTH",
    SET_REQUESTED_TEMPERATURE: "SET_REQUESTED_TEMPERATURE",
    SET_REQUESTED_TEMPERATURE_DEVIATION: "SET_REQUESTED_TEMPERATURE_DEVIATION",
    SET_HEATING_DEVIATION: "SET_HEATING_DEVIATION",
    SET_MINIMUM_TEMPERATURE_ALARM_DEVIATION:
        "SET_MINIMUM_TEMPERATURE_ALARM_DEVIATION",
    SET_MAXIMUM_TEMPERATURE_ALARM_DEVIATION:
        "SET_MAXIMUM_TEMPERATURE_ALARM_DEVIATION",
    SET_ABSOLUTE_ALARM: "SET_ABSOLUTE_ALARM",
    SET_MINIMUM_VENTILATION: "SET_MINIMUM_VENTILATION",
    SET_MAXIMUM_VENTILATION: "SET_MAXIMUM_VENTILATION",
    SET_MINIMUM_VENTILATION_DEVIATION: "SET_MINIMUM_VENTILATION_DEVIATION",
    SET_COOLING_ENABLE_TEMPERATURE: "SET_COOLING_ENABLE_TEMPERATURE",
    SET_COOLING_WORK_TIME: "SET_COOLING_WORK_TIME",
    SET_COOLING_BREAK_TIME: "SET_COOLING_BREAK_TIME",
    SET_COOLING_MAXIMUM_HUMIDITY: "SET_COOLING_MAXIMUM_HUMIDITY",
    SET_COOLING_MINIMUM_PRESSURE: "SET_COOLING_MINIMUM_PRESSURE",
    SET_COOLING_MAXIMUM_PRESSURE: "SET_COOLING_MAXIMUM_PRESSURE",
    SET_MANUAL_VENTILATION: "SET_MANUAL_VENTILATION",
    SET_DEVICE_ADDRESS: "SET_DEVICE_ADDRESS",
    SET_OBJECT_DYNAMICS: "SET_OBJECT_DYNAMICS",
    SET_CHIMNEY_VENTILATORS_COUNT: "SET_CHIMNEY_VENTILATORS_COUNT",
    SET_MASTERS_COUNT: "SET_MASTERS_COUNT",
    SET_THROTTLE_VOLTAGE_MIN: "SET_THROTTLE_VOLTAGE_MIN",
    SET_THROTTLE_VOLTAGE_MAX: "SET_THROTTLE_VOLTAGE_MAX",
    SET_FLAPS_VOLTAGE_MIN: "SET_FLAPS_VOLTAGE_MIN",
    SET_FLAPS_VOLTAGE_MAX: "SET_FLAPS_VOLTAGE_MAX",
    SET_HEATING_BREAK_TIME: "SET_HEATING_BREAK_TIME",
    SET_HEATING_WORK_TIME: "SET_HEATING_WORK_TIME",
    SET_VENTILATION_DELAY: "SET_VENTILATION_DELAY",
    SET_MINIMUM_ROTATION: "SET_MINIMUM_ROTATION",
    SET_TEMPERATURE_SENSORS_CALIBRATION_1:
        "SET_TEMPERATURE_SENSORS_CALIBRATION_1",
    SET_TEMPERATURE_SENSORS_CALIBRATION_2:
        "SET_TEMPERATURE_SENSORS_CALIBRATION_2",
    SET_TEMPERATURE_SENSORS_CALIBRATION_3:
        "SET_TEMPERATURE_SENSORS_CALIBRATION_3",
    SET_TEMPERATURE_SENSORS_CALIBRATION_4:
        "SET_TEMPERATURE_SENSORS_CALIBRATION_4",
    SET_TEMPERATURE_SENSORS_CALIBRATION_5:
        "SET_TEMPERATURE_SENSORS_CALIBRATION_5",
    SET_VENTILATION_CYCLE_TIME: "SET_VENTILATION_CYCLE_TIME",
    SET_VENTILATION_INTENSITY: "SET_VENTILATION_INTENSITY",
    GET_CLOCK_MENU: "GET_CLOCK_MENU",
    SET_CLIMATE_NH3: "SET_CLIMATE_NH3",
    SET_CL_PIN: "SET_CL_PIN",
    SET_LIFT_TYPE: "SET_LIFT_TYPE",
    GET_AMBIENT_CONDITIONS: "GET_AMBIENT_CONDITIONS",
    SET_CLIMATE_CURTAIN_LIMITS: "SET_CLIMATE_CURTAIN_LIMITS",
    SET_CLEAR_CLIMATE_ALARMS: "SET_CLEAR_CLIMATE_ALARMS",
    SET_CL_LIFT_WORK_TYPE: "SET_CL_LIFT_WORK_TYPE",
    SET_CL_RELAY_WATER_VALVE: "SET_CL_RELAY_WATER_VALVE",
};

export const BridgeCommandTypes = {
    SET_BEACON_ON: "SET_B_NRF_BEACON_ON",
    SET_BEACON_OFF: "SET_B_NRF_BEACON_OFF",
    SET_CHANNEL: "SET_B_NRF_CH_W",
    GET_CHANNEL: "GET_B_NRF_CH_R",
    SET_POWER: "SET_B_NRF_POWER_W",
    GET_POWER: "GET_B_NRF_POWER_R",
    GET_MBUS_CONNECTION: "GET_B_M_CNF_R",
    GET_MBUS_DEBUG: "GET_B_M_CHECK",
    GET_MBUS_DEBUG_RX: "GET_B_M_CHECK_RX",
    GET_MBUS_CHANNEL: "GET_B_M_CNF_R",
    SET_TOUT: "SET_B_485_TOUT_W",
    GET_TOUT: "GET_B_485_TOUT_R",
    B_INFO: "GET_B_INFO",
    B_BOOT_INFO: "GET_B_BOOT_INFO",
    B_BOOT_INFO_NRF: "GET_B_BOOT_INFO_NRF",
    B_NRF_STAT: "GET_B_NRF_STAT",
    B_485_TOUT_R: "GET_B_485_TOUT_R",
    B_GET_SCAN_ADDR: "GET_B_GET_SCAN_ADDR",
    SET_B_SEND_DOZO_PRG: "SET_B_SEND_DOZO_PRG",
};

export const DispenserNRFEventTypes = {
    NO_EVENT: 0,
    INSEMINATION: 1,
    PARTURITION: 2,
};

export const ChainFeedingCommandTypes = {
    SET_START_STOP_MOTOR: "SET_START_STOP_MOTOR",
    SET_CLEAR_ALARM: "SET_CLEAR_ALARM",
    SET_SCHEDULE: "SET_SCHEDULE",
    SET_SCHEDULE_ANALOG: "SET_SCHEDULE_ANALOG",
    SET_WORK_TYPE: "SET_WORK_TYPE",
    SET_SENSOR_WORK_TYPE: "SET_SENSOR_WORK_TYPE",
};

export const ElectricityMeterCommandTypes = {
    SET_RELAY_STATE: "SET_RELAY_STATE",
};

export const RelayCommandTypes = {
    SET_SINGLE_RELAY: "SET_SINGLE_RELAY",
};

export const UniversalBoardCommandTypes = {
    U_WRITE_RAM: "SET_WRITE_RAM",
    U_READ_RAM: "GET_READ_RAM",
};
