import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {getFallList} from "../../../selectors/animalDocumentsSelectors";
import {localMomentToUTC} from "../../../utils/DateTimeUtils";
import useAnimalGroupInfo from "../ideal-charts/useAnimalGroupInfo";
import useDomain from "../ideal-charts/useDomain";
import useReferenceLines from "../ideal-charts/useReferenceLines";
import {getFallReasons} from "../../../selectors/dictionarySelectors";
import FallChart from "./FallChart";

const FallGroupChart = () => {
    const groupInfo = useAnimalGroupInfo();

    const fallEvents = useSelector(getFallList);

    const fallReasons = useSelector(getFallReasons);

    const domain = useDomain();

    const referenceLines = useReferenceLines(domain, "lineAxis");

    const fallDict = useMemo(() => {
        const dictionary = {};
        for (const event of fallEvents) {
            const utcTIME = +localMomentToUTC(event?.EvTime).startOf("day");
            if (!dictionary[utcTIME]) dictionary[utcTIME] = [];
            const reason =
                fallReasons.WData.find(
                    (item) => item.ID === event?.EvData?.Reasn
                )?.Value || event?.EvData?.Reasn;

            dictionary[utcTIME].push({
                [reason]: event?.EvData?.Weight || 0,
                weight: event?.EvData?.Weight || 0,
                AnmCnt: event?.EvData?.AnmCnt || event?.AnmCnt || 1,
                Reason: reason,
                AnmID: event?.AnmID,
            });
        }
        return dictionary;
    }, [fallEvents, fallReasons]);

    return (
        <FallChart
            fallDict={fallDict}
            chartDomain={domain}
            referenceLines={referenceLines}
            {...groupInfo}
        />
    );
};

export default React.memo(FallGroupChart);
