import store from "../store/store";

export default class Paths {
    /*
    ######################################
    #  PREFERENCES
    ######################################
    */
    static getPreferences({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/preferences/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createPreference({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/preferences/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static updatePreference({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/preferences/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static sendTestNotificationToUser({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/testNotification/${LocalUserID}`,
            method: "POST",
        };
    }

    /*
    ######################################
    #  FEEDING CURVES
    ######################################
    */
    static createFeedingCurve({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/curve/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateFeedingCurve({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/curve/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static createNutriProCurve({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/nutriProCurve/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static updateNutriProCurve({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/nutriProCurve/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    /*
    ######################################
    #  FEEDING SCHEDULES
    ######################################
    */
    static createFeedingSchedule({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/schedule/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static updateFeedingSchedule({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/schedule/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    /*
     ######################################
     #  ROUTINES
     ######################################
     */

    static createRoutine({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/routine/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateRoutine({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/routine/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeRoutine({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/routine/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    /*
    ######################################
    #  SK 3 CLIMATE CURVES
    ######################################
    */
    static createClimateCurveSk3({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/climateCurve/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static updateClimateCurveSk3({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/climateCurve/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static deleteClimateCurveSk3({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/climateCurve/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    /*###########################################################################################################*/

    static saveEconomySettings({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/economy/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static saveHerdSettings({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/herd/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createLocation({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `buildings/buildings/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static pairingLocation({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `buildings/pairing/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static addRFID({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `buildings/addRFID/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static updateLocation({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `buildings/buildings/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static deleteLocation({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `buildings/buildings/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static listLocation({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `buildings/buildings/${farmID || farm}`,
            method: "GET",
        };
    }

    /**
     * @deprecated
     * @param farmID
     * @param clientID
     * @param localUserID
     * @return {{path: string, method: string}}
     */
    static createBuilding({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/building/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listBuilding({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/building/${farmID || farm}`,
            method: "GET",
        };
    }

    static updateBuilding({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        //console.log(farmID, clientID, localUserID, farm, LocalUserID, ClientID, 'PUT');
        return {
            path: `general/building/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static createAnimal({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/animal/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listAnimal({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/animal/${farmID || farm}`,
            method: "GET",
        };
    }

    static updateAnimal({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/animal/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeAnimal({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/animal/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static resurrectAnimal({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/resurrect/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static getHelpVideo({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/help/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static createEvent({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/events/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static sendEmail() {
        return {
            path: `general2/service/mailfwd`,
            method: "POST",
        };
    }

    static listEvent({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/events/${farmID || farm}`,
            method: "GET",
        };
    }

    static listEventsForAnimals() {
        return {
            path: `general/eventsForAnimals/`,
            method: "POST",
        };
    }

    static updateEvent({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/events/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static serviceSummary({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static movePointToNewPosition({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static getCommissioningProtocol({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static addPointToCommissioningProtocol({
        farmID,
        clientID,
        localUserID,
    } = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/point/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static editPointCommissioningProtocol({
        farmID,
        clientID,
        localUserID,
    } = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/point/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static removePointCommissioningProtocol({
        farmID,
        clientID,
        localUserID,
    } = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/point/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static addSectionToCommissioningProtocol({
        farmID,
        clientID,
        localUserID,
    } = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/section/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static getWorkingCopyProtocol({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/draft/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static saveWorkingCopyProtocol({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/draft/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static deleteAllWorkingCopiesProtocol({
        farmID,
        clientID,
        localUserID,
    } = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/service/draft/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static createDevices({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/device/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listDevices({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `devices/device/${farmID || farm}`,
            method: "GET",
        };
    }

    static updateDevices({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/device/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeDevices({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/device/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static changeBridgeMACAddress({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/eth/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static disableDevice({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/off/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static pairRoutine({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/routine/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static setDiodeMode({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/diode/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static createTechnologyGroups({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/tg/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listTechnologyGroups({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/tg/${farmID || farm}`,
            method: "GET",
        };
    }

    static updateTechnologyGroups({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/tg/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static createDictionaries({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `general/dictionary/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listDictionaries({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/dictionary/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static createDictionary({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/dictionary/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static saveAutomatedAction({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/actions/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static updateDictionary({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/dictionary/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static deleteDictionary({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/dictionary/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static createMedicine({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/medicine/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateMedicine({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/medicine/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static deleteMedicine({clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
        } = store.getState();
        return {
            path: `dictionary/medicine/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static createGraftingProgram({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `dictionary/program/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static editGraftingProgram({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `dictionary/program/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static editControlListData({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `dictionary/controlList/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static startControlList({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `dictionary/controlList/favourite/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static getAggregatedData(DevID, {farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/aggData/${farmID || farm}/${DevID}`,
            method: "GET",
        };
    }

    static getDeviceData({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/deviceData/${farmID || farm}`,
            method: "GET",
        };
    }

    static setAggDataComment(DevID, {clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/aggDataComment/${clientID || ClientID}/${farmID || farm}/${localUserID || LocalUserID}/${DevID}`,
            method: "PUT",
        };
    }

    static getUser(UserID) {
        if (!UserID) throw new Error("getUser: UserID is mandatory");
        return {
            path: `userapi/user/${UserID}`,
            method: "GET",
        };
    }

    static listUser({clientID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}`,
            method: "GET",
        };
    }

    static assignSupportUser({clientID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `userapi/support/${clientID || ClientID}`,
            method: "POST",
        };
    }

    static createUser(accType = {accType: "F"}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `userapi/account/${accType}/${ClientID}`,
            method: "POST",
        };
    }

    static verifyUserEmail() {
        return {
            path: "general2/service/verifymail",
            method: "POST",
        };
    }

    static enableDisableUser({clientID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}`,
            method: "PUT",
        };
    }

    static updateUser({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateRoles({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/roles/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static deleteUser({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static changeMQTTPassword() {
        return {
            path: "userapi/userMqttPasswd",
            method: "PUT",
        };
    }

    //nowe nie zateirdzone nizej

    static createSetting({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general2/settings/${clientID || ClientID}/${
                localUserID || LocalUserID
            }/${farmID || farm}`,
            method: "POST",
        };
    }

    static updateSetting({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general2/settings/${clientID || ClientID}/${
                localUserID || LocalUserID
            }/${farmID || farm}`,
            method: "PUT",
        };
    }

    static listSettings({clientID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `general2/settings/${clientID || ClientID}`, ///${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createForage({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/forage/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateForage({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/forage/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeForage({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/forage/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static restoreForage({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/forage/restore/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static changeOCRTemplate({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/ocr/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }
    static changeSowCardTemplate({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/card/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }
    static addSowCardTemplate({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/card/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }
    static addPermissionTemplate({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/permissions/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }
    static deletePermissionTemplate({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/permissions/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }
    static removeSowCardTemplate({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/card/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }
    static saveDefaultSowCardTemplate({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/card/default/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }
    static getDefaultTemplateList({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/defaultTemplate/${localUserID || LocalUserID}?SetType=SCG`,
            method: "GET",
        };
    }
    static getOCRTemplateFile({clientID, farmID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/ocr/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static service() {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/service/cc/${LocalUserID}`,
            method: "PUT",
        };
    }

    static listNotifications({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general2/notificationsFarm/${farmID || farm}`, ///${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static updateNotifications({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/notifications/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeNotification({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/notifications/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static createNotifications({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/notifications/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listDocuments() {
        return {
            path: `general2/documents`,
            method: "GET",
        };
    }

    static updateDocuments({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/documents/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static markDocumentAsRead({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/userDocRead/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static listGroups({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general/animalGR/${farmID || farm}`,
            method: "GET",
        };
    }

    static createGroup({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general/animalGR/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateGroup({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general/animalGR/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static listLogs({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general2/logs/${farmID || farm}`,
            method: "GET",
        };
    }

    static getLogObjectByID({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/logs/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getAnimalHistoryOfChanges({farmID, clientID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `general2/animalHistory/${farmID || farm}/${clientID || ClientID}`,
            method: "GET",
        };
    }

    static listCommandsHistory({farmID /*localUserID*/} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general2/commandsHistory/${farmID || farm}`, //${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static retryPayment() {
        return {
            path: "billing/retry",
            method: "POST",
        };
    }

    static terminateAccount({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `billing/terminate/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static commandProxy({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `iot/iotCommands/${farmID || farm}`,
            method: "POST",
        };
    }

    static registerIOT() {
        return {
            path: `iot/register`,
            method: "GET",
        };
    }

    /*
    ######################################
    #  SNS
    ######################################
    */

    static connectSNS(ClientID, LocalUserID) {
        return {
            path: `sns/connect/${ClientID}/${LocalUserID}`,
            method: "POST",
        };
    }

    static disconnectSNS(LocalUserID) {
        return {
            path: `sns/disconnect/${LocalUserID}`,
            method: "DELETE",
        };
    }

    /*
    ######################################
    #  BILLING
    ######################################
    */
    static getAddons() {
        return {
            path: `billing/addons`,
            method: "GET",
        };
    }

    static validateAddons({clientID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `billing/addons/${clientID || ClientID}`,
            method: "POST",
        };
    }

    static getPlans() {
        return {
            path: `billing/plans`,
            method: "GET",
        };
    }

    static generateToken({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/token/${customerId || sub}`,
            method: "GET",
        };
    }

    static createSubscription({clientID, farmID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `billing/subscription/${clientID || ClientID}/${
                farmID || farm
            }`,
            method: "POST",
        };
    }

    static updateSubscription({clientId, farmID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `billing/subscription/${clientId || ClientID}/${
                farmID || farm
            }`,
            method: "PUT",
        };
    }

    static searchSubscription() {
        return {
            path: `billing/subscription`,
            method: "GET",
        };
    }

    static createPaymentMethod({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/paymentMethod/${customerId || sub}`,
            method: "POST",
        };
    }

    static updatePaymentMethod({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/paymentMethod/${customerId || sub}`,
            method: "PUT",
        };
    }

    static deletePaymentMethod() {
        return {
            path: `billing/paymentMethod`,
            method: "DELETE",
        };
    }

    static cancelSubscription() {
        return {
            path: `billing/subscription`,
            method: "DELETE",
        };
    }

    static customerCreate({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/customer/${customerId || sub}`,
            method: "POST",
        };
    }

    static customerFind({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/customer/${customerId || sub}`,
            method: "GET",
        };
    }

    static customerDelete({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/customer/${customerId || sub}`,
            method: "DELETE",
        };
    }

    static transactionHistory({customerId} = {}) {
        const {
            user: {
                attributes: {sub},
            },
        } = store.getState();
        return {
            path: `billing/transaction/${customerId || sub}`,
            method: "GET",
        };
    }

    static deviceParam({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/deviceParam/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static getGateways({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/gateways/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    /*
    ######################################
    #  REPORTS
    ######################################
    */

    static getReports({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/getReport/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static createReport({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/report/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getReportData({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/getReport/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    /*
    ######################################
    #  EVENTS
    ######################################
    */

    static createTransfer({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/transfer/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static listSettlements({farmID} = {}) {
        const {
            location: {farm},
        } = store.getState();
        return {
            path: `general2/fti/${farmID || farm}`,
            method: "GET",
        };
    }

    static createSettlement({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/fti/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static updateSettlement({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/fti/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static deleteSettlement({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/fti/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static getTranslations({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `translations/translate/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static updateTranslations({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `translations/translate/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static deleteTranslations({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `translations/translate/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static getLanguages({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `translations/language/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    /*
    ######################################
    #  EVENTS
    ######################################
    */

    static createSowCycles({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/sowCycles/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createInsemination({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/insemination/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createTagging({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/tagging/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createUSG({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/usg/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createNoPregnancy({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/noPregnancy/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createIsMommy({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/isMommy/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createParturition({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/parturition/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createSeparationToMommy({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/separationToMommy/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createMommy({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/mommy/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createFallPiglets({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/fallPiglets/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createFall({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/fall/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createSelection({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/selection/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createWeighting({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/weighting/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createActiveNipples({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/activeNipples/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createSeparation({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/separation/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createComment({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/comment/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createP2({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/p2/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createSeparationByAnimal({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/separation/animal/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createTattoo({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/tattoo/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createSell({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/sell/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createHeat({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/heat/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createGrafting({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/grafting/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static endGrafting({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/endGrafting/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createPigletsTreatment({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/pigletsTreatment/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createCastration({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/castration/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createToInsemination({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/toInsemination/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createToRepeatInsemination({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/toRepeat/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static startParturition({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/startParturition/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static endParturition({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/endParturition/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createReclassify({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/reclassify/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createReclassifySow({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/reclassifySow/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createTreatment({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events/treatment/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createInsertion({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/insertion/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createAnimalHistory({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/history/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static deliverySettlement({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/deliverySettlement/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static deliverySettlementFromFile({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/deliverySettlement/file/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createFirstMilk({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/firstMilk/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createSeparationToMommyGilts({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `events2/separationToMommyGilts/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    /*
    ######################################
    #  SERVICE
    ######################################
    */

    static getMaintenance() {
        return {
            path: `general2/service/maintenance`,
            method: "GET",
        };
    }

    static deleteFarmData({farmID}) {
        return {
            path: `develop/farms/${farmID}`,
            method: "POST",
        };
    }

    static getApis() {
        return {
            path: `develop/version`,
            method: "GET",
        };
    }

    static changeEnvironment({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/iotEnv/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static removeFarmData({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/farm/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static createMockedFarm({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/mock/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getLambdaLogs({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/logs/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    /*
    ######################################
    #  REPORTS
    ######################################
    */

    static deleteReport({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `general2/reports/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    /*
    ######################################
    #  DEVICES
    ######################################
    */

    static assignDeviceToFarm({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/assign/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static createSettlementForDevice({farmID, clientID, localUserID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/settlement/start/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static endSettlementForDevice({farmID, clientID} = {}) {
        const {
            location: {farm},
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `devices/settlement/end/${farmID || farm}/${
                clientID || ClientID
            }`,
            method: "POST",
        };
    }

    static setFeedingEfficiency({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/efficiency/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static setWaterEfficiency({farmID, clientID, localUserID} = {}) {
        const {
            user: {ClientID, LocalUserID},
            location: {farm},
        } = store.getState();
        return {
            path: `devices/water/efficiency/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static setNutriProEfficiency({farmID, clientID, localUserID} = {}) {
        const {
            user: {ClientID, LocalUserID},
            location: {farm},
        } = store.getState();
        return {
            path: `devices/nutriPro/efficiency/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static addNecessaryDevices({farmID, clientID, localUserID} = {}) {
        const {
            user: {ClientID, LocalUserID},
            location: {farm},
        } = store.getState();
        return {
            path: `devices/fix/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static generalFeedingSettings({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/feeding/general/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    /*
    ######################################
    #  DEBUG
    ######################################
    */

    static debugClientEnv() {
        return {
            path: `develop/debugClientEnv`,
            method: "POST",
        };
    }

    static changeRFID({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/animal/rfid/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static editAnimal({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/editAnimal/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    /*
    ######################################
    #  SERVICE
    ######################################
     */

    static setMaintenanceDate({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/maintenance/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static deleteMaintenance({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/maintenance/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static synchronizeClientData({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/syncClient/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static synchronizeTableData({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `develop/syncTables/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static putDeviceProgram({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static getDevicePrograms({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static deleteDeviceProgram({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static createJobForDeviceProgram({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static getAgentList({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/agent/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static moveProgramToStage({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/move/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static downloadDeviceProgram({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/download/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getJobListForType({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/jobs/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getJobExecutionStatus({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/program/jobs/status/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getBackendPrograms({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/backend/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static downloadBackendProgram({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/backend/download/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createBackendUpdateJob({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/backend/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static getBackendUpdateJobList({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/backend/jobs/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getAgentProgramList({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/backend/rootServiceCloud/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static createAgentUpdateJob({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/backend/rootServiceCloud/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static synchronizeDeviceAnimalPlacementData() {
        return {
            path: `develop/fixDeviceAnimals`,
            method: "POST",
        };
    }

    static getScriptList({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/script/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createRunScriptJob({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/script/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static createInstallPackageJob({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/package/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    /*
    ######################################
    #  BREEDING
    ######################################
    */

    static editSowCycle({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `breeding/cycle/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static getCounters({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `breeding/counters/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createCounter({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `breeding/counters/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    /*
 ######################################
 #  USER
 ######################################
 */

    static forgotPassword() {
        return {
            path: "userapi/password/forgot",
            method: "POST",
        };
    }

    static changePasswordService({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/password/change/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static resetPassword() {
        return {
            path: `userapi/password/reset`,
            method: "POST",
        };
    }

    static assignServiceClientsMap({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/service/manage/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static getServiceClientsMap({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/service/manage/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getServiceAccounts({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/allServices/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getFarmOwner({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/owner/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getTranslators() {
        return {
            path: `userapi/translators`,
            method: "GET",
        };
    }

    static getAllUsers({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/allUsers/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    /*
    ######################################
    #  TASK
    ######################################
    */

    static createTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getTasks({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getProjectInformation({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/statistics/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getTaskByID({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/taskID/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static editTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static addCommentToTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/comment/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static addTaskFiles({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/file/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeCommentFromTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/comment/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static editTaskComment({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/comment/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static changeTaskStatus({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/status/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static addToDoItemToTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/todo/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static editToDoItem({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/todo/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static changeToDoItemStatus({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/todo/status/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static deleteToDoItem({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/todo/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static createCustomTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static editCustomTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static deleteCustomTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static createTaskFilter({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/task/filters/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static editTaskFilter({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/task/filters/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static deleteTaskFilters({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `settings/task/filters/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static manageGroupTaskStatus({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/task/group/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static manageGroupTaskItem({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/task/group/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static deleteGroupTaskItem({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/task/group/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static addProjectDescription({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `project/description/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static createProject({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `project/project/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getProjects({clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
        } = store.getState();
        return {
            path: `project/project/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static addUserToProject({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `project/user/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static removeUserFromProject({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `project/user/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static changeProjectColor({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `project/color/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeProject({clientID, localUserID, farmID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `project/project/${clientID || ClientID}/${
                localUserID || LocalUserID
            }/${farmID || farm}`,
            method: "DELETE",
        };
    }

    static createAnalytics({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general2/analytics/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static getChangelog({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general2/changelog/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }
    static getTechnologyGroupTasks({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `task/technologyGroupTasks/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }
    /*
    ######################################
    #  FILES
    ######################################
    */

    static getFiles({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/list/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getCreatedProtocols({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/protocol/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static uploadFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/client/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static createDirectory({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/folder/create/${clientID || ClientID}/${
                farmID || farm
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static createDirectoryForProtocol({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/protocol/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/client/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getFileObject({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/file/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getFileThumbnail({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/thumb/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static editFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/edit/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/remove/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static moveFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/move/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static getLastModifiedFiles({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/last/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getAllDirectories({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/dir/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static saveFileSettings({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `settings/file/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static createDosatron({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/dosatron/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static editDosatron({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/dosatron/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static deleteDosatron({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/dosatron/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    /*
    ######################################
    #  CLIENT
    ######################################
    */
    static editClientData({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/clients/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static editServiceFarm({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `userapi/service/farm/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static registerClient() {
        return {
            path: "userapi/clients/register",
            method: "POST",
        };
    }

    static createExistingClientFarm() {
        return {
            path: "userapi/createClientUserFarm",
            method: "POST",
        };
    }

    /*
    ######################################
    #  SALES
    ######################################
    */
    static getSales({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/sales/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static addSaleFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/files/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getSaleFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/files/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static editSaleData({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/sales/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static removeSaleFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/files/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static addUnassignedSale({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/sales/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static removeSale({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/sales/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static addUnassignedEventsToSale({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/events/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static unlinkSaleEvent({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `sales/events/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static getBasicEconomy({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `economy/basic/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static insertEconomyValues({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `economy/economy/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static getEconomy({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `economy/economy/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static pairForage({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/silosForage/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static editSellOrFall({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `events/sell/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static forceSync({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/sync/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "POST",
        };
    }

    static sendProgramToGateway({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/program/gateway/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static cancelScheduledTask({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/task/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "DELETE",
        };
    }

    static fileUploadToOCR({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/ocr/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "PUT",
        };
    }

    static getProgressOCR({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/ocr/${farmID || farm}/${clientID || ClientID}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getProgressObject({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `general/ocr/object/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getFilesOCR({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/ocr/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getOCRFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `files/ocr/object/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getPCBList({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `pcb/pcb/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createPCB({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `pcb/pcb/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static deletePCB({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `pcb/pcb/${localUserID || LocalUserID}`,
            method: "DELETE",
        };
    }

    static getPCBFile({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `pcb/file/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static getSerial({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `mngDevices/serial/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static createDeviceBySerial({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `mngDevices/serial/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static bindDeviceBySerial({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `mngDevices/bindDevice/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static swapBoard({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `mngDevices/serial/${farmID || farm}/${
                clientID || ClientID
            }/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    static offlineWrapper({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `offline/wrapper/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static generateOfflineFile({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {ClientID, LocalUserID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `offline/file/${clientID || ClientID}/${farmID || farm}/${
                localUserID || LocalUserID
            }`,
            method: "GET",
        };
    }

    static getDeviceFile({localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID},
            },
        } = store.getState();
        return {
            path: `devices/deviceFile/${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static bindDevice({farmID, clientID, localUserID} = {}) {
        const {
            user: {
                user: {LocalUserID, ClientID},
            },
            location: {farm},
        } = store.getState();
        return {
            path: `devices/bind/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }
    static getPermissionsList({clientID} = {}) {
        const {
            user: {
                user: {ClientID},
            },
        } = store.getState();
        return {
            path: `settings/permissions/list/${clientID || ClientID}`,
            method: "GET",
        };
    }
}
