import {createSelector} from "reselect";
import {PreferencesTypes} from "@wesstron/utils/Api/constants/prefTypes";
import * as NotificationTypes from "@wesstron/utils/Api/constants/notificationTypes";
import {getFarms, getSelectedEmployee} from "./userSelector";
import {get} from "lodash";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";

const SELECT_OPTIONS = {
    ALL: 0,
    CURRENT_FARM: 1,
    CLIENT: 2,
};

export const prepareNotificationPreference = (_farmID, prefData) => {
    const getPrefDataValue = (key) => ({
        SNS: get(prefData, `${key}.SNS`, true),
        email: get(prefData, `${key}.email`, false),
        SMS: get(prefData, `${key}.SMS`, false),
    });
    return {
        FarmID: _farmID,
        ShouldCreate: !prefData,
        PrefData: {
            Enabled: get(prefData, "Enabled", true),
            Events: {
                Alert: {},
                Info: {
                    [NotificationTypes.TREATMENT]: getPrefDataValue(
                        `Events.Info.${NotificationTypes.TREATMENT}`
                    ),
                    [NotificationTypes.AUTO_CREATE_ANIMAL]: getPrefDataValue(
                        `Events.Info.${NotificationTypes.AUTO_CREATE_ANIMAL}`
                    ),
                    [NotificationTypes.FIRST_MILK]: getPrefDataValue(
                        `Events.Info.${NotificationTypes.FIRST_MILK}`
                    ),
                },
                Warning: {
                    [NotificationTypes.INSEMINATION_TOO_FAST]: getPrefDataValue(
                        `Events.Warning.${NotificationTypes.INSEMINATION_TOO_FAST}`
                    ),
                    [NotificationTypes.FALL_PIGLETS_WRONG_AMOUNT]:
                        getPrefDataValue(
                            `Events.Warning.${NotificationTypes.FALL_PIGLETS_WRONG_AMOUNT}`
                        ),
                    [NotificationTypes.NO_PREGNANCY_NO_INSEMINATION]:
                        getPrefDataValue(
                            `Events.Warning.${NotificationTypes.NO_PREGNANCY_NO_INSEMINATION}`
                        ),
                    [NotificationTypes.PARTURITION_NO_INSEMINATION]:
                        getPrefDataValue(
                            `Events.Warning.${NotificationTypes.PARTURITION_NO_INSEMINATION}`
                        ),
                    [NotificationTypes.PARTURITION_NO_PREGNANCY]:
                        getPrefDataValue(
                            `Events.Warning.${NotificationTypes.PARTURITION_NO_PREGNANCY}`
                        ),
                    [NotificationTypes.PARTURITION_NEGATIVE_USG]:
                        getPrefDataValue(
                            `Events.Warning.${NotificationTypes.PARTURITION_NEGATIVE_USG}`
                        ),
                    [NotificationTypes.PARTURITION_TOO_FAST]: getPrefDataValue(
                        `Events.Warning.${NotificationTypes.PARTURITION_TOO_FAST}`
                    ),
                    [NotificationTypes.USG_NO_INSEMINATION]: getPrefDataValue(
                        `Events.Warning.${NotificationTypes.USG_NO_INSEMINATION}`
                    ),
                },
            },
            Feeding: {
                Alert: {
                    ConfigProblems: getPrefDataValue(
                        `Feeding.Alert.ConfigProblems`
                    ),
                    MotorProblems: getPrefDataValue(
                        `Feeding.Alert.MotorProblems`
                    ),
                    FeedingProblems: getPrefDataValue(
                        `Feeding.Alert.FeedingProblems`
                    ),
                    ScheduleUpdateProblems: getPrefDataValue(
                        `Feeding.Alert.ScheduleUpdateProblems`
                    ),
                    CurveUpdateProblems: getPrefDataValue(
                        `Feeding.Alert.CurveUpdateProblems`
                    ),
                    NoPower: getPrefDataValue(`Feeding.Alert.NoPower`),
                    HistoryDiff: getPrefDataValue(`Feeding.Alert.HistoryDiff`),
                    SelfBlockClosed: getPrefDataValue(
                        `Feeding.Alert.SelfBlockClosed`
                    ),
                    ManualAlert: getPrefDataValue(`Feeding.Alert.ManualAlert`),
                    RTC: getPrefDataValue(`Feeding.Alert.RTC`),
                    NoCommunication: getPrefDataValue(
                        `Feeding.Alert.NoCommunication`
                    ),
                    WrongTemperature: getPrefDataValue(
                        `Feeding.Alert.WrongTemperature`
                    ),
                    WrongCurrentConsumption: getPrefDataValue(
                        `Feeding.Alert.WrongCurrentConsumption`
                    ),
                    TestingMotors: getPrefDataValue(
                        `Feeding.Alert.TestingMotors`
                    ),
                    PunishmentSync: getPrefDataValue(
                        `Feeding.Alert.PunishmentSync`
                    ),
                    NoForage: getPrefDataValue(`Feeding.Alert.NoForage`),
                    MaximumFeedingTime: getPrefDataValue(
                        "Feeding.Alert.MaximumFeedingTime"
                    ),
                    IPSUMWeight: getPrefDataValue("Feeding.Alert.IPSUMWeight"),
                },
                Info: {
                    Birth: getPrefDataValue(`Feeding.Info.Birth`),
                },
                Warning: {
                    AnimalNoFeedingForHours: getPrefDataValue(
                        `Feeding.Warning.AnimalNoFeedingForHours`
                    ),
                    AnimalNoFeedingForDays: getPrefDataValue(
                        `Feeding.Warning.AnimalNoFeedingForDays`
                    ),
                    AnimalFeedingWrongPlace: getPrefDataValue(
                        `Feeding.Warning.AnimalFeedingWrongPlace`
                    ),
                    FeedingCurveLoop: getPrefDataValue(
                        `Feeding.Warning.FeedingCurveLoop`
                    ),
                    NoTemperatureSensor: getPrefDataValue(
                        `Feeding.Warning.NoTemperatureSensor`
                    ),
                },
            },
            Silo: {
                Alert: {
                    LimitExceeded: getPrefDataValue(`Silo.Alert.LimitExceeded`),
                    NoCommunication: getPrefDataValue(
                        `Silo.Alert.NoCommunication`
                    ),
                },
                Info: {},
                Warning: {
                    NoChanges: getPrefDataValue(`Silo.Warning.NoChanges`),
                    LowForage: getPrefDataValue(`Silo.Warning.LowForage`),
                },
            },
            Climate: {
                Alert: {
                    EEPROM: getPrefDataValue(`Climate.Alert.EEPROM`),
                    WrongTemperature: getPrefDataValue(
                        `Climate.Alert.WrongTemperature`
                    ),
                    WrongHumidity: getPrefDataValue(
                        `Climate.Alert.WrongHumidity`
                    ),
                    WrongCO2: getPrefDataValue(`Climate.Alert.WrongCO2`),
                    WrongNH3: getPrefDataValue(`Climate.Alert.WrongNH3`),
                    WrongH2S: getPrefDataValue(`Climate.Alert.WrongH2S`),
                    WrongSlurry: getPrefDataValue(`Climate.Alert.WrongSlurry`),
                    WrongPressure: getPrefDataValue(
                        `Climate.Alert.WrongPressure`
                    ),
                    LiftMalfunction: getPrefDataValue(
                        `Climate.Alert.LiftMalfunction`
                    ),
                    RunLoop: getPrefDataValue(`Climate.Alert.RunLoop`),
                },
                Info: {},
                Warning: {
                    ResetTime: getPrefDataValue(`Climate.Warning.ResetTime`),
                    NoActiveSensors: getPrefDataValue(
                        `Climate.Warning.NoActiveSensors`
                    ),
                    WrongFloorTemperature: getPrefDataValue(
                        `Climate.Warning.WrongFloorTemperature`
                    ),
                    WrongMaTType: getPrefDataValue(
                        `Climate.Warning.WrongMaTType`
                    ),
                    MissingCurtainCalibration: getPrefDataValue(
                        "Climate.Warning.MissingCurtainCalibration"
                    ),
                },
            },
            Cage: {
                Alert: {
                    General: getPrefDataValue(`Cage.Alert.General`),
                    DoorProblem: getPrefDataValue(`Cage.Alert.DoorProblem`),
                    ManualError: getPrefDataValue(`Cage.Alert.ManualError`),
                    TrainingError: getPrefDataValue(`Cage.Alert.TrainingError`),
                    SeparationError: getPrefDataValue(
                        `Cage.Alert.SeparationError`
                    ),
                    ExpeditionError: getPrefDataValue(
                        `Cage.Alert.ExpeditionError`
                    ),
                },
                Info: {
                    Expedition: getPrefDataValue(`Cage.Info.Expedition`),
                },
                Warning: {
                    PowerOutage: getPrefDataValue(`Cage.Warning.PowerOutage`),
                },
            },
            Counter: {
                Alert: {
                    WaterFlowExceeded: getPrefDataValue(
                        `Counter.Alert.WaterFlowExceeded`
                    ),
                    WaterLevelExceeded: getPrefDataValue(
                        `Counter.Alert.WaterLevelExceeded`
                    ),
                    WrongCurrencyConsumption: getPrefDataValue(
                        `Counter.Alert.WrongCurrencyConsumption`
                    ),
                },
                Info: {},
                Warning: {},
            },
            Chain: {
                Alert: {
                    NoChainSensor: getPrefDataValue(
                        `Chain.Alert.NoChainSensor`
                    ),
                    NoSiloFullSensor: getPrefDataValue(
                        `Chain.Alert.NoSiloFullSensor`
                    ),
                },
                Info: {},
                Warning: {
                    StateNotChanged: getPrefDataValue(
                        `Chain.Warning.StateNotChanged`
                    ),
                },
            },
            General: {
                Time: get(prefData, "General.Time", ["22:00", "22:00"]),
                Weekdays: get(prefData, "General.Weekdays", [
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                ]),
                Alert: {
                    NoCommunication: getPrefDataValue(
                        `General.Alert.NoCommunication`
                    ),
                },
                Info: {
                    CloudBoxRestart: getPrefDataValue(
                        `General.Info.CloudBoxRestart`
                    ),
                },
                Warning: {
                    CommandProblem: getPrefDataValue(
                        `General.Warning.CommandProblem`
                    ),
                },
            },
            Task: {
                Info: {
                    Changed: getPrefDataValue(`Task.Info.Changed`),
                },
            },
            FarmWatch: {
                Alert: {
                    LowTemperature: getPrefDataValue(
                        `FarmWatch.Alert.LowTemperature`
                    ),
                    HighTemperature: getPrefDataValue(
                        `FarmWatch.Alert.HighTemperature`
                    ),
                    LowHumidity: getPrefDataValue(
                        `FarmWatch.Alert.LowHumidity`
                    ),
                    HighHumidity: getPrefDataValue(
                        `FarmWatch.Alert.HighHumidity`
                    ),
                    LowCO2: getPrefDataValue(`FarmWatch.Alert.LowCO2`),
                    HighCO2: getPrefDataValue(`FarmWatch.Alert.HighCO2`),
                    SiloWeightBelowMin: getPrefDataValue(
                        `FarmWatch.Alert.SiloWeightBelowMin`
                    ),
                    SiloNoWeightChange: getPrefDataValue(
                        `FarmWatch.Alert.SiloNoWeightChange`
                    ),
                    SiloWeightChangeTooFast: getPrefDataValue(
                        `FarmWatch.Alert.SiloWeightChangeTooFast`
                    ),
                    DigitalInputError: getPrefDataValue(
                        `FarmWatch.Alert.DigitalInputError`
                    ),
                    HighTemporaryWaterFlow: getPrefDataValue(
                        `FarmWatch.Alert.HighTemporaryWaterFlow`
                    ),
                    HighConstantWaterFlow: getPrefDataValue(
                        `FarmWatch.Alert.HighConstantWaterFlow`
                    ),
                    NoWaterFlow: getPrefDataValue(
                        `FarmWatch.Alert.NoWaterFlow`
                    ),
                },
                Info: {
                    PowerBack: getPrefDataValue(`FarmWatch.Alert.PowerBack`),
                },
                Warning: {
                    NoSensorCommunication: getPrefDataValue(
                        `FarmWatch.Alert.NoSensorCommunication`
                    ),
                    HardwareError: getPrefDataValue(
                        `FarmWatch.Alert.HardwareError`
                    ),
                    LowSensorBattery: getPrefDataValue(
                        `FarmWatch.Alert.LowSensorBattery`
                    ),
                    NoPower: getPrefDataValue(`FarmWatch.Alert.NoPower`),
                    NoCloudConnection: getPrefDataValue(
                        `FarmWatch.Alert.NoCloudConnection`
                    ),
                },
            },
        },
    };
};

export const getPreferences = (state) => state.preferences.preferences;

const getFilterBookmarks = (state) => state.preferences.filterBookmarks;

export const makeSavedBookmarks = (ProjID) =>
    createSelector(getFilterBookmarks, (preferences) => {
        return preferences.filter(
            (item) =>
                item.SetType === PreferencesTypes.FILTER_BOOKMARKS &&
                item.ProjID === ProjID
        );
    });

export const getSelectedNotificationPreference = (state) =>
    state.preferences.selectedNotification;

const getSelectedFarmID = (state, props) => props.selectedFarmID;

const getSelectedPreferenceOption = (state, props) =>
    props.selectedPreferenceOption;

export const getNotificationsPreferencesData = createSelector(
    [getPreferences, getFarms, getSelectedEmployee],
    (preferences = [], farms = [], selectedEmployee = null) => {
        const notifications = preferences.filter(
            (pref) => pref.SetType === PreferencesTypes.NOTIFICATION
        );
        let _farms = farms;
        if (!!selectedEmployee) {
            const generalReadRole = selectedEmployee.roles.find(
                (role) => role.Role === RoleTypes.GENERAL_READ
            );
            if (!generalReadRole) _farms = [];
            _farms = farms.filter((farm) =>
                generalReadRole.LocalRights.some(
                    (lr) => lr.FarmID === farm.FarmID
                )
            );
        }
        return _farms.map((farm) => {
            const farmNotificationPreference = notifications.find(
                (n) => n.FarmID === farm.FarmID
            );
            return {
                ...prepareNotificationPreference(
                    farm.FarmID,
                    farmNotificationPreference?.PrefData
                ),
                DtaCrtTime:
                    farmNotificationPreference?.DtaCrtTime ||
                    new Date().getTime(),
                DtaModTime:
                    farmNotificationPreference?.DtaModTime ||
                    new Date().getTime(),
                LocalUserID: farmNotificationPreference?.LocalUserID,
                SetID: farmNotificationPreference?.SetID,
                SetType: farmNotificationPreference?.SetType,
            };
        });
    }
);

export const getAllPreferencesDataByFarmID = createSelector(
    [getFarms, getPreferences, getSelectedFarmID, getSelectedPreferenceOption],
    (farms, preferences, selectedFarmID, selectedPreference) => {
        const farm = farms.find((f) => f.FarmID === selectedFarmID);
        let FarmIDs = farms;
        if (selectedPreference === SELECT_OPTIONS.CLIENT) {
            FarmIDs = FarmIDs.filter((f) => f.ClientID === farm?.ClientID);
        }
        return {
            SetIDs: preferences
                .filter((p) => FarmIDs.find((f) => f.FarmID === p.FarmID))
                .map((p) => p.SetID),
            FarmIDs: FarmIDs.filter(
                (f) => !preferences.find((p) => p.FarmID === f.FarmID)
            ).map((f) => f.FarmID),
            ClientID: farm?.ClientID,
            FarmName: farm?.FarmName,
        };
    }
);

export const getTaskFilters = (state) => state?.preferences.taskFilters;

export const getSortedTaskFilters = createSelector(
    getTaskFilters,
    (filters) => {
        return [...filters].sort((a, b) =>
            a.PrefData.Name.localeCompare(b.PrefData.Name)
        );
    }
);

const getProjectFromProps = (_, props) => props.project;

export const getProjectFilters = createSelector(getTaskFilters, (filters) => {
    const tmp = {};
    for (let item of filters) {
        if (typeof item.ProjID === "string") {
            if (tmp[item.ProjID]) {
                tmp[item.ProjID].push(item);
            } else {
                tmp[item.ProjID] = [item];
            }
        } else if (Array.isArray(item.ProjID)) {
            for (let proj of item.ProjID) {
                if (tmp[proj]) {
                    tmp[proj].push(item);
                } else {
                    tmp[proj] = [item];
                }
            }
        }
    }
    return tmp;
});

export const makeProjectFilters = () =>
    createSelector(
        getProjectFilters,
        getProjectFromProps,
        (filters, project) => filters[project] || []
    );

export const getTaskFiltersLength = (state) =>
    state.preferences.taskFilters.length;
