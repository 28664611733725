import React from "react";
import {Fade} from "react-bootstrap";
import "./_loading.scss";
import PropTypes from "prop-types";
import {isFunction} from "lodash";

/**
 * komponent rysowany w trakcie ladowania asynchronicznego
 * odpowiednich komponentow
 * Implementowany w celu pokazania uzytkownikowi ze aplikacja dziala poprawnie,
 *
 * @param isLoading
 * @param error
 * @returns {*}
 */
export default class LoadingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeoutCalled: false,
        };
        this.setupTimer();
    }

    setupTimer = (props = this.props) => {
        const {isLoading, timeout, onTimeout} = props;
        const {timeoutCalled} = this.state;
        clearTimeout(this.timer);
        if (isLoading && timeout) {
            if (!timeoutCalled) {
                this.timer = setTimeout(() => {
                    onTimeout();
                }, 1000 * timeout);
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isLoading} = this.props;
        if (isLoading !== prevProps.isLoading) {
            this.setupTimer(this.props);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {
            error,
            isLoading,
            fallBackComponent,
            style,
            loadingComponent,
            bgInherit,
            type,
            text,
        } = this.props;
        if (error) {
            console.error(error);
            return isFunction(fallBackComponent)
                ? fallBackComponent(error)
                : fallBackComponent;
        }
        const className = [
            "loading",
            bgInherit ? "bg-inherit" : "",
            `type-${type}`,
        ]
            .filter((o) => o)
            .join(" ");
        return (
            <Fade
                in={isLoading}
                timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 500,
                }}
                unmountOnExit>
                <div style={style} className={className}>
                    <div>{loadingComponent}</div>
                    {text && <div className="loading-text">{text}</div>}
                </div>
            </Fade>
        );
    }
}

LoadingComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    timeout: PropTypes.number,
    fallBackComponent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
    ]),
    loadingComponent: PropTypes.node,
    onTimeout: PropTypes.func,
    bgInherit: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.oneOf(["component", "container", "sticky"]),
    text: PropTypes.node,
};

LoadingComponent.defaultProps = {
    timeout: 0,
    bgInherit: false,
    type: "component",
    onTimeout: () => {},
    fallBackComponent: <div>Sorry, there was a problem loading the page.</div>,
    style: {},
    loadingComponent: <i className="fas fa-circle-notch fa-spin" />,
};
