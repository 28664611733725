import {get, isArray, isFinite, isNil, isObject, isString} from "lodash";
import {LogTableTypes, LogTableTypesShort} from "../constans/logCodeTypes";
import moment from "moment";
import {
    convertDensityUnitTo,
    convertLengthUnitTo,
    convertPressureUnitTo,
    convertVoltageUnitTo,
    convertVolumeUnitTo,
    convertWeightUnitTo,
    getUnit,
} from "../utils/UnitUtils";
import {UnitTypes} from "../constans/unitTypes";
import {DICTIONARY_TYPE} from "../constans/general";
import {getTranslationPath} from "../utils/EventUtils";
import {
    getIconClassName,
    getLogType,
    shouldGetExternalName,
} from "../utils/LogsViewUtils";
import i18next from "i18next";
import {Level} from "../constans/levelTypes";
import {SettingTypes} from "../constans/settingTypes";
import {timeFormatter} from "../utils/DateTimeUtils";
import {isFiniteNumber} from "../utils/MathUtils";
import {PARTURITION_DIFFICULTY} from "../constans/eventTypes";
import {
    temperatureFormatter,
    temperatureOffsetFormatter,
} from "../utils/global-formatters/temperatureFormatter";
import {isVirtualPlacement} from "../utils/LocationUtils";

export class LogParser {
    constructor(log, dictionaries, useContainers) {
        this.log = log || {};
        this.dictionaries = dictionaries || {};
        this.useContainers = !!useContainers || false;
    }

    static createLogParser(log, dictionaries = {}, userContainers = false) {
        return new LogParser(log, dictionaries, !!userContainers);
    }

    _ColorContainer = (color) => {
        return `<withColor>${JSON.stringify({color})}</withColor>`;
    };

    _LinkContainer = (type, id, name, subIDs = []) => {
        return `<withLink>${JSON.stringify({type, id, name, subIDs})}</withLink>`;
    };

    _getSingleDictionaryItemByID = (
        dictionary,
        path,
        defaultValue,
        linkType,
        index = 0
    ) => {
        const id = get(this.log, path);
        const _defaultValue =
            defaultValue === null
                ? null
                : (isArray(defaultValue)
                      ? defaultValue[index]
                      : defaultValue) || "$t(none)";
        if (isNil(id)) return _defaultValue;
        const makeSubIDs = (_id) => {
            return isObject(dictionary)
                ? Object.entries(dictionary).find(([key, value]) => {
                      if (key.includes("isGroup")) {
                          const _val = JSON.parse(value);
                          return Array.isArray(_val) && _val.includes(_id);
                      }
                  })
                : [];
        };
        if (isArray(id)) {
            return id.map((_id) => {
                return isNil(dictionary[_id])
                    ? _defaultValue
                    : isObject(dictionary[_id])
                      ? JSON.stringify(dictionary[_id])
                      : this.useContainers && linkType
                        ? this._LinkContainer(
                              linkType,
                              _id,
                              dictionary[_id],
                              makeSubIDs(_id)
                          )
                        : dictionary[_id];
            });
        }
        return isNil(dictionary[id])
            ? _defaultValue
            : isObject(dictionary[id])
              ? JSON.stringify(dictionary[id])
              : this.useContainers && linkType
                ? this._LinkContainer(
                      linkType,
                      id,
                      dictionary[id],
                      makeSubIDs(id)
                  )
                : dictionary[id];
    };

    _getDictionaryItemByID = (
        dictionary = {},
        path,
        defaultValue = "$t(none)",
        {linkType = ""} = {}
    ) => {
        //mozemy path dostac jako string lub tablice
        if (isString(path))
            return this._getSingleDictionaryItemByID(
                dictionary,
                path,
                defaultValue,
                linkType,
                0
            );
        if (isArray(path))
            return path.map((p, i) => {
                return this._getSingleDictionaryItemByID(
                    dictionary,
                    p,
                    defaultValue,
                    linkType,
                    i
                );
            });
        return isString(defaultValue) ? defaultValue : "";
    };

    _timeFormatter = (format, {path, defaultValue}, options = {}) => {
        let timestamp = get(this.log, path);
        if (!isFiniteNumber(timestamp)) timestamp = defaultValue;
        if (!isFiniteNumber(timestamp)) {
            return defaultValue;
        }
        if (options.utc) {
            return moment.utc(timestamp).format(format);
        }
        return moment(timestamp).format(format);
    };

    dateFormatter = (props) => {
        return this._timeFormatter("DD.MM.YY", props);
    };

    dateTimeFormatter = (props) => {
        return this._timeFormatter("DD.MM.YY HH:mm", props);
    };

    timeFormatter = (props) => {
        return this._timeFormatter("HH:mm", props);
    };

    utcDateFormatter = (props) => {
        return this._timeFormatter("DD.MM.YY", props, {utc: true});
    };

    utcDateTimeFormatter = (props) => {
        return this._timeFormatter("DD.MM.YY HH:mm", props, {utc: true});
    };

    utcTimeFormatter = (props) => {
        return this._timeFormatter("HH:mm", props, {utc: true});
    };

    cageAlarmTimeFormatter = ({path, defaultValue}) => {
        const timestamp = get(this.log, path, defaultValue);
        if (timestamp === 0) return "$t(turnedOff_male)";
        return isFinite(timestamp) ? timeFormatter(timestamp) : defaultValue;
    };

    prettyTimeFormatter = ({path, defaultValue}) => {
        const timestamp = get(this.log, path, defaultValue);
        return isFinite(timestamp) ? timeFormatter(timestamp) : defaultValue;
    };

    localDayTimestampFormatter = ({path, defaultValue}) => {
        const localTimestamp = get(this.log, path, defaultValue);
        const logTime = moment(this.log.DtaCrtTime).startOf("day");
        return isFiniteNumber(localTimestamp)
            ? logTime.diff(moment(localTimestamp).startOf("day"), "day")
            : "$t(none)";
    };

    animalFormatter = ({path, defaultValue}) => {
        const {animals} = this.dictionaries;
        return this._getDictionaryItemByID(animals, path, defaultValue, {
            linkType: "animal",
        });
    };

    breedingCounterFormatter = () => {
        return i18next.t("breedingCounter");
    };

    efficiencyTimeFormatter = ({path, defaultValue}) => {
        return i18next.t(
            "newSettings.dispenserNRF.efficiency.timeEfficiencyFormatter",
            {
                value: convertVolumeUnitTo(
                    get(this.log, path, defaultValue) * 2,
                    {
                        unit: UnitTypes.MEDIUM,
                        fixed: 2,
                        showUnit: true,
                        acceptNil: true,
                        rawValue: false,
                    }
                ),
            }
        );
    };

    efficiencyImpulseFormatter = ({path, defaultValue}) => {
        return i18next.t(
            "newSettings.dispenserNRF.efficiency.impulseEfficiencyFormatter",
            {
                value: get(this.log, path, defaultValue),
                unit: getUnit("volume", UnitTypes.MEDIUM, {
                    overrideUnitSystem: "metric",
                }),
            }
        );
    };

    settingFormatter = ({path, defaultValue}) => {
        const {settings} = this.dictionaries;
        return this._getDictionaryItemByID(settings, path, defaultValue);
    };

    supplierFormatter = ({path, defaultValue}) => {
        const {C} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(C, path, defaultValue);
    };

    raceFormatter = ({path, defaultValue}) => {
        const {R} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(R, path, defaultValue);
    };

    deviceFormatter = ({path, defaultValue}) => {
        // console.log(this, "DDD")
        const {devices} = this.dictionaries;
        return this._getDictionaryItemByID(devices, path, defaultValue);
    };

    employeeFormatter = ({path, defaultValue}) => {
        const {employees} = this.dictionaries;
        return this._getDictionaryItemByID(employees, path, defaultValue);
    };

    locationFormatter = ({path, defaultValue}) => {
        const {placements} = this.dictionaries;
        const id = get(this.log, path);
        if (isVirtualPlacement(id)) return "$t(locationOfRemoved)";
        return this._getDictionaryItemByID(placements, path, defaultValue);
    };

    fallReasonFormatter = ({path, defaultValue}) => {
        const {F} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(F, path, defaultValue);
    };

    medicineFormatter = ({path, defaultValue}) => {
        const {MD} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(MD, path, defaultValue);
    };

    graftingReasonFormatter = ({path, defaultValue}) => {
        const {G} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(G, path, defaultValue);
    };

    noPregnancyFormatter = ({path, defaultValue}) => {
        const {N} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(N, path, defaultValue);
    };

    selectionReasonFormatter = ({path, defaultValue}) => {
        const {S} = this.dictionaries.dictionaries;
        return this._getDictionaryItemByID(S, path, defaultValue);
    };

    dictionaryFormatter = ({path, defaultValue}) => {
        const mergedKeys = Object.values(this.dictionaries.dictionaries).reduce(
            (a, b) => ({...a, ...b}),
            {}
        );
        return this._getDictionaryItemByID(mergedKeys, path, defaultValue);
    };

    markerWorkTypeWithParamsFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        if (!value) return "-";
        return `$t(formatters.markerWorkTypeWithParamsFormatter.${[value.workType]}, {"weightMin": "${this.weightFormatter({path: `${path}.weightMin`})}", "weightMax": "${this.weightFormatter({path: `${path}.weightMax`})}"})`;
    };

    booleanFormatter = ({path, defaultValue}) => {
        const dict = {
            true: "✓",
            1: "✓",
            false: "✗",
            0: "✗",
        };
        return this._getDictionaryItemByID(dict, path, defaultValue);
    };

    skipDosesFormatter = ({path, defaultValue}) => {
        const dict = {
            true: "$t(skipped)",
            1: "$t(skipped)",
            false: "$t(ok)",
            0: "$t(ok)",
        };
        const doses = get(this.log, path, []);
        return doses
            .map((o, i) => `${i + 1}: ${dict[o] || "$t(none)"}`)
            .join(", ");
    };

    weightFormatter = ({path, defaultValue}) =>
        convertWeightUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 2,
            acceptNil: true,
            rawValue: false,
        });

    densityFormatter = ({path, defaultValue}) =>
        convertDensityUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 2,
            acceptNil: true,
            rawValue: false,
        });

    distanceFormatter = ({path, defaultValue}) =>
        convertLengthUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: false,
        });

    weightFormatterBig = ({path, defaultValue}) =>
        convertWeightUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.BIG,
            showUnit: true,
            fixed: 2,
            acceptNil: true,
            rawValue: false,
        });

    weightFormatterSmall = ({path, defaultValue}) =>
        convertWeightUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.SMALL,
            showUnit: true,
            fixed: 0,
            acceptNil: true,
            rawValue: false,
        });

    volumeFormatter = ({path, defaultValue}) =>
        convertVolumeUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: false,
        });

    voltageFormatter = ({path, defaultValue}) =>
        convertVoltageUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.SMALL,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: false,
        });

    temperatureFormatter = ({path, defaultValue}) =>
        temperatureFormatter(get(this.log, path, defaultValue));

    temperatureOffsetFormatter = ({path, defaultValue}) =>
        temperatureOffsetFormatter(get(this.log, path, defaultValue));

    pressureFormatter = ({path, defaultValue}) =>
        convertPressureUnitTo(get(this.log, path, defaultValue), {
            unit: UnitTypes.SMALL,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: false,
        });

    animalKindFormatter = ({path, defaultValue}) => {
        const animalKind = get(this.log, path, defaultValue);
        return `$t(animalKind.${animalKind})`;
    };

    pregnantFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(events.usgEvent.${["negative", "positive", "toRepeat", "toRepeatNegative", "toRepeatPositive"][value]})`;
    };

    matsFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(formatters.matsFormatter.${[value]})`;
    };

    dispenserExitFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value
            ? `$t(formatters.dispenserExitFormatter, {"exitNumber": ${value}})`
            : "";
    };

    floorFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value === -11 ? `$t(floorDisabled)` : value;
    };

    ppmFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(${value}$t("ppm"))`;
    };

    climateCurveDayFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value === -1 ? `$t(curveDisabled)` : value;
    };

    chimneyFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(formatters.chimneyFormatter.${value})`;
    };

    secondsFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `${(value / 1000).toFixed(2)}s`;
    };

    percentFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `${value}%`;
    };

    dayFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(Xdays, {"count": ${value}})`;
    };

    impulseFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(formatters.impluse, {"count": ${value}})`;
    };
    minuteFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(Xminute, {"count": ${value}})`;
    };

    routineFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return ["add", "remove"].includes(value.Action)
            ? `$t(formatters.routineFormatter.${value.Action}, {"Index": ${value?.Index}, "Routine": ${value?.Routine}})`
            : "$t(none)";
    };

    liftTypeSK4Formatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return [0, 1, 2, 3].includes(value)
            ? `$t(formatters.liftTypeSK4Formatter.${value}`
            : "$t(none)";
    };

    millisecondsFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `${value}ms`;
    };

    animalsNumberCageExpeditionFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value === 0
            ? "$t(noLimitLowerCase)"
            : this.pcsFormatter({path, defaultValue});
    };

    pcsFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return isFiniteNumber(value)
            ? `$t(pcs, {"count": ${value}})`
            : "$t(none)";
    };

    workTypeSK3Formatter = ({path, defaultValue}) => {
        const translation = [
            "deviceRows.chainFeeding.chainFeedingRow.workTypes.inactive",
            "deviceRows.chainFeeding.chainFeedingRow.workTypes.manual",
            "deviceRows.chainFeeding.chainFeedingRow.workTypes.automatic",
            "modals.feedingHistoryModal.curve",
        ];
        const value = get(this.log, path, defaultValue);
        return `$t(${translation[value]})`;
    };

    workTypeCageFormatter = ({path, defaultValue}) => {
        const translation = [
            "$t(modals.cageWorkMode.manual)",
            "$t(modals.cageWorkMode.training) 1",
            "$t(modals.cageWorkMode.training) 2",
            "$t(modals.cageWorkMode.training) 3",
            "$t(modals.cageWorkMode.separation)",
            "$t(modals.cageWorkMode.expedition)",
            "$t(modals.cageWorkMode.weighting)",
        ];
        const value = get(this.log, path, defaultValue);
        return translation[value] || "$t(none)";
    };

    cageExitFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value && value < 4
            ? `$t(modals.cageMarkerModal.exit.${value})`
            : "$t(none)";
    };

    cageAutoResolveAttemptsAlertFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        if (value === 0) {
            return `$t(turnedOff_male)`;
        } else if (isFiniteNumber(value)) {
            return value;
        }
        return "$t(none)";
    };

    cageAutoResolveAttemptsFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        switch (value) {
            case 255:
            case 0:
                return `$t(formatters.cageAutoResolveAttemptsFormatter.${value})`;
            default:
                if (isFiniteNumber(value)) {
                    return value;
                }
                return "$t(none)";
        }
    };

    //todo: pobrac krzywa
    climateCurveFormatter = ({path, defaultValue}) => {
        return get(this.log, path, defaultValue);
    };

    climateMatCalibrationFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        switch (value) {
            case 0:
                return "$t(settings.ctrlByCurve)";
            case 1:
                return "$t(settings.ctrlByCalibration0)";
            case 2:
                return "$t(settings.ctrlByCalibration2)";
            default:
                return "$t(none)";
        }
    };

    colorFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return this._ColorContainer(value);
    };

    diodeModeFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return ["DEF", "TECHNO"].includes(value)
            ? `$t(formatters.diodeModeFormatter.${value})`
            : "$t(none)";
    };

    dictionaryTypeFormatter = ({path, defaultValue}) => {
        const dict = {};
        Object.values(DICTIONARY_TYPE).forEach((o) => {
            const translation = getTranslationPath(o);
            if (translation !== null) {
                dict[o] = `$t(${translation})`;
            }
        });
        return this._getDictionaryItemByID(dict, path, defaultValue);
    };

    sectorTypeFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(SType.${value - 1})`;
    };

    clearCounterFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return [true, false, 0, 1].includes(value)
            ? `$t(formatters.clearCounterFormatter.${+value})`
            : "$t(none)";
    };

    tattooSexCodeFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value === "S"
            ? "$t(gilt)"
            : value === "B"
              ? "$t(animalTypes.4)"
              : "$(none)";
    };

    workTypeCageMarkerFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value < 5
            ? `$t(modals.cageMarkerModal.exit.${value})`
            : "$t(none)";
    };

    workTypeCageExpeditionFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return [1, 2, 3, 4].includes(value)
            ? `$t(modals.cageWorkMode.expeditionWorkTypes.${value})`
            : "$t(none)";
    };

    locationTypeFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        switch (value) {
            case Level.CHAMBER:
                return "$t(newSettings.buildings.chamber)";
            case Level.BOX:
                return "$t(standing)";
            case Level.BUILDING:
                return "$t(newSettings.buildings.building)";
            case Level.FARM:
                return "$t(farm)";
            case Level.SECTOR:
                return "$t(newSettings.buildings.sector)";
            default:
                return null;
        }
    };

    feedingTypeFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return value
            ? `$t(modals.addLocationModal.individualFeeding)`
            : `$t(modals.addLocationModal.groupFeeding)`;
    };

    feedingSensorFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return [true, false, 0, 1].includes(value)
            ? `$t(formatters.feedingSensorFormatter.${+value})`
            : "$t(none)";
    };

    parturitionDifficultyFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        switch (value) {
            case PARTURITION_DIFFICULTY.LOW:
                return "$t(low)";
            case PARTURITION_DIFFICULTY.MEDIUM:
                return "$t(medium)";
            case PARTURITION_DIFFICULTY.HARD:
                return "$t(hard)";
            default:
                return "$t(none)";
        }
    };

    groupFormatter = ({path, defaultValue}) => {
        const {GrID} = this.dictionaries.groups;
        return this._getDictionaryItemByID(GrID, path, defaultValue);
    };

    pigletsTreatmentDoseFormatter = ({path}) => {
        const [value, medicineId] = get(this.log, path, [null, null]);
        if (isNil(value)) {
            return "$t(none)";
        }
        let formattedValue = `${value}`;
        if (medicineId) {
            let unit = get(
                this.dictionaries.medicines[medicineId],
                "WData.Unit",
                null
            );
            if (typeof unit === "string") {
                if (unit.includes("/")) {
                    unit = unit.split("/").shift();
                }
                formattedValue += unit;
            }
        }
        return formattedValue;
    };

    saleFormatter = ({path, defaultValue}) => {
        console.log(path, defaultValue, this.dictionaries.sales);
        return this._getDictionaryItemByID(
            this.dictionaries.sales,
            path,
            defaultValue
        );
    };

    eventTypeFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(eventTypes.${value})`;
    };

    relayStateFormatter = ({path, defaultValue}) => {
        const value = get(this.log, path, defaultValue);
        return `$t(formatters.relayStateFormatter.${value})`;
    };

    tableNameToFormatterName = (tableName) => {
        switch (tableName) {
            case LogTableTypes.ANIMALS:
            case LogTableTypesShort.ANIMALS:
                return "animalFormatter";
            case LogTableTypes.SETTINGS:
            case LogTableTypesShort.SETTINGS:
                return "settingFormatter";
            case LogTableTypes.DEVICES:
            case LogTableTypesShort.DEVICES:
                return "deviceFormatter";
            case LogTableTypes.BUILDINGS:
            case LogTableTypesShort.BUILDINGS:
                return "locationFormatter";
            case LogTableTypes.ANIMAL_GROUP:
            case LogTableTypesShort.ANIMAL_GROUP:
                return "groupFormatter";
            case LogTableTypes.SALES:
            case LogTableTypesShort.SALES:
                return "saleFormatter";
            case LogTableTypes.BREEDING_COUNTERS:
            case LogTableTypesShort.BREEDING_COUNTERS:
                return "breedingCounterFormatter";
            default:
                return null;
        }
    };

    getFormatter = (formatterName) => {
        switch (formatterName) {
            case "breedingCounterFormatter":
                return this.breedingCounterFormatter;
            case "animalFormatter":
                return this.animalFormatter;
            case "efficiencyTimeFormatter":
                return this.efficiencyTimeFormatter;
            case "efficiencyImpulseFormatter":
                return this.efficiencyImpulseFormatter;
            case "settingFormatter":
                return this.settingFormatter;
            case "employeeFormatter":
                return this.employeeFormatter;
            case "locationFormatter":
                return this.locationFormatter;
            case "fallReasonFormatter":
                return this.fallReasonFormatter;
            case "dateFormatter":
                return this.dateFormatter;
            case "timeFormatter":
                return this.timeFormatter;
            case "utcDateTimeFormatter":
                return this.utcDateTimeFormatter;
            case "utcTimeFormatter":
                return this.utcTimeFormatter;
            case "utcDateFormatter":
                return this.utcDateFormatter;
            case "medicineFormatter":
                return this.medicineFormatter;
            case "graftingReasonFormatter":
                return this.graftingReasonFormatter;
            case "noPregnancyFormatter":
                return this.noPregnancyFormatter;
            case "weightFormatter":
                return this.weightFormatter;
            case "weightFormatterSmall":
                return this.weightFormatterSmall;
            case "weightFormatterBig":
                return this.weightFormatterBig;
            case "selectionReasonFormatter":
                return this.selectionReasonFormatter;
            case "animalKindFormatter":
                return this.animalKindFormatter;
            case "pregnantFormatter":
                return this.pregnantFormatter;
            case "deviceFormatter":
                return this.deviceFormatter;
            case "supplierFormatter":
                return this.supplierFormatter;
            case "raceFormatter":
                return this.raceFormatter;
            case "ppmFormatter":
                return this.ppmFormatter;
            case "dictionaryFormatter":
                return this.dictionaryFormatter;
            case "dictionaryTypeFormatter":
                return this.dictionaryTypeFormatter;
            case "skipDosesFormatter": //todo: dorobic formatter do dawek
                return this.skipDosesFormatter;
            case "booleanFormatter":
                return this.booleanFormatter;
            case "volumeFormatter":
                return this.volumeFormatter;
            case "percentFormatter":
                return this.percentFormatter;
            case "temperatureFormatter":
                return this.temperatureFormatter;
            case "temperatureOffsetFormatter":
                return this.temperatureFormatter;
            case "secondsFormatter":
                return this.secondsFormatter;
            case "pressureFormatter":
                return this.pressureFormatter;
            case "voltageFormatter":
                return this.voltageFormatter;
            case "matsFormatter":
                return this.matsFormatter;
            case "floorFormatter":
                return this.floorFormatter;
            case "climateCurveDayFormatter":
                return this.climateCurveDayFormatter;
            case "chimneyFormatter":
                return this.chimneyFormatter;
            case "feedingTypeFormatter":
                return this.feedingTypeFormatter;
            case "sectorTypeFormatter":
                return this.sectorTypeFormatter;
            case "locationTypeFormatter":
                return this.locationTypeFormatter;
            case "workTypeFormatter":
            case "workTypeSK3Formatter":
                return this.workTypeSK3Formatter;
            case "workTypeCageFormatter":
                return this.workTypeCageFormatter;
            case "climateCurveFormatter":
                return this.climateCurveFormatter;
            case "cageExitFormatter":
                return this.cageExitFormatter;
            case "dayFormatter":
                return this.dayFormatter;
            case "minuteFormatter":
                return this.minuteFormatter;
            case "millisecondsFormatter":
                return this.millisecondsFormatter;
            case "climateMatCalibrationFormatter":
                return this.climateMatCalibrationFormatter;
            case "prettyTimeFormatter":
                return this.prettyTimeFormatter;
            case "dispenserExitFormatter":
                return this.dispenserExitFormatter;
            case "impulseFormatter":
                return this.impulseFormatter;
            case "workTypeCageMarkerFormatter":
                return this.workTypeCageMarkerFormatter;
            case "workTypeCageExpeditionFormatter":
                return this.workTypeCageExpeditionFormatter;
            case "animalsNumberCageExpeditionFormatter":
                return this.animalsNumberCageExpeditionFormatter;
            case "pcsFormatter":
                return this.pcsFormatter;
            case "localDayTimestampFormatter":
                return this.localDayTimestampFormatter;
            case "dateTimeFormatter":
                return this.dateTimeFormatter;
            case "cageAutoResolveAttemptsFormatter":
                return this.cageAutoResolveAttemptsFormatter;
            case "cageAutoResolveAttemptsAlertFormatter":
                return this.cageAutoResolveAttemptsAlertFormatter;
            case "cageAlarmTimeFormatter":
                return this.cageAlarmTimeFormatter;
            case "feedingSensorFormatter":
                return this.feedingSensorFormatter;
            case "colorFormatter":
                return this.colorFormatter;
            case "markerWorkTypeWithParamsFormatter":
                return this.markerWorkTypeWithParamsFormatter;
            case "clearCounterFormatter":
                return this.clearCounterFormatter;
            case "tattooSexCodeFormatter":
                return this.tattooSexCodeFormatter;
            case "groupFormatter":
                return this.groupFormatter;
            case "parturitionDifficultyFormatter":
                return this.parturitionDifficultyFormatter;
            case "pigletsTreatmentDoseFormatter":
                return this.pigletsTreatmentDoseFormatter;
            case "saleFormatter":
                return this.saleFormatter;
            case "eventTypeFormatter":
                return this.eventTypeFormatter;
            case "relayStateFormatter":
                return this.relayStateFormatter;
            case "routineFormatter":
                return this.routineFormatter;
            case "liftTypeSK4Formatter":
                return this.liftTypeSK4Formatter;
            case "diodeModeFormatter":
                return this.diodeModeFormatter;
            case "densityFormatter":
                return this.densityFormatter;
            case "distanceFormatter":
                return this.distanceFormatter;
            default:
                return ({path, defaultValue = "$t(none)"}) => {
                    const value = get(this.log, path) ?? defaultValue;
                    if (isArray(value)) return value.join(", ");
                    return value;
                };
        }
    };

    getIconAndName = () => {
        const fallbackName = get(this, "log.AdditionalInfo._name", "$t(none)");
        if (
            [LogTableTypes.FTI, LogTableTypesShort.FTI].includes(
                this.log.TableName
            )
        ) {
            const formatter = this.getFormatter("locationFormatter");
            const defaultName = this.locationFormatter({
                path: "ObjOld.PlcmntID",
            });
            return {
                icon: getIconClassName("FarmTimeInterval"),
                name: `$t(settlement), $t(grid.chamber): ${formatter({
                    path: "ObjNew.PlcmntID",
                    defaultValue: defaultName,
                })}`,
            };
        }
        if (
            [LogTableTypes.TASKS, LogTableTypesShort.TASKS].includes(
                this.log.TableName
            )
        ) {
            const issueNumber = get(
                this,
                "log.ObjNew.IssueNr",
                get(this, "log.ObjOld.IssueNr")
            );
            const issueTemplateName = get(
                this,
                "log.ObjNew.SetData.Title",
                get(this, "log.ObjOld.SetData.Title")
            );
            return {
                icon: getIconClassName(this.log.TableName),
                name:
                    !isNil(issueNumber) || isNil(issueTemplateName)
                        ? `$t(task) #${issueNumber}`
                        : `$t(task) "${issueTemplateName}"`,
            };
        }
        if (
            [LogTableTypes.DICTIONARY, LogTableTypesShort.DICTIONARY].includes(
                this.log.TableName
            )
        ) {
            // trzeba ogarnac jaka ikonke wyswietlic a nie mamy w sumie nic poza id i name xD
            const {
                NAMES: {
                    DICTIONARY,
                    CONTROLLIST,
                    MEDICINE,
                    INGREDIENT,
                    GRAFTINGPROGRAM,
                },
            } = this.dictionaries.dictionaries;
            let icon = getIconClassName(LogTableTypes.DICTIONARY);
            if (CONTROLLIST[this.log.ObjID])
                icon = getIconClassName("ControlList");
            if (MEDICINE[this.log.ObjID]) icon = getIconClassName("Medicine");
            if (INGREDIENT[this.log.ObjID])
                icon = getIconClassName("Ingredient");
            if (GRAFTINGPROGRAM[this.log.ObjID])
                icon = getIconClassName("GraftingProgram");
            const merged = {
                ...DICTIONARY,
                ...CONTROLLIST,
                ...MEDICINE,
                ...INGREDIENT,
                ...GRAFTINGPROGRAM,
            };
            return {
                name: merged[this.log.ObjID] || fallbackName,
                icon: icon,
            };
        }
        if (
            [LogTableTypes.SETTINGS, LogTableTypesShort.SETTINGS].includes(
                this.log.TableName
            )
        ) {
            const setType = this.log?.ObjNew?.SetType;
            const codes = this.log?.Codes || [];
            const formatter = this.getFormatter("settingFormatter");
            const getName = () => {
                if (codes.includes("2246"))
                    return i18next.t("validationsView.validations");
                if (codes.includes("2228")) return i18next.t("locale");
                if (setType === SettingTypes.GENERAL)
                    return i18next.t("generalSettings");
                if (setType === SettingTypes.FARM_MAP) return i18next.t("map");
                if (setType === SettingTypes.ECONOMY_SETTINGS)
                    return i18next.t("economySettings");
                return i18next.t("notifications.notifications");
            };
            const name = shouldGetExternalName(this.log)
                ? getName()
                : formatter({
                      path: "ObjID",
                      defaultValue: fallbackName,
                  });
            const type = getLogType(this.log.TableName, codes);
            return {
                icon: getIconClassName(type),
                name,
            };
        }
        if (
            [LogTableTypes.SALES, LogTableTypesShort.SALES].includes(
                this.log.TableName
            )
        ) {
            const formatter = this.getFormatter("saleFormatter");
            return {
                icon: getIconClassName(this.log.TableName),
                name: formatter({path: "ObjID", defaultValue: fallbackName}),
            };
        }
        if (
            [LogTableTypes.REPORTS, LogTableTypesShort.REPORTS].includes(
                this.log.TableName
            )
        ) {
            const key = get(this.log.ObjNew, "FeturaQuery.QueryCode");
            return {
                icon: getIconClassName(this.log.TableName),
                name: i18next.t([`newReports.headers.${key}`, key]),
            };
        }

        const formatter = this.getFormatter(
            this.tableNameToFormatterName(this.log.TableName)
        );
        return {
            icon: getIconClassName(this.log.TableName),
            name: formatter({path: "ObjID", defaultValue: fallbackName}),
        };
    };

    convertPathToTranslation = (path = "") => {
        const newPath = path.replace(/\[/g, ".").replace(/]/g, "");
        const splitPath = newPath.split(".").filter((o) => !!o);
        let prefix = "";
        //jesli mamy mats[0].minus to zeby wyswietlilo tlumaczenie Mata 1 - Minus zamiast samego Minus
        console.log(newPath, path, splitPath);
        if (
            splitPath.length > 1 &&
            isFinite(+splitPath[splitPath.length - 2])
        ) {
            prefix = `$t(shadowDescription.${splitPath.slice(0, splitPath.length - 1).join(".")}._title) - `;
        }

        return `${prefix}$t(shadowDescription.${newPath}._title)`;
    };

    getCodeParams(params) {
        let codeParams = {};
        for (let [
            key,
            {path, formatter, defaultValue = null},
        ] of Object.entries(params)) {
            const valueFormatter = this.getFormatter(formatter);
            codeParams[key] = valueFormatter({path, defaultValue});
        }
        return codeParams;
    }

    getComment() {
        return this.log.AdditionalInfo?._comment ?? "";
    }

    getUserObj() {
        const {log} = this;
        return {
            value: log.UserID,
            name: this.dictionaries.employees[log.UserID] || log.UserID,
            type: "user",
            isEmployee: !!this.dictionaries.employees[log.UserID],
        };
    }

    getData = () => {
        // console.log(this, "DDD")
        const {log} = this;
        // const codes = (log.Codes || []).slice();
        const listParams = log.ListParams || [];
        const allParams = [];
        const allTranslations = [];
        let scheduleData = null;
        let codes = [];
        let logParams = [];
        for (let i = 0; i < (log.Codes || []).length; i++) {
            let code = (log.Codes || [])[i];
            let params = (log.Params || [])[i];
            // zaplanowane zdarzenie
            if (code === "2999") {
                scheduleData = {
                    code: `$t(logsView.codes.${code})`,
                    params: this.getCodeParams(params, i),
                };
            } else {
                codes.push(code);
                logParams.push(params);
            }
        }
        if (listParams.length) {
            // inne formatowanie bo lista zmian
            const codeParams = [];
            for (let [
                key,
                {path, newValue, oldValue, valueFormatter: _valueFormatter},
            ] of Object.entries(listParams)) {
                if (key > 3) break;
                const valueFormatter = this.getFormatter(_valueFormatter);
                const after =
                    valueFormatter({
                        path: `ListParams.${key}.newValue`,
                        defaultValue: newValue ?? "$t(none)",
                    }) ?? "$t(none)";
                const before =
                    valueFormatter({
                        path: `ListParams.${key}.oldValue`,
                        defaultValue: oldValue ?? "$t(none)",
                    }) ?? "$t(none)";
                const translation = `${this.convertPathToTranslation(path)}: <strong>${before}</strong> → <strong>${after}</strong>`;
                codeParams.push(translation);
            }
            if (listParams.length > 4) {
                codeParams.push(
                    `<strong>$t(XmoreParams, {"number": ${listParams.length - 4}})</strong>`
                );
            }
            codes.forEach((code, i) => {
                allTranslations[i] = `$t(logsView.codes.${code})`;
                allParams[i] = {values: codeParams.join(", ")};
            });
        }
        codes.forEach((code, index) => {
            const params = logParams[index] || {};
            const codeParams = this.getCodeParams(params);
            allTranslations[index] = `$t(logsView.codes.${code})`;
            allParams[index] = allParams[index]
                ? {...allParams[index], ...codeParams}
                : codeParams;
        });
        //get employee
        const user = this.getUserObj();
        const {icon, name} = this.getIconAndName();
        const comment = this.getComment();
        return {
            params: allParams,
            user,
            iconClassName: icon,
            translation: allTranslations,
            type: log.TableName,
            name: name,
            scheduleData,
            ...(!!comment && {comment}),
        };
    };
}
